.ServicesIcon
  width 98px
  font-weight 500
  font-size 13px
  text-align center
  
  &_imgWrapper
    display flex
    justify-content center
    align-items center
    min-height 30px
    margin-bottom 7px