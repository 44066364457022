.EntityList
	&_companyLink
		margin 0 auto 19px
	
	&_btnWrapper
		position relative
		margin 0 -15px 22px
		padding 0 20px 24px
		border-bottom 2px solid $grey
		
		&:after
			content ""
			position absolute
			right 0
			bottom 0
			left 0
			height 35px
			background-image -webkit-linear-gradient(to top, $lightGrey, #fff)
			background-image -o-linear-gradient(to top, $lightGrey, #fff)
			background-image linear-gradient(to top, $lightGrey, #fff)
	
	&_btn
		position relative
		z-index 10
		
	&_header
		display flex
		justify-content space-between
		margin-bottom 16px
		padding-bottom 10px
		border-bottom 1px solid #ebf0f2
	
	&_title
		font-weight 600
		font-size 16px
		
	&_item
		margin-bottom 27px
		
		@media $xs
			margin-bottom 22px
		
		&:last-child
			margin-bottom 0
	
	&-myVacancies
		padding-right 22px
		padding-bottom 120px
		
		@media $xs
			padding-top 16px
			padding-right 0
			padding-bottom 0
		
		.EntityList_header			
			@media $smPlus
				margin-bottom 23px
				padding-top 5px
				padding-bottom 6px
		
		.EntityList_item
			margin-bottom 22px
		
		.EntityList_bottom
			margin-top 36px
	
	&-services
		padding-bottom 25px
		
		.EntityList_item
			margin-bottom 21px
			
			@media $xs
				margin-bottom 19px
			
			&:last-child
				margin-bottom 0