.SaveFilter
	position relative
	
	&_icon
		display block
		width 28px
		text-align right
		color $blue3
		
		&:after
			content ""
			display inline-block
			margin-left 4px
			border 3px solid transparent
			border-top 3px solid $blue3
			@extend .transition
	
	&_menu
		display none
		list-style none
		position absolute
		top calc(100% + 11px)
		left -20px
		width 188px
		padding 7px 0 11px
		border-radius 2px
		box-shadow 0 1px 5px 0 rgba(0, 0, 0, 0.12)
		background $white
		text-align left
		
		&-opened
			display block
	
	&_link
		display flex
		align-items center
		padding 4px 10px 4px 20px
		color $blue3
		font-weight 500
		font-size 13px
		line-height 1.8461
		@extend .transition
		
		& > .SvgIco
			width 12px
			margin-right 10px
		
		&:hover
			background #f5f5f5
		
		&-notifications
			& > .SvgIco
				width 17px
				margin-right 6px
		
		&-active
			color $blue