.TopicCreationBlock
	padding 26px
	background $lightGrey url('../img/icon_creation_topic.png') calc(100% - 12px) calc(100% - 5px) no-repeat
	
	&_title
		margin-bottom 5px
		font-weight 500
		font-size 24px
		color $blue2
	
	&_text
		margin-bottom 15px
		line-height 1.5714