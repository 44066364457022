.BusinessOportunities
	background #F5F7FA
	
	&_container
		display grid
		grid-template-columns 55.4% 44.6%
		padding 130px 0 106px
		
		@media $xs
			grid-template-columns auto
			padding 29px 0 50px
	
	&_title
		margin-bottom 12px
		font-weight 600
		font-size 36px
		line-height 1.23
		
		@media $xs
			margin-bottom 14px
			font-size 24px
			line-height 1.2
			text-align center
	
	&_left
		@media $xs
			margin-bottom 16px
	
	&_subTitle
		margin-bottom 10px
		font-weight 600
		font-size 18px
		line-height 1.56
		
		@media $xs
			margin-bottom 7px
			font-size 16px
			line-height 1.5
	
	&_text
		font-size 16px
		line-height 1.625