.Container
	width 100%
	max-width 1170px
	margin-right auto
	margin-left auto
	padding-right 15px
	padding-left 15px
	
	&-dossierSection
		max-width 1220px
	
	&-company,
	&-article,
	&-plans,
	&-pricing
		@media $xs
			padding-right 20px
			padding-left 20px