.TeamTable
	width 100%
	font-size 16px
	
	@media $xs
		font-size 15px
	
	&_th, &_td
		height 70px
		border-bottom 1px solid #ebf0f2
		vertical-align middle
		
		@media $xs
			height 71px
	
	&_th
		text-align left
		
		&-earnings
			padding-right 8px
			text-align right
	
	&_td
		&-date
			@media $smPlus
				width 111px
			
		&-earnings
			padding-right 11px
			text-align right
			
			@media $xs
				vertical-align top
				padding-top 14px
				padding-right 8px
		
		&-user
			display flex
			justify-content center
		
		&-phone
			width 124px
			
			@media $xs
				width 95px
	
	&_count
		margin-left 4px
	
	&_user
		display flex
		align-items center
	
	&_userPhoto
		width 30px
		margin-right 14px
		
		@media $xs
			margin-right 10px
	
	&_nameAndDate
		flex-grow 1
		display flex
		justify-content space-between
		align-items center
		max-width 526px
		
		@media $xs
			flex-direction column
			align-items flex-start
	
	&_userName
		@media $xs
			font-size 14px
	
	&_date,
	&_time
		@media $xs
			font-size 12px
	
	&-applications
		@media $xs
			font-size 12px
			
		.TeamTable_userName
			@media $xs
				font-size 12px