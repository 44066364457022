*
	margin 0
	padding 0
	box-sizing border-box
	-webkit-tap-highlight-color transparent

html, body
	height 100%

body
	color $black
	background $white
	font-family $baseFont
	font-weight 400
	font-size 14px
	line-height 1.4
	
	// @media $xxs
	// 	font-family $roboto