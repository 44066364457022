.BtnWhite
	display inline-block
	padding 10px 13px 10px 14px
	outline none
	border 1px solid #dfe1f0
	background $white
	color #0670eb
	font-weight 500
	font-size 12px
	text-align center
	white-space nowrap
	
	&-block
		width 100%
	
	&-communicationBanner,
	&-vacanciesBanner,
	&-companiesBanner
		width 152px
		border-radius 20px
		font-size 14px
	
	&-vacanciesBanner
		color #01967D
	
	&-companiesBanner
		color #f67800