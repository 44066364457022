.fancybox-slide--html
	.fancybox-close-small
		top 19px
		right 21px
		color #c4c4c4
		@extend .transition
		
		&:hover
			opacity 0.6
			color currentColor

.fancybox-close-small
	opacity 1

.fancybox-button
	width 54px
	height 54px

.fancybox-bg
	background $black

.fancybox-is-open
	.fancybox-bg
		opacity 0.5
		// opacity 1
	
	&.fancybox-container--hint
		.fancybox-bg
			opacity 0.8

.fancybox-container
	&--no-padding
		.fancybox-slide--html
			padding 0
		
		.fancybox-slide
			@media $xs
				padding-right 0
				padding-left 0
	
	&--image,
	&--video
		&.fancybox-is-open
			.fancybox-bg
				// opacity 0.9
				opacity 1
	
	.fancybox-slide--video
		@media $xs
			padding 0
			
		.fancybox-content
			@media $xs
				width auto !important
				height 100% !important