.IconMenu
	position fixed
	right 0
	bottom 0
	left 0
	z-index 102
	background $white
	filter drop-shadow(0 0 4px rgba(0,0,0,0.1))
	
	&_menu
		display flex
		align-items center
		padding 8px 0 6px
	
	&_item
		&-more
			margin-right 20px
			margin-left auto
	
	&_link
		display inline-flex
		flex-direction column
		align-items center
		padding 0 11px
		color $blue3
		@extend .transition
		
		&:hover
			color #5681b8
		
		&-dot
			.IconMenu_linkIcon
				position relative
				
				&:before
					content ""
					position absolute
					top 0
					right -8px
					width 7px
					height 7px
					// top 4px
					// right 3px
					// width 11px
					// height 11px
					// border 2px solid $white
					background $orange
					border-radius 50%
		
		&.active
			// color #5681b8
			color $blue
			
			.IconMenu_linkTitle
				color $blue
	
	&_linkTitle
		color $blue3
		font-weight 500
		font-size 10px