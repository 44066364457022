.SendMessage
	position relative
	display flex
	align-items center
	
	@media $xs
		padding-right 56px
		padding-left 20px
	
	&_uploadFile, &_submit
		display flex
		border none
		
		@media $xs
			bottom 10px
	
	&_uploadFile
		margin-right 15px
		
		@media $xs
			right auto
			left 20px
	
	&_submit
		position absolute
		bottom 15px
		right 15px
		background transparent
		
		@media $xs
			right 20px