.ContactLinks
	font-size 18px
	line-height 21.6px
	
	&_title
		margin-bottom 7px
		font-weight 600
		color #404A66
		
	&_link
		display grid
		grid-template-columns 1fr auto
		align-items center
		gap 10px
		padding 10px 0
		color $blue
		font-weight 500
		
		&-telegram
			&::after
				content ""
				width 20px
				height 17px
				background url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='16.999'%3E%3Cpath data-name='Фигура 18' d='m7.848 11.2-.331 4.746a1.151 1.151 0 0 0 .924-.457l2.219-2.163 4.6 3.435c.843.479 1.438.227 1.665-.792l3.018-14.425C20.21.272 19.492-.225 18.671.087L.929 7.015c-1.211.48-1.193 1.168-.206 1.48l4.536 1.439L15.795 3.21c.5-.335.947-.149.576.185Z' fill='%23419fd9'/%3E%3C/svg%3E") 0 0 no-repeat
		
		&-email
			&::after
				content ""
				width 19px
				height 19px
				background url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18.983' height='18.982'%3E%3Cpath data-name='Фигура 904' d='M18.089 5.486A9.489 9.489 0 1 0 2.795 16.218c.148.148.285.285.431.416a.307.307 0 0 0 .046.034 1.521 1.521 0 0 0 .152.124c.162.143.332.276.551.436l.147.092.022.012c.11.082.22.155.338.227a.256.256 0 0 0 .037.02l.031.02.07.03a1.21 1.21 0 0 0 .155.1c.034.019.067.036.047.017a.7.7 0 0 0 .15.094 1.129 1.129 0 0 0 .12.059l.018.006.125.064.072.038.145-.284-.06.327.108.054a1.344 1.344 0 0 0 .176.07 9.29 9.29 0 0 0 3.817.8 9.6 9.6 0 0 0 2.323-.292 1.2 1.2 0 0 0 .857-1.481.232.232 0 0 0-.031-.087 1.2 1.2 0 0 0-1.4-.721 7.22 7.22 0 0 1-1.773.217h-.012a6.871 6.871 0 0 1-2.813-.6 7.125 7.125 0 1 1 9.973-6.532 6.92 6.92 0 0 1-.435 2.436 1.129 1.129 0 0 1-1.454.662 1.136 1.136 0 0 1-.735-1.064V6.319a1.191 1.191 0 0 0-1.175-1.188.654.654 0 0 0-.1.011 1.205 1.205 0 0 0-.866.491 4.449 4.449 0 0 0-2.353-.66 4.505 4.505 0 0 0 .022 9.01 4.443 4.443 0 0 0 2.108-.536c.021-.006.133-.076.151-.086.09-.052.178-.107.264-.166a3.365 3.365 0 0 0 .483.682 3.568 3.568 0 0 0 2.016 1.094 3.694 3.694 0 0 0 2.147-.29.779.779 0 0 1 .1-.052 3.418 3.418 0 0 0 1.625-1.9 9.426 9.426 0 0 0-.326-7.243Zm-6.466 4.01a2.139 2.139 0 1 1-2.139-2.139 2.142 2.142 0 0 1 2.139 2.138Z' fill='%23f89333'/%3E%3C/svg%3E") 0 0 no-repeat
				
		&-viber
			&::after
				content ""
				width 19px
				height 21px
				background url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19.034' height='20.999'%3E%3Cpath data-name='Фигура 890' d='M16.958 1.818c-.5-.447-2.639-1.78-7.109-1.8 0 0-5.293-.345-7.863 1.961C.556 3.379.074 5.458.021 7.996s-.327 7.387 4.361 8.677c0 0-.018 3.6-.021 3.916 0 .221.035.373.163.4a.408.408 0 0 0 .346-.141c.749-.745 3.149-3.613 3.149-3.613a19.865 19.865 0 0 0 6.056-.511c.649-.207 4.162-.506 4.757-5.263.615-4.897-.225-8.244-1.874-9.643Zm-2.606 11.42v.008a4.189 4.189 0 0 1-.79 1.04s0 0-.007.006a1.516 1.516 0 0 1-.8.415.5.5 0 0 1-.117.008 1 1 0 0 1-.341-.056l-.009-.013a11.3 11.3 0 0 1-2.2-1.091 13.081 13.081 0 0 1-1.852-1.308 9.945 9.945 0 0 1-.833-.79l-.028-.03-.028-.03-.028-.03-.028-.03a10.178 10.178 0 0 1-.739-.891 14.163 14.163 0 0 1-1.223-1.98 12.891 12.891 0 0 1-1.02-2.357L4.296 6.1a1.215 1.215 0 0 1-.052-.365.593.593 0 0 1 .007-.125 1.751 1.751 0 0 1 .388-.858s0 0 .005-.008a4.015 4.015 0 0 1 .972-.844h.008a.7.7 0 0 1 .949.163s.491.63.7.937q.324.488.6 1.009a.936.936 0 0 1-.147 1.13l-.477.406a.783.783 0 0 0-.209.594 5.211 5.211 0 0 0 3.348 3.583.677.677 0 0 0 .556-.224l.379-.51a.8.8 0 0 1 1.057-.157 11.147 11.147 0 0 1 .943.644c.287.226.875.75.876.75a.813.813 0 0 1 .153 1.013Zm-2.185-7.06a3.1 3.1 0 0 0-2.24-1.041.27.27 0 0 1-.238-.294.263.263 0 0 1 .275-.254 3.628 3.628 0 0 1 2.582 1.218 3.912 3.912 0 0 1 .9 2.71.266.266 0 0 1-.257.269h-.006a.268.268 0 0 1-.251-.281 3.314 3.314 0 0 0-.766-2.328Zm-.038 1.846a.269.269 0 0 1-.244.288h-.013a.265.265 0 0 1-.257-.261c-.043-.934-.454-1.391-1.293-1.438a.269.269 0 0 1-.243-.289.264.264 0 0 1 .27-.26 1.791 1.791 0 0 1 1.779 1.959Zm2.415 1.326a.266.266 0 0 1-.257-.273 5.518 5.518 0 0 0-1.383-3.924 4.6 4.6 0 0 0-3.41-1.442.276.276 0 0 1 0-.55 5.084 5.084 0 0 1 3.766 1.6 6.064 6.064 0 0 1 1.539 4.313.267.267 0 0 1-.256.275Z' fill='%237360f2'/%3E%3C/svg%3E") 0 0 no-repeat
				
		&-phone
			&::after
				content ""
				width 18px
				height 18px
				background url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17.9' height='17.943'%3E%3Cpath data-name='Фигура 887 копия 5' d='m17.499 14.193-2.764-2.778a1.437 1.437 0 0 0-2.032.039l-1.393 1.4-.275-.154a13.857 13.857 0 0 1-3.35-2.434 14.006 14.006 0 0 1-2.428-3.372c-.052-.094-.1-.184-.15-.269l.935-.938.459-.461a1.451 1.451 0 0 0 .037-2.042L3.773.405a1.434 1.434 0 0 0-2.032.039l-.779.787.021.021A4.528 4.528 0 0 0 .341 2.39a4.728 4.728 0 0 0-.286 1.154c-.365 3.039 1.018 5.818 4.771 9.589 5.188 5.211 9.368 4.818 9.549 4.8a4.611 4.611 0 0 0 1.152-.29 4.471 4.471 0 0 0 1.128-.642l.017.015.789-.776a1.454 1.454 0 0 0 .038-2.047Z' fill='%2385be1f'/%3E%3C/svg%3E") 0 0 no-repeat