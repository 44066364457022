.UploadArea
	display flex
	justify-content center
	align-items center
	height 130px
	padding-right 30px
	padding-left 30px
	border-radius 2px
	background $lightGrey
	color $blue3
	font-weight 500
	text-align center
	
	@media $xs
		height 190px
		font-size 16px
		line-height 1.5
	
	&-privateFiles
		background $pink
	
	&-whiteBg
		min-height 180px
		border 1px dashed $blue3
		background $white