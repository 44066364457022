.BtnGreen
	display inline-block
	padding 7px 28px
	border 1px solid $green2
	border-radius 16px
	color $green2
	font-weight 500
	font-size 14px
	white-space nowrap
	@extend .transition
	
	@media $xs
		padding 9px 15px
		border-radius 20px
	
	&:hover
		background $green2
		color $white
	
	&-banner
		padding 11px 44px
		border none
		border-radius 20px
		background #49B874
		color $white
		
		&:hover
			background darken(#49B874, 10%)
	
	&-call
		display inline-grid
		grid-template-columns 12px auto
		justify-content center
		align-items center
		gap 8px
		padding-top 8px
		padding-bottom 7px
		border-radius 18px
		background $green
		color $white
		text-align center
		
		&::before
			content ""
			width 12px
			height 12px
			background url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11.986' height='11.997'%3E%3Cpath data-name='Фигура 887 копия 2' d='M11.718 9.489 9.867 7.631a.963.963 0 0 0-1.361.026l-.933.935-.184-.1A8.746 8.746 0 0 1 3.52 4.61l-.1-.18.626-.627.308-.309a.969.969 0 0 0 .025-1.365L2.528.271a.962.962 0 0 0-1.361.026L.645.823l.014.014a3.032 3.032 0 0 0-.43.761 3.154 3.154 0 0 0-.191.772C-.206 4.402.72 6.26 3.233 8.781c3.473 3.484 6.273 3.221 6.394 3.208a3.1 3.1 0 0 0 .771-.194 3 3 0 0 0 .756-.43l.011.01.529-.519a.971.971 0 0 0 .024-1.367Z' fill='%23fff'/%3E%3C/svg%3E") 0 0 no-repeat