.HintPopup
	width 320px
	padding 14px 19px 20px 20px
	border-radius 8px
	overflow-x hidden
	
	&_title
		margin-bottom 3px
		padding-right 20px
		font-weight 600
		font-size 18px
	
	&_text
		margin-bottom 15px
		font-size 15px
		line-height 1.6
		
		p
			margin-bottom 8px
			
			&:last-child
				margin-bottom 0
	
.fancybox-container--hint
	.fancybox-slide--html
		padding-top 24px
		padding-bottom 24px
		
		.fancybox-close-small
			top -4px
			right -4px