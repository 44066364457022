.NewsFeed
	@media $xxs
		padding-right 5px
		padding-left 5px
		
	&_article
		margin-bottom 21px
		
		@media $smMinus
			margin-top 20px
		
		@media $xs
			margin 33px auto 23px