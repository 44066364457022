.Wrapper
	display flex
	flex-direction column
	min-height 100%
	// overflow hidden
	
	&-noMobileHeader
		@media $xs
			padding-top 51px
			
			.Header
				display none
		
		.Main
			margin-top 0
	
	&-blurred
		filter blur(7px)
		
		&:after
			content ""
			position absolute
			z-index 10
			top 0
			right 0
			bottom 0
			left 0
			background rgba($black, 5%)
	
	&-noMobilePadding
		@media $xs
			padding-top 0
	
	&-authPage
		.TopBtn
			display none
	
	&-noDotsMobileHeader
		.Icons_dotsIcon
			display none
	
	&-post,
	&-job
		.TopBtn
			bottom 116px