.Theses
	&_container
		padding-top 79px
		padding-bottom 76px
		
		@media $mdMinus
			padding-top 65px
			padding-bottom 65px
		
		@media $smMinus
			padding-top 50px
			padding-bottom 50px
		
		@media $xs
			padding-top 34px
			padding-bottom 15px
	
	&_item
		margin-bottom 33px
		padding-bottom 47px
		border-bottom 1px solid #dfe4e6
		
		@media $xs
			margin-bottom 18px
			padding-bottom 27px
		
		&:last-child
			margin-bottom 0
			padding-bottom 0
			border-bottom none
	
	&-pricing
		.Theses_container
			padding-top 115px
			padding-bottom 114px
			
			@media $xs
				padding-top 29px
				padding-bottom 29px
		
		.Theses_title
			margin-bottom 8px
			font-weight 600
			font-size 36px
			line-height 1.2
			
			@media $xs
				font-size 24px
		
		.Theses_subTitle
			margin-bottom 96px
			font-weight 600
			font-size 18px
			line-height 1.56
			
			@media $xs
				margin-bottom 36px
				font-size 16px
				line-height 1.5
			
		.Theses_item
			margin-bottom 50px
			padding-bottom 42px
			border-bottom 1px solid #EBF0F2
			
			@media $xs
				margin-bottom 18px
				padding-bottom 19px
			
			&:last-child
				margin-bottom 0
				padding-bottom 0
				border-bottom none