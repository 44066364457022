.JoinForm
	&_title
		margin-bottom 45px
		text-align center
		
		@media $xs
			margin-bottom 25px
	
	&_wrapper
		max-width 470px
		margin-right auto
		margin-left auto
	
	&_field
		margin-bottom 15px
		
		@media $xs
			margin-bottom 10px
	
	&_fieldGroup
		display flex
		margin-bottom 43px
		
		@media $xs
			margin-bottom 30px
		
		.JoinForm_select
			flex-shrink 0
			
			@media $xs
				flex-shrink 1
	
	&_text, &_select
		height 51px
		border 1px solid #e3e3e3
		outline none
		font-size 18px
		font-family 'Decima Nova Pro', sans-serif
		
		@media $xs
			height 40px
			font-size 14px
	
	&_text
		width 100%
		padding-right 22px
		padding-left 22px
		
		+placeholder()
			color rgba($black, 33%)
		
		@media $xs
			padding-right 15px
			padding-left 15px
	
	&_select
		appearance none
		width 128px
		padding-right 15px
		padding-left 22px
		cursor pointer
		background $white url('../img/arrow_down.png') calc(100% - 10px) calc(50% + 2px) no-repeat
		
		@media $xs
			padding-right 30px
			padding-left 15px
	
	&_actions
		text-align center
	
	&_success
		margin-right auto
		margin-left auto