.Replenishment
	padding-bottom 40px
	
	@media $smPlus
		padding-bottom 70px
	
	@media $xlPlus
		padding-bottom 180px
	
	&_wrapper
		text-align center
		
		@media $smPlus
			width 480px
			margin-right auto
			margin-left auto
	
	&_imgWrapper
		@media $smPlus
			margin-bottom 6px
	
	&_img
		@media $smPlus
			width 380px
	
	&_title
		margin-bottom 3px
		font-weight 600
		font-size 18px
		line-height 32px
		
		@media $smPlus
			margin-bottom 5px
			font-size 21px
			line-height 40px
	
	&_highlighted
		font-weight bold
		font-size 30px
		
		@media $smPlus
			font-size 36px
	
	&_text
		margin-bottom 17px
		padding-right 10px
		padding-left 10px
		color $blue2
		font-size 16px
		line-height 1.5
		
		@media $smPlus
			margin-bottom 24px
	
	&_buttons
		display grid
		grid-template-columns 156px 156px
		gap 8px
		justify-content center