.Salary
	font-weight 600
	font-size 24px
	color $green2
	
	@media $xs
		font-size 21px
	
	&-small
		@media $smPlus
			font-size 22px