.Characteristic
	display flex
	font-size 15px
	line-height 1.625
	
	@media $xs
		flex-direction column
		font-size 13px
	
	&_name
		flex-basis 200px
		flex-shrink 0
		font-weight 600
		
		@media $xs
			flex-basis auto
	
	&_value
		&-status			
			& > .SvgIco
				width 14px
				margin-right 6px
				margin-bottom 4px
				
				@media $xs
					display none
	
	&_link
		font-weight 500
		color $blue
		
		&-doc
			& > .SvgIco
				margin-left 6px
	
	&_date
		color $blue3
		
		@media $xs
			margin-left 3px
		
		&-status
			font-weight normal
			font-size 14px
			line-height 1.714
			
			@media $xs
				font-size 16px
				line-height 1.5625
	
	&-inlineMob
		@media $xs
			flex-direction row
			
			.Characteristic_name
				margin-right 9px
	
	&-dossier
		font-size 15px
		line-height 1.6
		
		@media $xs
			font-size 16px
			line-height 1.5625
		
		.Characteristic_name
			flex-basis 258px
			
			@media $xs
				flex-basis auto
	
	&-activities
		.Characteristic_name
			@media $xs
				margin-bottom 9px