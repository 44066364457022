.Job
	padding-bottom 30px
	border-bottom 1px solid #ebf0f2
	
	@media $xs
		padding-bottom 26px
	
	&_title
		margin-bottom 22px
		font-weight 600
		font-size 24px
		line-height 1.34
		
		@media $xs
			margin-bottom 8px
			font-size 21px
			line-height 1.23809
	
	&_basicInfo
		display flex
		align-items flex-start
		margin-bottom 14px
		
		@media $xs
			flex-direction column
			margin-bottom 13px
	
	&_basicInfoLeft
		flex-shrink 0
		
		@media $xs
			margin-bottom 24px
	
	&_img
		display block
		width 150px
		
		@media $xs
			width auto
	
	&_basicInfoRight
		flex-grow 1
		padding-left 26px
		
		@media $xs
			padding-left 0
	
	&_basicInfoWrap
		display flex
		justify-content space-between
		
		@media $xs
			flex-direction column
	
	&_specs
		flex-basis 310px
		margin-top -5px
		
		@media $xs
			flex-basis auto
	
	&_salaries
		display flex
		flex-direction column
		justify-content center
		align-items flex-end
		flex-basis 284px
		min-height 120px
		padding-bottom 11px
		border-left 1px solid #ebf0f2
		
		@media $xs
			align-items flex-start
			flex-basis auto
			min-height 0
			padding-bottom 15px
			border-left none
	
	&_salary
		margin-bottom 6px
		
		@media $xs
			margin-bottom 1px
	
	&_description
		margin-bottom 23px
		font-size 15px
		line-height 1.6
		color $black
		
		h2, h3
			margin-bottom 12px
			font-weight 600
			font-size 18px
			line-height 1.34
			
			@media $xs
				margin-bottom 8px
		
		ul
			margin-bottom 23px
			
			@media $xs
				margin-bottom 18px
				padding-left 11px
			
			&:last-child
				margin-bottom 0
			
			li
				list-style none
				position relative
				margin-bottom 11px
				
				@media $xs
					margin-bottom 9px
				
				&:last-child
					margin-bottom 0
				
				&:before
					content ""
					position absolute
					top 11px
					left -10px
					width 4px
					height 4px
					border-radius 50%
					background $green2
		
		@media $xs
			margin-bottom 19px
	
	&_viewBtnWrapper
		display flex
		justify-content flex-end
		padding-right 3px
	
	&_viewAll
		margin-bottom 16px
	
	&_images
		margin-bottom 24px
	
	&_bottom, &_additional, &_icons
		display flex
		justify-content space-between
		align-items center
	
	&_bottom
		margin-right -3px
		
		@media $xs
			flex-direction column-reverse
			align-items stretch
	
	&_additional
		@media $xs
			margin-bottom 35px
	
	&_updated
		margin-right 25px
		font-weight 500
		color $blue3
	
	&_share
		margin-right 19px
		margin-bottom 3px
		
		@media $xs
			margin-right 27px
			margin-bottom 0
	
	&_status
		@media $xs
			margin-bottom 23px
	
	&-full
		.Job_title
			margin-bottom 5px
			
			@media $xs
				margin-bottom 15px
			
		.Job_salaries
			display block
			min-height 0
			margin-bottom 23px
			padding-bottom 0
			border-left none
		
		.Job_salary
			margin-bottom 9px
			
			@media $xs
				margin-bottom 0
		
		.Job_specs
			flex-basis auto
			margin-top 0
			margin-bottom 25px
			
			@media $xs
				margin-bottom 22px
		
		.Job_description
			margin-bottom 33px
			
			@media $xs
				margin-bottom 23px
	
	&-my
		padding-bottom 23px
		
		.Job_specs
			@media $smPlus
				flex-basis 321px
				padding-right 10px
		
		.Job_basicInfo
			margin-bottom 12px
		
		.Job_basicInfoLeft
			@media $xs
				margin-bottom 26px
		
		.Job_description
			margin-bottom 13px
		
		.Job_bottom
			margin-right 0
			padding-right 1px
		
		.Job_updated
			font-weight normal
			color #999
			
			@media $xs
				margin-right 0