.RatingIncrease
	font-size 16px
	line-height 1.625
	
	@media $xs
		font-size 15px
		line-height 1.6
	
	&_item
		display flex
		justify-content space-between
		align-items center
		padding-top 17px
		padding-bottom 18px
		border-bottom 1px solid #ebf0f2
		
		@media $xs
			display block
			padding-top 11px
			padding-bottom 13px
	
		&:first-child, &-borderTop
			border-top 1px solid #ebf0f2
		
		&-noBorder
			padding-bottom 10px
			border-bottom none
		
	&_text
		width 78%
		
		@media $xs
			display inline
			width auto
	
	&_number
		font-weight 600
		text-align right
		
		@media $xs
			display inline
	
	&_note
		margin-bottom 18px
		margin-left -13px