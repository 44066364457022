.VideoContainer
	position relative
	height 0
	padding-bottom 55.55%
	overflow hidden
	
	iframe,
	object,
	embed
		position absolute
		top 0
		left 0
		width 100%
		height 100%