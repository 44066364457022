.MobileTopBtn
	position fixed
	left 20px
	bottom 70px
	z-index 103
	display inline-flex
	width 48px
	height 48px
	border-radius 50%
	border none
	outline none
	background $white url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15.828' height='15.977' viewBox='0 0 15.828 15.977'%3E%3Cpath data-name='Фигура 902' d='M15.541 7.277 8.616.286a.987.987 0 0 0-1.4 0L.291 7.277a1 1 0 0 0 0 1.412.983.983 0 0 0 1.4 0l5.26-5.286-.01 11.574a1 1 0 0 0 2 0L8.934 3.408l5.2 5.286a.983.983 0 0 0 1.4 0 1 1 0 0 0 .007-1.417Z' fill='%234b97f9'/%3E%3C/svg%3E") center center no-repeat
	filter drop-shadow(0px 1px 3px rgba(100, 124, 151, 0.31))
	visibility hidden
	opacity 0
	
	&-visible
		visibility visible
		opacity 1
	
	@media $smPlus
		display none