.Breadcrumbs	
	&_item
		display inline
		font-size 13px
		
		&:last-child
			.Breadcrumbs_link::after
				display none
	
	&_link
		color $blue3
		
		&::after
			content ""
			display inline-block
			width 3px
			height 4px
			// margin 0 5px 1px
			margin 0 3px 1px
			background-image url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='3.012' height='4.001'%3E%3Cpath data-name='Многоугольник 1 копия 10' d='M3.01 1.997l-3.012 2v-4z' fill='%238396ac'/%3E%3C/svg%3E")
			vertical-align middle
			
			@media $xs
				margin-right 1px
				margin-left 1px