.WelcomePage
	padding-bottom 80px
	
	@media $xs
		padding-bottom 50px
	
	&_header
		margin-bottom 15px
	
	&_languages
		margin-bottom 30px
		
	&_description
		margin-bottom 2.58em
		text-align center
		font-size 19px
		line-height normal
		font-family 'Decima Nova Pro', sans-serif
		
		@media $xs
			font-size 16px
	
	&_tabs
		margin-bottom 78px
		
		@media $xs
			margin-bottom 50px
	
	&_joinUs
		margin-bottom 37px