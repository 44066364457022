.ArrowLink
	display inline-block
	color $blue
	font-weight 600
	font-size 14px
	line-height 1.857
	white-space nowrap
	
	&::after
		content ""
		display inline-block
		width 4px
		height 6px
		margin-left 4px
		background url('../img/arrow_blue.png') 0 0 no-repeat
		vertical-align middle
	
	&-companyInfoName
		font-size 21px
		
		&::after
			margin-left 6px
			transform rotate(90deg)
	
	&-replenishment, &-remark
		font-size 15px
	
	&-remark
		line-height 1.5
	
	&-company
		font-weight 500
		font-size 15px
	
	&-coverSelection
		font-weight 500
		
		@media $xxs
			font-size 16px
		
		&::after
			transform rotate(90deg)
	
	&-left
		&::before
			content ""
			display inline-block
			margin-right 4px
			margin-left -5px
			border 4px solid transparent
			border-right-color $blue
			vertical-align middle
			
		&::after
			display none
	
	&-countries,
	&-cover,
	&-vacancies
		font-weight 500
		font-size 13px
		
		&::after
			content ""
			display inline-block
			border 3px solid transparent
			border-top-color $blue
			vertical-align middle
			background none
			margin-top 5px
	
	&-cover
		font-size 14px
		
		@media $xs
			font-size 16px
			
			&::after
				display none
	
	&-vacancies
		font-size 14px
	
	&-news
		font-size 13px
		
		&::before
			content ""
			display inline-block
			width 14px
			height 9px
			vertical-align middle
			margin-right 7px
			background url('../img/arrow_left.svg') 0 0 no-repeat
		
		&::after
			display none
	
	&-vacancyFull
		display inline-flex
		align-items center
		font-size 13px
		
		&::after
			display none
		
		&::before
			content ""
			width 14px
			height 9px
			margin-right 7px
			background-image url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='9'%3E%3Cstyle%3E%3C/style%3E%3Cg id='Заголовок'%3E%3Cpath id='Фигура 1' d='M5.23 8.85c.22.18.58.18.8 0 .22-.17.22-.46 0-.63L1.94 4.94 13 5c1 0 1-1 0-1l-11.06.03L6.03.77c.22-.18.22-.47 0-.65a.66.66 0 00-.8 0L.17 4.17c-.23.17-.23.46 0 .64l5.06 4.04z' fill='%234b97f9'/%3E%3C/g%3E%3C/svg%3E")