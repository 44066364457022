.TrustBlock
	&_iconWrapper
		margin-bottom 20px
		
		@media $xs
			margin-bottom 11px
		
	&_icon
		display block
		
	&_title
		margin-bottom 12px
		font-weight 600
		font-size 22px
		line-height 1.36
		
		@media $xs
			margin-bottom 7px
			font-size 18px
			line-height 1.45
	
	&_text
		font-size 16px
		line-height 1.625