.AddFieldItem
	display inline-flex
	color $blue
	font-weight 500
	font-size 14px
	outline none
	
	@media $xs
		font-size 15px
	
	& > svg
		margin-top -1px
		margin-right 7px
		
		@media $xs
			width 24px
			margin-right 11px
	
	&-photoVideo
		@media $xs
			& > svg
				width 20px
				margin-right 9px
	
	&-country
		& > svg
			margin-right 11px