.SiteName
	font-size 27px
	font-family 'Welcome', sans-serif
	text-transform uppercase
	color rgba($black, 75%)
	
	@media $xs
		display inline-block
		font-weight bold
		font-size 20px
		vertical-align middle
	
	&-modal
		font-size 28px
		color #404040
		
		@media $xs
			font-size 30px
	
	&-article,
	&-news
		font-size 17px
	
	&-feed
		font-size 16px
	
	&-large
		font-size 30px