.MobileMenu
	&_item
		border-bottom 1px solid #fbfbfb
		font-size 16px
		
	&_link
		display flex
		align-items center
		padding 12px 20px 13px
		color $blue3
		@extend .transition
		
		&:hover,
		&.active
			background #F2F5F7
			color $blue
		
		&.active
			font-weight 600