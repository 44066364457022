.Login
	display flex
	align-items center
	
	&_img
		display block
		width 40px
		margin-right 12px
		border-radius 50%
	
	&_text
		display inline-block
		margin-bottom 2px
		color $black
		font-weight 500
		font-size 14px