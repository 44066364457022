.Dropdown
	&_overlay
		content ""
		position fixed
		z-index 101
		top 0
		right 0
		bottom 0
		left 0
		background rgba(0, 0, 0, .5)
		opacity 0
		visibility hidden
		@extend .transition
		
	&_wrapper
		position absolute
		z-index 101
		top -15px
		right -8px
		width 220px
		border 1px solid $grey
		border-radius 4px
		background $white
		visibility hidden
		opacity 0
		@extend .transition
		
		@media $xs
			top -10px
			right -20px
	
	@media $xs
		border-radius 5px
	
	.Count
		margin-left 5px
		vertical-align middle
	
	&_header
		display flex
		justify-content space-between
		align-items center
		min-height 69px
		padding 11px 19px
		border-bottom 1px solid $grey
		
		@media $xs
			min-height 52px
			padding-top 6px
			padding-bottom 6px
	
	&_userNameWrapper
		display flex
		align-items center
	
	&_userName
		max-width 90px
		font-weight 600
		line-height 1.7
		
		@media $xs
			font-size 13px
			line-height 1.3846
	
	&_rating
		@media $xs
			margin-left 6px
	
	&_userPhoto
		width 40px
		border-radius 50%
		
		@media $xs
			width 32px
	
	&_body
		padding 15px 20px
		box-shadow 0 4px 10px rgba(100, 124, 151, 0.1)
		
		@media $xs
			padding 24px 18px
			box-shadow none
	
	&_item
		margin-bottom 16px
		
		@media $xs
			margin-bottom 22px
		
		&:last-child
			margin-bottom 0
	
	&_link
		display block
		color $blue2
		font-weight 500
		line-height 1.4
		@extend .transition
		
		@media $xs
			font-size 16px
		
		&:hover
			color $blue
		
		&-orange
			color $orange
	
	&-visible
		.Dropdown_overlay
			@media $xs
				opacity 1
				visibility visible
			
		.Dropdown_wrapper
			visibility visible
			opacity 1
	
	&-tabs
		.Dropdown_wrapper
			right -13px
			width 270px
			box-shadow 0 0 10px 0 rgba($black, .1)
			
			@media $xs
				box-shadow none
			
			@media $mdMinus
				right 0
		
		.Dropdown_header
			min-height 0
			border-bottom none
			padding 14px 24px 0
		
		.Dropdown_userName
			max-width 180px
		
		.Dropdown_body
			padding 6px 18px 15px 24px
			box-shadow none