.CompanyReviews
	&_title
		margin-bottom 24px
		padding-bottom 8px
		border-bottom 1px solid #ebf0f2
		font-weight 600
		font-size 16px
	
	&_item
		margin-bottom 24px
		
		&:last-child
			margin-bottom 0