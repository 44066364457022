.JobFilter
  // width 330px
  width 300px
  background $white
  @extend .transition
  
  @media $md
    // filter drop-shadow(0px 1px 6px rgba(100, 124, 151, 0.1))
    // padding-bottom 20px
    border-radius 4px
    box-shadow 0 2px 6px rgba(100, 124, 151, 0.1)

  @media $xs
    position fixed
    z-index 1000
    top 0
    left 0
    transform translateX(100%)
    width 100%
    height 100%
    overflow-y auto
    overflow-x hidden
  
  &_wrapper
    @media $xs
      display flex
      flex-direction column
      height 100%
  
  &_header
    display flex
    justify-content space-between
    align-items center
    padding 20px 15px 14px
    border-bottom 1px solid $grey

    @media $xs
      // padding 17px 20px 14px
      padding 12px 20px

    @media $smPlus
      // margin-bottom 24px
      padding 24px 25px 25px
      border-bottom none
  
  &_headerLeft
    display flex
    align-items flex-start
  
  &_closeLink
    margin-right 10px

    @media $smPlus
      display none
  
  &_title
    font-weight 600
    font-size 16px
  
  &_clearLink
    color $blue
    font-weight 500
    font-size 15px
  
  &_content
    @media $xs
      height calc(100vh - 110px)
      overflow auto
    
    @media $smPlus
      padding-bottom 35px
  
  &_itemGroup,
  &_item
    @media $smPlus
      position relative

      &::after
        content ''
        position absolute
        // right 15px
        right 0
        bottom 0
        // left 15px
        left 0
        height 1px
        background $grey
      
      &:last-child
        padding-bottom 0

        &::after
          display none
  
  &_itemGroup
    @media $smPlus
      margin-bottom 36px
      padding-bottom 36px
      
      &:last-child
        margin-bottom 0
        padding-bottom 0
  
  &_item
    margin-bottom 42px
    padding-bottom 46px

    @media $xs
      margin-bottom 0
      padding-bottom 0
    
    &-salary
      margin-bottom 36px
      
      @media $smPlus
        margin-bottom 29px

    &:last-child
      margin-bottom 0
  
  &_footer
    margin-top auto
    // padding-top 32px
    // padding-top 29px
    // padding-top 15px
    padding 30px 15px
    text-align center

    @media $smPlus
      // display none
      padding-top 12px
      padding-bottom 15px
      border-top 1px solid #EBF0F2
  
  &-opened
    @media $xs
      transform translateX(0)
  
  &-noScroll
    .JobFilter_wrapper
      height 0
      overflow hidden