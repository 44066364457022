.OrderCallPopup
	width 560px
	padding 56px 40px 38px
	
	.fancybox-close-small
		top 5px
		right 5px
	
	&_header
		margin-bottom 22px
		text-align center
	
	&_iconWrapper
		margin-bottom 6px
		
		& > svg
			width 32px
	
	&_title
		font-weight 600
		font-size 21px
		line-height 24px
	
	&_text,
	&_btnWrapper
		text-align center
	
	&_text
		margin-bottom 32px
		font-size 16px
		line-height 1.75
		color #404040
	
	&-success
		padding-bottom 56px
		
		.OrderCallPopup_header
			margin-bottom 30px