.PopupNotification
	width 340px
	max-width 100%
	padding 18px 20px 20px 24px
	box-shadow 0 0 15px 0 rgba(1, 0, 0, .3)
	background $white
	border-radius 10px
	transition all .25s linear
	
	@media $xs
		padding-right 20px
		padding-left 20px
	
	&_title
		margin-bottom 10px
		font-weight 600
		font-size 16px
		
		&:last-child
			margin-bottom 0
	
	&-fixedBottom
		position fixed
		z-index 101
		right 0
		bottom -120px
		left 0
		margin auto
	
	&-visible
		bottom 15px