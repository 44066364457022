.Timer
	display flex
	justify-content space-between
	width 363px
	max-width 100%
	font-weight bold
	
	@media $xs
		width 280px
	
	&_itemNumber
		display flex
		justify-content space-between
		width 81px
		margin-bottom 7px
		
		@media $xs
			width 61px
	
	&_digit
		position relative
		width 40px
		height 45px
		border-radius 6px
		font-size 36px
		line-height 45px
		text-align center
		background #4D4D4D
		color $white
		
		@media $xs
			width 30px
			height 40px
			font-size 28px
			line-height 40px
		
		&:before
			content ""
			position absolute
			left 0
			bottom 50%
			width 100%
			height 16px
			background-image linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.25))
	
	&_itemLabel
		font-weight bold
		font-size 14px
		line-height normal
		letter-spacing 0.4px
		text-transform uppercase
		text-align center
		
		@media $xs
			font-size 10px