.Plan
	width 300px
	max-width 100%
	box-shadow 0px 1px 8px rgba(0, 0, 0, 0.08)
	
	&_title
		padding 11px 20px
		border-top-right-radius 8px
		border-top-left-radius 8px
		background $blue
		color $white
		font-weight 600
		font-size 21px
	
	&_content
		padding 22px 20px
		border-bottom-right-radius 8px
		border-bottom-left-radius 8px
	
	&_price
		margin-bottom 20px
		font-weight 600
		font-size 28px
		color $blue
		
	&_priceSuffix
		margin-left 5px
		font-size 16px