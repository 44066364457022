.OperationsHistory
	&_title
		margin-bottom 14px
		padding-left 2px
		font-weight 600
		font-size 18px
	
	&_header
		display flex
		justify-content space-between
		align-items center
		margin-bottom 26px
		padding-bottom 2px
		border-bottom 1px solid $grey
		
	&_period
		display inline-flex
		align-items center
		margin-bottom 4px
		font-weight 500
		color $blue
		
		svg
			margin-left 9px
	
	&_group
		margin-bottom 25px
		
		&:last-child
			margin-bottom 0
	
	&_date
		margin-bottom 6px
		text-indent 2px
		font-weight 600
		font-size 16px