.Companies
	padding-top 6px
	
	&_header
		display flex
		justify-content flex-end
		align-items center
		margin-bottom 14px
		
		@media $xs
			margin-bottom 0
	
	&_headerLeft
		flex-grow 1
	
	&_filter
		@media $xs
			margin-bottom 14px
	
	&_filterLinks
		display flex
		justify-content space-between
		align-items center
		margin 0 0 25px
		padding 10px 18px 8px
		background $lightGrey
	
	&_searchForm
		margin-bottom 35px
	
	&_recentSearch
		margin 0 0 20px
	
	&_item
		margin-bottom 40px
		
		&:last-child
			margin-bottom 0
	
	&-dossiers
		max-width 722px
		
		@media $smMinus
			max-width 100%
		
		@media $xs
			// padding-top 19px
			padding-top 4px
		
		.Companies_item
			margin-bottom 35px
			
			@media $xs
				margin-bottom 27px
			
			&:last-child
				margin-bottom 0
	
	&-search
		padding-top 13px
		
		.Companies_searchForm
			margin-bottom 18px
			
			@media $xs
				margin-right -8px
		
		.Companies_item
			margin-right -20px
			margin-left -20px
			margin-bottom 22px
			
			&:last-child
				margin-bottom 0
				border-bottom none
	
	&-vacancies
		@media $smMinus
			margin-right -15px
			margin-left -15px
			// padding-right 20px
			// padding-left 20px
			
		.Companies_item
			@media $xs
				margin-bottom 32px
				
			@media $smPlus
				margin-bottom 33px
				
				&:last-child
					margin-bottom 0
		
		@media $md
			padding-top 0