.DossierFilter
	width 250px
	
	&_title
		margin-bottom 22px
		padding-top 11px
		padding-bottom 16px
		border-bottom 1px solid $grey
		font-weight 600
		font-size 16px
	
	&_groupTitle
		margin-bottom 16px
		padding-left 2px
		font-weight 600
		font-size 15px