.NoResults
	padding 37px 86px 120px 0
	text-align center
	
	@media $smMinus
		padding-right 0
	
	@media $xs
		padding 75px 5px
	
	&_icon
		margin-bottom 20px
		
		svg
			@media $xs
				width 134px
	
	&_img
		display inline-block
		margin-bottom 49px
		
		@media $smMinus
			width 400px
			margin-bottom 40px
		
		@media $xs
			width 280px
			margin-bottom 30px
	
	&_text
		margin-bottom 40px
		color $blue2
		font-weight 500
		font-size 21px
		line-height 1.23809
		
		a
			color $blue
			border-bottom 1px solid $blue
			@extend .transition
			
			&:hover
				border-bottom-color transparent
		
		@media $xs
			margin-bottom 23px
			font-size 18px
			line-height 1.34
	
	&_linkWrapper
		margin-top 25px
	
	&_link
		color $blue
		font-weight 500
	
	&-notifications
		padding-top 58px
		
		@media $xs
			padding-top 82px
		
		.NoResults_icon
			@media $xs
				margin-bottom 17px
				
				svg
					width 90px
	
	&-notificationsSmall
		padding 83px 6px 0 0
		
		.NoResults_icon
			margin-bottom 11px
			
			svg
				width 63px
		
		.NoResults_text
			margin-bottom 0
			font-size 12px
	
	&-teamTable
		padding 100px 20px
		
		.NoResults_text
			font-size 16px
	
	&-search
		padding-top 40px
		padding-right 27px
		
		@media $xs
			padding-right 0
		
		.NoResults_icon
			margin-bottom 18px
		
		.NoResults_text
			font-size 15px
			line-height 1.42857
	
	&-companies
		padding 13px 0
		
		.NoResults_icon
			margin-bottom 12px
		
		.NoResults_text
			margin-bottom 20px
			font-size 16px
			line-height 1.875
	
	&-404
		padding 160px 0
		
		@media $xlMinus
			padding 40px 0
		
		@media $xs
			padding 84px 0
		
		.NoResults_img
			margin-bottom 0
			
			@media $xlMinus
				width 360px
			
			@media $xs
				width 300px
		
		.NoResults_text
			margin-top -7px
			margin-bottom 40px
			font-size 21px
			color $black
			
			@media $smMinus
				font-size 17px
			
			@media $xs
				margin-top -2px
				margin-bottom 37px
				font-size 24px
	
	&-mySave
		padding 57px 30px 80px 0
		
		@media $smMinus
			padding-right 0
		
		.NoResults_icon
			margin-bottom 10px
		
		.NoResults_text
			font-size 16px
			line-height 1.625
	
	&-entities
		padding 40px 30px 50px
		
		@media $xs
			padding 20px 10px 30px