.RatingSection
	padding-top 23px
	padding-bottom 14px
	border-top 1px solid #ebf0f2
	border-bottom 1px solid #ebf0f2
	
	@media $xs
		padding-bottom 0
		border-bottom none
	
	&_header
		display flex
		justify-content space-between
		align-items center
		margin-bottom 18px
		
		@media $xs
			margin-bottom 19px
	
	&_title
		font-weight 500
		font-size 24px
		line-height 1.34
		
		@media $xs
			font-weight 600
			font-size 18px
	
	&_ratingWrapper
		display flex
		align-items flex-end
		margin-bottom 21px
		
		@media $xs
			margin-bottom 17px
	
	&_dropdownSelect
		margin-bottom 1px
		margin-left 3px
		
		@media $xs
			margin-right 4px
			margin-bottom 0
	
	&_text
		margin-bottom 13px
		font-size 16px
		line-height 1.5
		
		@media $xs
			line-height 1.5625
	
	&_reviewsNumber
		font-weight 600
		font-size 16px
	
	&_extendedRatings
		margin-bottom 36px
		
		@media $xs
			margin-bottom 29px
	
	&_testimonialBtn
		margin-top -6px
		margin-bottom 32px
		
		@media $xs
			margin-top 0
			margin-bottom 25px
	
	&-noBorderTop
		padding-top 0
		border-top none
	
	&-short
		padding 28px 28px 33px 31px
		border none
		background $white
		border-radius 12px
		filter drop-shadow(0 0 6px rgba(0,0,0,0.05))
		
		@media $xs
			padding 0
			filter none
			border-radius 0
		
		.RatingSection_title
			@media $xs
				margin-bottom 19px
		
		.RatingSection_ratingWrapper
			margin-bottom 12px
			
			@media $xs
				margin-bottom 17px
		
		.RatingSection_extendedRatings
			margin-bottom 29px
		
		.RatingSection_rating
			margin-left -4px
		
		.RatingSection_testimonialBtn
			margin-bottom 0