.Notice
	padding 12px 21px 14px
	background $beige
	font-size 13px
	line-height 1.5384
	
	a
		text-decoration underline
		
		&:hover
			text-decoration none
	
	&_title
		margin-bottom 5px
		font-weight 600
		font-size 18px
		line-height 1.12
		
		@media $xs
			line-height 1.34
		
		&:last-child
			margin-bottom 0
	
	&_text
		font-weight 500
		font-size 16px
		line-height 1.4375
	
	&-lock
		padding 21px 15px 19px 45px
		border-radius 4px
		background $beige url('../img/lock.svg') 21px 21px no-repeat
		font-size 15px
		line-height 1.34
		
		@media $xs
			padding 21px 10px 17px 20px
			background-image none
			line-height 1.6
	
	&-fraud
		padding 21px 25px 18px
		border-radius 4px
		background $beige url('../img/warning_fraud_icon.svg') right bottom no-repeat
		color $orange
		
		@media $xs
			padding 17px 8px 10px 16px
	
	&-rejected,
	&-onCheck
		padding 19px 31px 20px
		border-radius 4px
		
		@media $xs
			padding 22px 28px 23px
		
		.Notice_title
			font-size 15px
			line-height 1.533
			
			@media $xs
				font-size 16px
				line-height 1.4375
		
		.Notice_text
			font-weight normal
			font-size 14px
			line-height 1.5714
			
			@media $xs
				font-size 15px
				line-height 1.6
			
			a
				font-weight 600
	
	&-rejected
		background #FFE9DE
		
		.Notice_title
			color $orange
		
		.Notice_text
			a
				color $orange
	
	&-onCheck
		background #ebf0f2
		
		.Notice_title
			color $blue2
		
		.Notice_text
			a
				color $blue2