.Contacts
	&_title
		margin-bottom 17px
		font-weight 600
		font-size 18px
	
	&_item
		display flex
		align-items flex-start
		margin-bottom 16px
		font-size 16px
		line-height 1.5625
		
		&-link
			.Contacts_text
				overflow hidden
				text-overflow ellipsis
				color $blue
		
		&:last-child
			margin-bottom 0
	
	&_icon
		flex-shrink 0
		flex-basis 17px
		margin-right 13px
		text-align center
		color $blue3
		
		&-link
			margin-top -2px
	
	&_text
		& > p
			margin-bottom 16px
			
			&:last-child
				margin-bottom 0
	
	&_link
		color $blue
		font-weight 500
	
	&-bordered
		padding-bottom 20px
		border-bottom 1px solid $grey