.ConversationChoice
	display flex
	flex-direction column
	justify-content center
	align-items center
	
	&_icon
		width 130px
		margin-bottom 20px
	
	&_text
		font-weight 500
		font-size 15px
		line-height 1.67
		color $blue2
	
	// &-profileMessages
	// 	height calc(100vh - 30px)