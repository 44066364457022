.EntityInfo
	display flex
	justify-content space-between
	align-items center
	
	@media $xs
		flex-direction column-reverse
		align-items stretch
	
	&_status
		@media $xs
			margin-bottom 23px
	
	&_updated
		font-weight normal
		color #999
		
		@media $xs
			margin-right 0