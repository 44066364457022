.SectionDescription
	font-size 16px
	line-height 1.65
	letter-spacing 0.003em
	
	@media $xs
		font-size 15px

	& > h2
		margin-bottom 5px
		font-weight 500
		font-size 21px
	
	& > h3,
	& > h4
		margin-top 16px
		margin-bottom 8px
		font-weight 600
		
		@media $xs
			font-size 16px
			line-height 1.5
	
	& > p
		margin-bottom 8px
		
		&:last-child
			margin-bottom 0
	
	& > ul
		margin-bottom 8px
		
		&:last-child
			margin-bottom 0
		
		& > li
			margin-bottom 4px
			
			&:last-child
				margin-bottom 0
			
			&::before
				content "•"
				margin-right 9px
	
	& > ol
		list-style none
		counter-reset n
		margin-bottom 8px
		
		&:last-child
			margin-bottom 0
		
		& > li
			counter-increment n
			display grid
			grid-template-columns auto 1fr
			gap 7px
			margin-bottom 4px
			
			@media $xs
				margin-bottom 5px
			
			&:last-child
				margin-bottom 0
			
			&::before
				content counter(n) '.'
				min-width 15px
	
	&-homePage
		padding-right 30px
		
		@media $xs
			padding-right 10px
	
	&-companies
		margin-right -5px
		
		@media $xs
			margin-right 0
		
		& > ul > li::before
			margin-right 4px
	
	&-vacancies
		@media $smMinus
			padding-right 15px
			padding-left 15px
			font-size 16px
		
		@media $xs
			padding-right 25px
			
		& > ul > li::before
			margin-right 4px
	
	&-articles
		@media $xs
			padding-right 10px
			
		& > h2
			margin-bottom 16px
			font-size 24px
			line-height 1.34
			
			@media $xs
				font-size 21px
				letter-spacing 0.02em
	
	&-news
		& > ul > li::before
			margin-right 4px