.UserMenu
	&_link
		display flex
		align-items center
		padding 10px 25px 6px
		border-radius 14px
		font-weight 500
		@extend .transition
		
		& > .SvgIco
			width 13px
			margin-right 19px
			color #8D8E8E
			@extend .transition
		
		&.active, &:hover
			background #F0F3F6
			
			& > .SvgIco
				color $black
	
	&_linkText
		margin-top 3px