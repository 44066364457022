.PageAddress
	display flex
	align-items center
	height 44px
	padding-right 12px
	padding-left 12px
	border 1px solid $grey
	border-radius 3px
	font-size 15px
	
	&_siteUrl
		color #999
	
	&_companyUrl
		flex auto 1 1
		width 100%
		border none
		outline none