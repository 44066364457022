.Entity
	display inline-flex
	align-items center
	color $blue2
	font-weight 500
	
	& > .SvgIco
		margin-right 4px
	
	&_name
		margin-top 1px