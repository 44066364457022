.CompaniesFilter
	font-size 15px
		
	&_title
		margin-bottom 13px
		padding 6px 0 15px
		border-bottom 1px solid $grey
		font-weight 600
	
	&_block
		margin-bottom 18px
		
		&:last-child
			margin-bottom 0
	
	&_link
		display block
		padding 8px 0
		font-weight 500
		@extend .transition
		
		&:hover, &-active
			color $blue