.NewsSidebar
	width 360px
	max-width 100%
	
	@media $xs
		padding-top 22px
		border-top 1px solid $grey
	
	&_header
		display flex
		-webkit-justify-content space-between
		justify-content space-between
		-webkit-align-items center
		align-items center
		margin-bottom 30px
		padding-bottom 14px
		border-bottom 1px solid #ebf0f2
		
		@media $xs
			margin-bottom 22px
			padding-bottom 0
			border-bottom none
	
	&_title
		font-weight 600
		font-size 16px
	
	&_otherNewsLink
		display inline-flex
		-webkit-align-items center
		align-items center
		font-weight 500
		font-size 13px
		color $blue
		
		& > svg
			margin-left 7px
	
	&_item
		margin-bottom 30px
		
		@media $xs
			margin-bottom 36px
		
		&:last-child
			margin-bottom 0