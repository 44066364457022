.Error
	font-weight 500
	color $red
	
	@media $smMinus
		font-size 13px
	
	@media $xs
		font-size 12px
	
	&-small
		font-size 13px