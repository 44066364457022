.Activity
	position relative
	line-height 22px
	
	&::after
		content ""
		position absolute
		top 0
		right 0
		bottom 0
		width 7px
		height 12px
		margin auto
		background-image url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='13'%3E%3Cstyle%3E%3C/style%3E%3Cg id='Card х2'%3E%3Cg id='Вакансия'%3E%3Cpath id='Фигура 1 копия 5' d='M.98 2.5c-.38-.38-.37-1 0-1.37l.08-.09c.38-.38.98-.38 1.36 0L7.3 6c.38.38.38.99 0 1.38l-4.88 4.95c-.38.38-.99.38-1.36 0l-.08-.08c-.38-.38-.38-1 0-1.38L5.1 6.69.98 2.5z' fill='%23b8c1cc'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")
		
		@media $smPlus
			display none
	
	&_name
		font-weight 500
		font-size 15px
		color #404040
	
	&_salary
		font-weight 600
		color $blue