.Article
	word-break break-word
	
	&_header
		margin-bottom 14px
	
	&_title
		margin-bottom 22px
		font-weight 500
		font-size 28px
		line-height 1.42857
		
		@media $xs
			font-weight bold
			font-size 24px
	
	&_statIconsWrapper
		display flex
		-webkit-align-items center
		align-items center
		margin-bottom 17px
		
		@media $xs
			margin-bottom 14px
	
	&_imgWrap
		margin-bottom 25px
	
	&_text
		margin-bottom 30px
		font-size 16px
		line-height 1.625
		
		h1,
		h2,
		h3
			font-weight 500
			line-height 1.2
		
		h1
			margin 0.67em 0
			font-size 2em
		
		h2
			margin 0.83em 0
			font-size 1.5em
		
		h3
			margin 1em 0
			font-size 1.17em
		
		a
			color $blue
		
		p
			margin-bottom 10px
			
			&:last-child
				margin-bottom 0
		
		ul,
		ol
			margin 1em 0
			padding-left 40px
		
		ul
			list-style disc inside
		
		img,
		iframe,
		object,
		embed
			max-width 100%
			height auto
			
			@media $xxs
				max-width calc(100% + 40px)
				margin-left -20px
				height auto !important
	
	&_complete
		margin-bottom 30px
	
	&_link
		color $blue
		font-weight 500
		font-size 15px
		
		&-langSwitcher
			font-weight normal
			font-size 13px
	
	&_footer
		display flex
		justify-content space-between
		align-items flex-start
		
		@media $xs
			margin-bottom 30px
			align-items center
	
	&_btnWrapper
		text-align center
	
	&-twoCol
		display flex
		
		@media $xs
			flex-direction column
			padding-bottom 26px
			width 320px
			max-width 100%
			border-bottom 1px solid $grey
		
		.Article_col
			flex-basis 50%
			
			&-right
				margin-left 30px
				
				@media $smMinus
					margin-left 20px
				
				@media $xs
					margin-left 0
		
		.Article_imgWrap
			margin-top 7px
			
			@media $xs
				margin-bottom 16px
				text-align center
				
		.Article_title
			margin-bottom 14px
			
			font-size 22px
			
			@media $xs
				font-weight 600
				font-size 21px
				line-height 1.238
		
		.Article_text
			margin-bottom 25px
			font-size 15px
			
			@media $xs
				margin-bottom 13px
				font-size 15px
				line-height 1.6
		
		.Article_statIcons
			margin-right -4px
			
			@media $xs
				margin-right 0
	
	&-news
		.Article_title
			margin-bottom 13px
			
			@media $xs
				margin-bottom 15px
				
		.Article_statistics-views
			margin-bottom 17px
	
	&-full
		max-width 720px
		
		.Article_title
			margin-bottom 17px
			
			@media $xs
				margin-bottom 14px
				font-weight 600
				font-size 21px
				line-height 1.34
		
		.Article_text
			margin-bottom 28px
			
			@media $xs
				margin-bottom 18px
		
		.Article_footer
			margin-bottom 20px