.ContactsMenu
	&_item
		margin-bottom 18px
		font-size 20px
		color #404040
		
		&:last-child
			margin-bottom 0
	
	&_link
		display flex
		justify-content space-between
		align-items center
	
	&_linkWrapper
		font-weight 500
		font-size 18px
		color $blue