.CommentsBlock
	padding-right 20px
	
	@media $xs
		padding-right 0
	
	&_header
		display flex
		justify-content space-between
		align-items center
		margin-bottom 29px
		padding-right 10px
		padding-bottom 6px
		padding-left 3px
		border-bottom 1px solid #ebf0f2
		
		@media $xs
			// margin-bottom 28px
			margin-bottom 21px
			padding-right 3px
			padding-left 6px
	
	&_title
		font-weight 600
		font-size 16px
		line-height normal
	
	&_dropdownSelect
		margin-top 5px
	
	&_notice
		margin -2px 10px 19px 3px
		
		&-rejected,
		&-onCheck
			margin 0 -30px 0
	
	&_commentForm
		margin 0 10px 20px 3px
		
		@media $xs
			// margin 0 5px 13px 6px
			margin 0 5px 38px 6px
	
	&_reviewNote
		margin-top -6px
		margin-bottom 23px
		
		@media $xs
			margin 35px -20px 23px
	
	&_item
		margin-bottom 17px
		
		&-rejected,
		&-onCheck
			margin-right -30px
			margin-left -30px
	
	&_bottom
		padding 0 30px 30px
	
	&_expandLink
		color $blue
	
	&-company
		padding-right 0
		
		@media $xs
			padding-bottom 22px
			border-bottom 1px solid $grey
		
		.CommentsBlock_commentForm
			margin 0 0 20px 0
			
			@media $xs
				margin-bottom 10px
		
		.CommentsBlock_item
			margin-bottom 23px
			
			@media $xs
				margin-bottom 13px
		
		.CommentsBlock_footer
			padding-top 7px
			text-align right
			
			@media $xs
				text-align left
	
	&-vacancyFull
		.CommentsBlock_header
			@media $xs
				padding-right 0
				padding-left 0