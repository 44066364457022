.VacancyBlock
	display flex
	justify-content space-between
	align-items center
	padding-top 12px
	padding-bottom 12px
	
	&_left
		overflow hidden
	
	&_right
		flex-shrink 0
	
	&_title
		margin-right 10px
		margin-bottom 3px
		font-weight 500
		font-size 16px
		overflow hidden
		text-overflow ellipsis
		white-space nowrap
	
	&_workplace
		font-size 13px
		line-height 1.53846
		color #999999