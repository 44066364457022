.SelectionLink
	display inline-block
	padding-right 15px
	background url('../img/arrow_bottom_blue.svg') right center no-repeat
	color $blue
	font-weight 600
	font-size 16px
	
	&-small
		font-weight 500
		font-size 14px