.Message
	position relative
	display flex
	align-items flex-start
	width 320px
	padding 19px 14px 16px 17px
	
	@media $xs
		width auto
		padding 14px 20px
	
	&:after
		content ""
		position absolute
		right 15px
		bottom 0
		left 15px
		height 1px
		background #EBF0F2
		
		@media $xs
			display none
	
	&:hover
		background #F2F4F6
	
	&-highlighted,
	&-full,
	&:hover,
	&:last-child
		&:after
			display none
	
	&_userPhoto
		flex-shrink 0
		margin-right 12px
	
	&_mainWrapper
		width 236px
		line-height 21px
		
		@media $xs
			width calc(100% - 52px)
	
	&_header, &_body
		display flex
		justify-content space-between
	
	&_header
		@media $xs
			margin-top -2px
			margin-bottom 2px
	
	&_nameWrapper
		display flex
		align-items center
		overflow hidden
	
	&_name
		font-weight 600
		font-size 14px
		white-space nowrap
		overflow hidden
		text-overflow ellipsis
		
		@media $xs
			font-size 15px
			line-height 1.4
	
	&_statusIcon
		margin-left 4px
		
		@media $xs
			margin-left 3px
	
	&_dateTime
		flex-shrink 0
		margin-left 7px
		font-size 11px
		color $blue2
		
		@media $xs
			margin-left 13px
			font-size 13px
	
	&_wrapper
		display flex
		flex-direction column
		align-items flex-start
	
	&_text
		color #4C4C4C
		font-size 13px
		white-space nowrap
		overflow hidden
		text-overflow ellipsis
		
		@media $xs
			font-size 14px
			line-height 1.5
		
		&-twoLines
			display -webkit-box
			-webkit-line-clamp 2
			-webkit-box-orient vertical
			white-space normal
			line-height 18px
			
			@media $xs
				line-height 1.5
	
	&_quantity
		flex-shrink 0
		margin-top 3px
		margin-left 9px
		
		@media $xs
			margin-top 1px
	
	&_info
		float right
		margin-top -14px
		
		@media $xs
			margin-top -18px
	
	&-highlighted
		background #F2F4F6
	
	&-full
		width auto
		padding 0
		
		&:hover
			background transparent
		
		.Message_userPhoto
			margin-right 10px
		
		.Message_body
			position relative
			display block
			margin-bottom 5px
			padding 9px 11px 5px 16px
			border-radius 9px
			
			@media $xs
				padding 8px 18px 8px 14px
				border-radius 16px
			
			&:last-child
				margin-bottom 0
		
		.Message_header
			justify-content flex-start
			
			@media $xs
				margin-bottom 0
		
		.Message_dateTime,
		.Message_statusIcon
			display inline-block
			vertical-align middle
		
		.Message_statusIcon
			margin-left 0
		
		.Message_text
			font-size 14px
			line-height 21px
			color $black
			white-space normal
			
			&:after
				content ""
				display inline-block
				visibility hidden
			
			@media $xs
				font-size 16px
				line-height 1.3125
		
		.Message_dateTime
			margin-left 0
			font-size 12px
			
			@media $xs
				font-size 11px
	
	&-short
		.Message_text
			@media $xs
				color #999
	
	&-interlocutor
		.Message_body
			max-width 364px
			background #EBF0F2
			
			@media $xs
				max-width 226px
				padding 9px 16px
			
			&:first-child
				border-top-left-radius 0				
		
		.Message_text
			&:after
				width 34px
	
	&-you
		justify-content flex-end
		
		.Message_body
			max-width 405px
			background #D9EBFC
			
			@media $xs
				max-width 235px
			
			&:first-child
				border-bottom-right-radius 0
		
		.Message_text
			&:after
				width 57px
				
				@media $xs
					width 54px
		
		.Message_info
			margin-bottom -1px
		
		.Message_statusIcon
			width 18px