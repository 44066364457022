.CompaniesBalances
	position relative
	padding-top 27px
	border-radius 4px/9px
	box-shadow 0 4px 0 0 rgba($blue, 1) inset, 0 0 10px 0 rgba($black, 0.1)
	
	&_item
		margin-bottom 20px
		border-bottom 1px solid $grey
		
		&:last-child
			margin-bottom 0
			border-bottom none
		
		&-first
			position relative
			z-index 11
	
	&_additional
		position absolute
		z-index 10
		top 100%
		right 0
		left 0
		padding-top 21px
		background $white
		box-shadow 0 0 10px 0 rgba($black, 0.1)