.MenuSection
	position relative
	display flex
	justify-content space-between
	align-items center
	
	@media $xs
		padding-right 20px
		padding-bottom 14px
		padding-left 19px
		overflow auto
	
	&:before
		content ""
		position absolute
		right 0
		bottom 0
		left 0
		height 1px
		background $grey
	
	&_selectionLink
		margin-right 25px
		
		&:last-child
			margin-right 0
	
	&-profile
		@media $xs
			padding-bottom 20px
	
	&-notifications
		overflow visible
	
	&-company,
	&-companySettings,
	&-vacancyFull
		overflow hidden
		
		@media $xs
			position relative
			padding-right 0
			padding-bottom 0
			padding-left 0
			background-image -webkit-linear-gradient(to top, $lightGrey, #fff)
			background-image -o-linear-gradient(to top, $lightGrey, #fff)
			background-image linear-gradient(to top, $lightGrey, #fff)
			
			&:after
				content ""
				position absolute
				top 20px
				right 0
				width 32px
				height 10px
				background url('../img/menu_section_blur.png') 0 0 / 100% 100% no-repeat
				pointer-events none
	
	&-save
		@media $xs
			padding-right 0
			padding-bottom 0
			padding-left 0
			background-image -webkit-linear-gradient(to top, $lightGrey, #fff)
			background-image -o-linear-gradient(to top, $lightGrey, #fff)
			background-image linear-gradient(to top, $lightGrey, #fff)
			
		.MenuSection_right
			display flex
			align-items center
		
		.MenuSection_selectWrapper
			margin-right 21px
			
			&:last-child
				margin-right 7px
		
		.MenuSection_saveFilter
			margin-top -1px
	
	&-vacancyFull
		@media $xs
			background-image none
			
			&::after
				top 19px
				height 14px
	
	&-fixed,
	&-sticky
		@media $xs
			z-index 10
			right 0
			left 0
	
	&-fixed
		top 67px
		height 34px
		padding-right 10px
		padding-left 10px
		background $white
		@extend .transition
		
		@media $xs
			position fixed
	
	&-fixedTop
		top 0
	
	&-sticky
		@media $xs
			position sticky
			z-index 10
			top 51px
			background $white
	
	&-small
		@media $xs
			&::after
				top 9px
				width 18px