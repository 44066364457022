.WriteUs
	background #F7F9FA
	
	@media $xs
		background $white
	
	@media $smPlus
		display flex
		align-items center
		padding-top 20px
		padding-bottom 20px
	
	&_container		
		@media $xs
			padding-top 46px
			padding-bottom 49px
	
	&_title
		margin-bottom 0.8611em
		font-weight 600
		font-size 36px
		line-height 1.278
		text-align center
		
		@media $mdMinus
			font-size 32px
		
		@media $smMinus
			font-size 28px
		
		@media $xs
			margin-bottom 27px
			font-size 24px
	
	&-pricing
		@media $smPlus
			padding 89px 0 67px
		
		@media $xs
			background #F5F7FA
		
		.WriteUs_container
			@media $xs
				padding-top 28px
				padding-bottom 28px
		
		.WriteUs_title
			margin-bottom 21px
			
			@media $xs
				margin-bottom 15px
				font-size 23px
				line-height 1.2