.TopBtn
	position fixed
	z-index 1000
	top 0
	bottom 0
	left 0
	display inline-grid
	grid-template-columns auto 1fr
	column-gap 9px
	align-items end
	padding 10px 20px 24px
	border none
	font-weight 600
	background none
	color $blue3
	font-size 13px
	visibility hidden
	opacity 0
	@extend .transition
	
	&::before
		content ""
		width 17px
		height 10px
		margin-bottom 2px
		background url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16.904' height='9.866' viewBox='0 0 16.904 9.866'%3E%3Cpath data-name='Прямоугольник 1' d='m.707 9.159 7.745-7.745 7.745 7.745' fill='none' stroke='%238396ac' stroke-width='2'/%3E%3C/svg%3E") 0 0 no-repeat
	
	&:hover
		background rgba($blue3, 0.1)
	
	&-visible
		visibility visible
		opacity 1
		
	@media $xs
		display none
	
	@media (max-width 1439px)
		display none