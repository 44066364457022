.RadioBtn
	position relative
	display block
	
	&_input
		position absolute
		clip rect(0, 0, 0, 0)

		&:checked ~ .RadioBtn_label:before
			border-width 4px
			border-color #dbeafe
			background-color $blue
	
	&_label
		position relative
		display flex
		align-items flex-start
		font-size 14px
		cursor pointer

		@media $xs
			font-size 16px
		
		&::before
			content ""
			flex-shrink 0
			width 16px
			height 16px
			margin-right 10px
			border 1px solid #dfe2e6
			border-radius 50%
			transition all .15s ease-in-out

			@media $xs
				width 18px
				height 18px
				margin-right 12px