.VacancyDummy
	padding-top 7px
	padding-bottom 70px
	
	@media $xs
		padding-top 20px
		padding-bottom 80px
	
	&_categoriesList
		margin-top 40px
		margin-bottom 23px
		
		@media $smMinus
			margin-top 25px