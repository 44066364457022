.MobilePopupMenu
	&_list
		list-style none
	
	&_link
		display block
		// padding 12px 20px
		
		&:hover,
		&-active
			background $lightGrey