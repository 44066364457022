.UserRating
	display inline-flex
	align-items baseline
	height 20px
	padding 0px 5px 0px 17px
	border-radius 4px
	background $blue url("data:image/svg+xml,%3Csvg version='1.2' baseProfile='tiny-ps' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 7 7'%3E%3Cstyle%3E%3C/style%3E%3Cg id='Ratings'%3E%3Cpath id='Фигура 3 копия 15' d='M5.63 7c-.03 0-.06-.01-.09-.02l-2.03-.9-2.03.9c-.07.03-.16.02-.22-.03a.204.204 0 01-.08-.22l.45-2.23L.07 2.93a.226.226 0 01-.06-.22c.02-.08.09-.13.17-.15l2.24-.45L3.31.1c.07-.16.33-.16.4 0l.9 2.01 2.23.45c.08.02.15.07.17.15.02.08 0 .16-.05.22L5.39 4.5l.45 2.23c.02.09-.01.17-.08.22-.04.03-.09.05-.13.05z' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E") 7px center / 7px auto no-repeat
	color $white
	font-weight 500
	font-size 15px
	
	&_number
		margin-right 3px
	
	&_max
		font-size 10px
	
	&-green
		background-color #2FA75C
	
	&-orange
		background-color #f89333
	
	&-red
		background-color #ed184a