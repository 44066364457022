.Soclink
	font-weight 600
	font-size 18px
	line-height 1.34
	color $blue
	
	& > .SvgIco
		margin-right 9px
		color $blue2
		
		@media $xs
			width 17px
			margin-right 5px
	
	&_text
		position relative
		white-space nowrap
		
		&:after
			content ""
			position absolute
			right 0
			bottom 0
			width 100%
			height 1px
			background $blue
			@extend .transition
	
	&:hover
		.Soclink_text:after
			background transparent
	
	&-vacancies
		font-weight 500
		line-height normal
		
		@media $smPlus
			font-size 16px
		
		& > .SvgIco
			width 18px
			margin-right 8px
			
			@media $xs
				width 20px
				margin-right 13px
	
	&-inlineGrid
		display inline-grid
		grid-template-columns auto 1fr
		align-items center
	
	&-medium
		font-weight 500
	
	&-contactsBlock
		font-weight 500
		font-size 16px
		line-height 1.5
		
		.Soclink_text:after
			bottom 4px
		
		& > .SvgIco
			// max-width 22px
			max-width 17px
			margin-right 6px
	
	&-twoIcons
		grid-template-columns auto auto 1fr