.ConfirmLabel
	display inline-flex
	align-items center
	color $green2
	font-weight 500
	font-size 13px
	
	&:before
		content ""
		width 11px
		height 12px
		margin-right 5px
		background url('../img/confirm_icon.svg') 0 0 no-repeat
		vertical-align middle