.SquareBtn
	display inline-grid
	justify-items center
	grid-template-rows 38px 1fr
	padding 17px 8px
	border-radius 4px
	font-weight 500
	font-size 13px
	line-height 15.6px
	background #F5F7FA
	text-align center
	
	&::before
		align-self center
	
	&-write
		&::before
			content ""
			width 24px
			height 24px
			// margin-bottom 10px
			background url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24.004' height='24'%3E%3Cpath data-name='Фигура 897' d='M12.004 0c-6.618 0-12 5.1-12 11.368a10.885 10.885 0 0 0 1.908 6.149c-.236 2.753-.87 4.8-1.79 5.764a.438.438 0 0 0-.075.486.4.4 0 0 0 .357.233.352.352 0 0 0 .056 0 20.6 20.6 0 0 0 6.648-2.248 12.454 12.454 0 0 0 4.9.989c6.618 0 12-5.1 12-11.368S18.62 0 12.004 0Zm-5.6 13.053a1.686 1.686 0 1 1 1.6-1.684 1.646 1.646 0 0 1-1.6 1.684Zm5.6 0a1.686 1.686 0 1 1 1.6-1.684 1.645 1.645 0 0 1-1.6 1.684Zm5.6 0a1.686 1.686 0 1 1 1.6-1.684 1.645 1.645 0 0 1-1.6 1.684Z' fill='%234b97f9'/%3E%3C/svg%3E") 0 0 no-repeat
			
	&-call
		&::before
			content ""
			width 24px
			height 24px
			// margin-bottom 10px
			background url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23.348' height='23.993'%3E%3Cpath data-name='Фигура 887 копия 2' d='m22.824 18.978-3.606-3.715a1.841 1.841 0 0 0-2.651.052l-1.816 1.871-.359-.206a18.088 18.088 0 0 1-4.369-3.254 18.732 18.732 0 0 1-3.166-4.509 9.093 9.093 0 0 0-.2-.36l1.219-1.254.6-.617a1.976 1.976 0 0 0 .048-2.73L4.918.54a1.839 1.839 0 0 0-2.651.052L1.251 1.645l.028.028a6.1 6.1 0 0 0-.837 1.522 6.452 6.452 0 0 0-.373 1.543c-.476 4.064 1.328 7.78 6.223 12.822 6.766 6.969 12.219 6.443 12.454 6.416a5.9 5.9 0 0 0 1.5-.388 5.792 5.792 0 0 0 1.472-.859l.022.02 1.03-1.038a1.979 1.979 0 0 0 .054-2.733Z' fill='%234b97f9'/%3E%3C/svg%3E") 0 0 no-repeat
			
	&-orderCall
		&::before
			content ""
			width 24px
			height 24px
			// margin-bottom 10px
			background url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23.998' height='24.02'%3E%3Cpath data-name='Фигура 887 копия' d='M6.293 17.585C1.393 12.542-.407 8.827.07 4.763A6.458 6.458 0 0 1 .443 3.22a6.1 6.1 0 0 1 .837-1.522l-.028-.028L2.268.618A1.839 1.839 0 0 1 4.919.566l3.605 3.716a1.975 1.975 0 0 1-.049 2.73l-.6.617-1.22 1.254c.064.115.128.235.2.361a18.742 18.742 0 0 0 3.166 4.509 18.075 18.075 0 0 0 4.369 3.254l.358.206 1.817-1.871a1.842 1.842 0 0 1 2.65-.051l3.605 3.715a1.979 1.979 0 0 1-.048 2.732l-1.03 1.038-.022-.02a5.782 5.782 0 0 1-1.471.859 5.931 5.931 0 0 1-1.5.388 4.1 4.1 0 0 1-.431.019c-1.438-.002-6.253-.495-12.025-6.437Zm8.629-8.246V.648h1.78v5.563l6.037-6.212 1.259 1.3-6.034 6.211h5.4v1.832Z' fill='%234b97f9'/%3E%3C/svg%3E") 0 0 no-repeat