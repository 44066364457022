.DotsIcon
	display block
	width 14px
	color $blue2
	opacity 0.4
	@extend .transition
	
	&:hover
		opacity 1
	
	&-entityStatus
		opacity 1
	
	&-entityStatusHidden
		color $orange