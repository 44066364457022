.Search
	width 487px
	max-width 100%
	
	&_closeBtn
		display inline-flex
		flex-shrink 0
		margin-right 1px
		padding 10px
		
		& > .SvgIco
			width 24px
	
	&_item
		flex-grow 1
		position relative
	
	&_text
		width 100%
		height 45px
		padding-left 30px
		padding-right 50px
		border 1px solid $grey
		outline none
		border-radius 25px
		background $white url('../img/icon_search.svg') calc(100% - 18px) 50% no-repeat
		+placeholder()
			color $placeholder
			opacity 0.5
	
	&_icon
		position absolute
		top 0
		right 0
		display inline-flex
		justify-content center
		align-items center
		flex-shrink 0
		width 36px
		height 36px
		border none
		outline none
		border-radius 50%
		background #F2F2F2
	
	&-categories
		width auto
		
		.Search_text
			width 100%
			height 52px
			padding-left 40px
			border-radius 3px
			background-position 14px center
			background-size 14px auto
			font-size 12px
			+placeholder()
				opacity 1
	
	&-companies
		width auto
		
		.Search_text
			width 100%
			height 52px
			padding-left 43px
			border-radius 3px
			background-position 16px center
			background-size 16px auto
			font-size 14px
			+placeholder()
				opacity 1
				color $blue2
	
	&-companiesDossiers,
	&-vacancies
		@media $xs
			display flex
			align-items center
		
		.Search_item
			@media $xs
				margin-right 17px
				
		.Search_text
			height 50px
			
			@media $xs
				height 45px
				padding-right 42px
				padding-left 13px
				background-image none
				font-size 13px
		
		.Search_closeBtn
			position absolute
			top 6px
			right -2px
			opacity 0.5
			color #ababab
			
			@media $xs
				position absolute
				top 3px
				right 0
			
			& > .SvgIco
				width 18px
				color $blue2
				
		.Search_icon
			position relative
			
			.SvgIco
				width 16px
	
	&-vacancies
		.Search_text
			font-size 14px
		
		.Search_icon
			right 5px
	
	&-header
		display none
		position absolute
		z-index 100
		top 28px
		right 400px
		left 168px
		width auto
		background $white
		
		.Search_closeBtn
			color #C5C7CA
			
			@media $xs
				position static
				order -1
				color $black
		
		.Search_icon
			position absolute
			right 4px
			width 45px
			height 45px
			background transparent
			color $blue
			
			.SvgIco
				@media $xs
					width 20px
			
			@media $xs
				width 35px
				height 35px
				background #F2F2F2
				color $black
		
		.Search_text
			padding-right 70px
			padding-left 52px
			background-position 20px center
			background-image url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTguMiAwYzQuNTIzIDAgOC4yMDIgMy42OCA4LjIwMiA4LjIgMCAxLjk4LS43MDcgMy44LTEuODc5IDUuMjE4TDIwIDE4Ljg5NSAxOC44OTUgMjBsLTUuNDc3LTUuNDc3YTguMTcgOC4xNyAwIDAxLTUuMjE5IDEuODhjLTQuNTIzIDAtOC4xOTktMy42OC04LjE5OS04LjJTMy42OCAwIDguMiAwem0wIDE0Ljg0YzMuNjYgMCA2LjY0LTIuOTc3IDYuNjQtNi42NEE2LjY0NSA2LjY0NSAwIDAwOC4yIDEuNTU4YTYuNjQ1IDYuNjQ1IDAgMDAtNi42NDEgNi42NGMwIDMuNjY0IDIuOTggNi42NCA2LjY0IDYuNjR6IiBmaWxsPSIjNGI5N2Y5Ii8+PC9zdmc+')
			
			@media $xs
				width calc(100% - 63px)
				margin-left 10px
				font-size 16px !important
				
				&:focus
					font-size 16px !important
		
		@media $smMinus
			top 23px
			width calc(100% - 309px)
		
		@media $xs
			display none
			height 55px
			align-items center
			position absolute
			z-index 10
			top 0
			left 0
			width 100%
			padding-top 17px
			padding-right 1px
			background $white
			
			.Search_text
				height 35px
				margin-right 14px
				padding-right 14px
				padding-left 14px
				border-radius 0
				background #F2F2F2
				font-size 13px
				+placeholder()
					color #AAA
				
				@media $xs
					padding-right 12px
					padding-left 12px
					font-weight 500
					font-size 12px
			
	&-visibleFlex
		display flex
	
	&-results
		width auto
		
		@media $xs
			display flex
			align-items center
		
		// .Search_item
		// 	@media $xs
		// 		margin-right 17px
		
		.Search_text
			width 100%
			height 52px
			padding-left 40px
			border-radius 3px
			background-position 17px center
			background-size 17px auto
			font-size 12px
			+placeholder()
				opacity 1
			
			@media $xs
				height 45px
				padding-right 42px
				border-color #D2D7D9
				// padding-left 13px
				// background-image none
				background-position 15px center
				// font-size 13px
				font-size 14px
				+placeholder()
					color $blue2
		
		.Search_closeBtn
			color #ababab
			
			@media $xs
				position absolute
				top 0
				right 0
		
		.Search_icon
			position relative
			
			.SvgIco
				width 16px
	
	&-messages
		position relative
		width auto
		
		.Search_text
			height 47px
			padding-right 25px
			padding-left 25px
			border none
			border-radius 0
			background url('../img/messages_search_icon.svg') 0 50% no-repeat
			font-size 13px
		
		.Search_closeBtn
			position absolute
			top 4px
			right -13px
			width 37px
			margin-right 0
			color $blue2
	
	&-mobileHeader
		position absolute
		top 0
		left 0
		width 100%
		background $white
		
		.Search_btnBack
			position absolute
			top 16px
			left 20px
		
		.Search_text
			height 51px
			padding-left 72px
			border none
			border-radius 0
			background none
			font-size 15px
			color $blue2
		
		.Search_closeBtn
			position absolute
			top 4px
			right 5px
			color $blue3