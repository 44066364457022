.CompanyInfo
	display flex
	align-items flex-start
	padding 17px
	background $white
	box-shadow 0 1px 5px 0 rgba(0, 0, 0, 0.12)
	@extend .transition
	
	&:hover
		background $lightGrey
	
	&_logo
		width 40px
		margin-right 14px
	
	&_logoImg
		display block
		border-radius 4px
	
	&_wrapper
		padding-top 2px
	
	&_name
		font-weight 500
		font-size 16px
		line-height 1.25
	
	&-balance
		padding 7px 30px 25px 32px
		box-shadow none
		
		&:hover
			background $white
			
		.CompanyInfo_logo
			width 50px
		
		.CompanyInfo_wrapper
			flex auto 1 1
			display flex
			justify-content space-between
			align-items flex-start
			margin-top -6px
		
		.CompanyInfo_name
			margin-top -5px
			font-weight 600
			font-size 21px
			line-height 1.857
		
		.CompanyInfo_balance
			font-weight 600
			font-size 15px
		
		.CompanyInfo_sum
			font-size 21px
	
	&-company
		padding 0
		box-shadow none
		
		@media $xs
			align-items center
		
		&:hover
			background $white
		
		.CompanyInfo_wrapper
			flex-grow 1
		
		.CompanyInfo_logo
			width 70px
			flex-shrink 0
			
			@media $xs
				width 65px
				margin-right 17px
		
		.CompanyInfo_nameWrapper
			display flex
			justify-content space-between
			align-items center
			margin-top -7px
			margin-bottom 3px
		
			.Bookmark
				// margin-right 5px
				margin-right 0
				
				&-companyBellCheck
					margin-right 7px
		
		.CompanyInfo_name
			// display -webkit-box
			// -webkit-line-clamp 2
			// -webkit-box-orient vertical
			white-space normal
			// overflow hidden
			margin-right 10px
			font-size 18px
			line-height 1.45
		
		.CompanyInfo_statIcons
			.StatIcons_item
				@media $xs
					margin-right 0
	
	&-similarTopics
		padding 0
		box-shadow none
		
		.CompanyInfo_logo
			margin-right 10px
		
		.CompanyInfo_wrapper
			margin-top -4px
			padding-top 0
		
		.CompanyInfo_name
			margin-bottom -1px
			color #404040
			font-size 15px
			line-height 1.6
		
		&:hover
			background transparent