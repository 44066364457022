.Like
	display inline-flex
	justify-content flex-start
	align-items center
	color $black
	font-size 13px
	@extend .transition
	
	& > .SvgIco
		width 18px
		margin-right 5px
		color $blue2
		opacity 0.8
		
		@media $xs
			width 21.33px
	
	&-comment
		& > .SvgIco
			width 16px
	
	&-post
		& > .SvgIco
			width 24px
		
			@media $xs
				width 24.52px
	
	&-news		
		& > .SvgIco
			width 23px
	
	&-liked
		color $orange
		
		& > .SvgIco
			color $orange
			
			path
				fill $orange
	
	&-animated
		& > .SvgIco
			animation pulsating-animation .45s ease-in-out
	
	&-searchPage
		& > .SvgIco
			@media $xs
				width 22px
				margin-right 6px

@keyframes pulsating-animation
	from,
	to
		transform scale(1)
	25%
		transform scale(1.2)
	50%
		transform scale(0.95)

// @keyframes like-heart-animation
// 	from,
// 	to
// 		opacity 0
// 		transform scale(0)
// 	15%
// 		opacity 0.9
// 		transform scale(1.2)
// 	30%
// 		transform scale(0.95)
// 	45%,
// 	80%
// 		opacity 0.9
// 		transform scale(1)