.MobileHeader
	position fixed
	z-index 100
	top 0
	left 0
	width 100%
	padding 14px 15px
	border-bottom 1px solid $grey
	background $white
	
	&_btnBack
		position absolute
		top 15px
		left 20px
	
	&_titleWrapper
		padding-right 30px
		padding-left 30px
		text-align center
	
	&_title
		font-weight 600
		font-size 16px
		text-align center
		
		&-popupMenuLink
			display inline-block
			padding-right 14px
			background url('../img/arrow_black_bottom.png') right center no-repeat
	
	&_searchLink
		position absolute
		top 16px
		right 20px
		width 18px
		color #444
	
	&_siteName
		position relative
		top 1px
	
	&_dotsMenu, &_dotsIcon
		position absolute
		top 15px
		right 20px
	
	&_phoneIcon
		position absolute
		top 14px
		right 55px
		color $blue
	
	&-passwordRecovery
		padding-top 18px
		
		.MobileHeader_btnBack
			top 19px
	
	&-filter
		position relative
		padding-top 18px
		
		.MobileHeader_btnBack
			top 19px
	
	&-noBorder
		border-bottom-color transparent
	
	&-companiesDossiers,
	&-vacancies
		display flex
		justify-content space-between
		align-items center
		padding-right 47px
		padding-left 21px
	
	&-search
		padding-bottom 9px
		padding-left 20px
	
	&-profileMessages
		.MobileHeader_title
			margin-top 2px
	
	&-news,
	&-article,
	&-feed
		display flex
		align-items center
	
	&-news,
	&-article
		justify-content space-between
		padding-right 28px
		padding-left 60px
		
		.MobileHeader_btnBack
			top 15px
	
	&-news
		padding-right 20px
	
	&-feed
		justify-content center
		
		.MobileHeader_siteName
			position absolute
			top 17px
			left 20px
	
	&-lightGrey
		background #F8F9FA
		
	&-company,
	&-addTestimonial,
	&-vacancy
		.MobileHeader_title
			text-overflow ellipsis
			overflow hidden
			white-space nowrap
			margin-right 10px
			margin-left 10px
			
			// &:after
			// 	content ""
			// 	display inline-block
			// 	width 12px
			// 	height 12px
			// 	margin-left 6px
			// 	background url('../img/checkbox_green.svg') 0 0 / cover no-repeat
		
	// &-addTestimonial
	// 	.MobileHeader_title
	// 		text-overflow ellipsis
	// 		overflow hidden
	// 		white-space nowrap
	// 		margin-right 10px
	// 		margin-left 10px
			
	// 		&:after
	// 			content ""
	// 			display inline-block
	// 			width 12px
	// 			height 12px
	// 			margin-left 6px
	// 			background url('../img/checkbox_green.svg') 0 0 / cover no-repeat
	
	&-hasPhoneIcon
		.MobileHeader_title
			margin-right 35px
			// margin-left 20px
			margin-left 10px
	
	&-withHeader
		position sticky
		top 0
		border-top 1px solid $grey
		// top 67px
		
		.MobileHeader_titleWrapper
			padding-right 25px
			text-overflow ellipsis
			overflow hidden
		
		.MobileHeader_phoneIcon
			right 26px