.NewsBlocks
	display flex
	flex-wrap wrap
	justify-content center
	align-items flex-start
	margin-right -15px
	margin-left -15px
	
	@media $xxs
		margin-right 0
		margin-left 0
	
	&_title
		margin-bottom 10px
		padding-bottom 10px
		border-bottom 1px solid #ebf0f2
		color $blue2
		font-weight 500
		font-size 15px
	
	&_items
		margin-bottom 10px
	
	&_item
		flex 0 0 auto
		margin-right 15px
		margin-bottom 41px
		margin-left 14px
		
		@media $xs
			margin-bottom 25px
		
		@media $xxs
			margin-right 0
			margin-left 0
	
	&_link
		color $blue
		font-weight 500
	
	&-column
		flex-direction column
		margin-right 0
		margin-left 0
		
		.NewsBlocks_item
			margin-right 0
			margin-bottom 35px
			margin-left 0
			
			@media $xs
				margin-right auto
				margin-bottom 25px
				margin-left auto
				
				&:last-child
					margin-bottom 0
			
			&:last-child
				margin-bottom 0
	
	&-saved
		justify-content flex-start
		padding-top 9px
		
		@media $xs
			padding-top 16px
	
	&-mobilePopup
		display block
		margin-right 0
		margin-left 0

		.NewsBlocks_title
			margin-bottom 15px

		.NewsBlocks_item
			margin-bottom 15px

			&:last-child
				margin-bottom 0