.CoverSelection
	&_header
		margin-bottom 37px
		
		@media $xxs
			margin-bottom 0
		
	&_title
		margin-bottom 4px
		font-weight 500
		font-size 16px
		
		@media $xxs
			margin-bottom 0
			font-size 18px
	
	&_imgWrapper
		&-large
			margin-bottom 30px
			width 110px
		
		&-small
			width 34px
	
	&_img
		display block
		border-radius 2px