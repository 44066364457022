.SimilarTopics
	padding 13px 25px 22px 27px
	background $white
	box-shadow 0 2px 7px 0 rgba(0, 0, 0, 0.12)
	
	&_header
		display flex
		justify-content space-between
		align-items center
		margin-bottom 26px
		padding-bottom 13px
		border-bottom 1px solid #ebf0f2
	
	&_title
		color $blue2
		font-weight 600
		font-size 15px
		line-height 1.6
	
	&_close
		color $blue
		font-weight 500
		
		& > .SvgIco
			margin-left 5px
	
	&_companies
		margin-bottom 17px
	
	&_companyInfo
		margin-bottom 20px
		
		&:last-child
			margin-bottom 0
	
	&_topic
		margin-bottom 15px
		
		&:last-child
			margin-bottom 0