.NumberField
	display flex
	align-items center
	
	&_formText
		width 158px
		min-width 0
		
		@media $xs
			width 132px
		
		// &-from,
		// &-to
		// 	width 134px
	
	&_rangeSeparator,
	&_suffix
		font-size 15px
		color #404040
	
	&_rangeSeparator
		margin 0 4px 3px
		
		@media $xs
			margin-bottom -5px
	
	&_suffix
		margin-left 9px
		
		&-rate
			@media $smPlus
				margin-left 13px
		
		&-noMargin
			margin-left 0
	
	&_currencySelect
		margin-left 10px
		
		&-rate
			margin-left 15px