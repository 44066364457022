.Advantage
	display flex
	width 550px
	max-width 100%
	
	@media $xs
		flex-direction column
		align-items center
		width 320px
	
	&_icon
		width 36px
		flex-shrink 0
		margin-right 28px
		color $blue2
		
		& > .SvgIco
			@media $xs
				width 45px
		
		@media $smMinus
			margin-right 21px
		
		@media $xs
			margin-right 0
			margin-bottom 17px
		
		&-smallMargin
			margin-right 21px
			
			@media $xs
				margin-right 0
	
	&_textWrap
		@media $xs
			text-align center
	
	&_title
		margin-bottom 0.47619em
		font-weight 600
		font-size 21px
		line-height 1.42857
		
		@media $mdMinus
			font-size 20px
		
		@media $smMinus
			font-size 19px
		
		@media $xs
			font-size 18px
	
	&_text
		font-size 16px
		line-height 1.5
		
		@media $smMinus
			font-size 15px
			line-height 1.6