.Users
	display flex
	align-items center
	color rgba($black, 77%)
	font-weight 700
	font-size 55px
	font-family "Decima Nova Pro", sans-serif
	
	@media $xs
		justify-content center
		font-size 40px
	
	&_icon
		margin-right 34px
		
		@media $xs
			flex-basis 80px
			margin-right 20px