.ImageUpload	
	&_input
		position absolute
		clip rect(0, 0, 0, 0)
	
	&_label
		display flex
		width 34px
		height 34px
		justify-content center
		align-items center
		background #F0F3F6
		border-radius 50%
		
		@media $xs
			width auto
			height auto
			border-radius 0
			background transparent
	
	&_text
		margin-top 7px
		padding-left 10px
		font-size 11px
		
		@media $xs
			margin-top 1px
			padding-left 12px
			font-weight 500
			font-size 14px
			color $blue
	
	&-topicCreation,
	&-vacancyCreation
		display flex
		align-items center
		
		svg
			width 18px
			margin-bottom 4px
			
			@media $xs
				width 22px
				margin-bottom 0
				color $blue

.dropzone.dropzone
	&-grey
		display flex
		justify-content center
		align-items center
		height 130px
		padding-right 30px
		padding-left 30px
		border none
		border-radius 2px
		background $lightGrey
		text-align center
		
		@media $xs
			height 190px
			font-size 16px
			line-height 1.5
		
		.dz-message
			font-weight 500
			color $blue3
		
			@media $xs
				font-size 16px
				line-height 1.5
	
	&-public
		background $lightGreen
	
	&-private
		background $pink