.JobSpecs
	&_item
		margin-bottom 10px
		
		&:last-child
			margin-bottom 0
	
	&-extended
		.JobSpecs_item
			@media $smPlus
				margin-bottom 13px
			
				&:last-child
					margin-bottom 0