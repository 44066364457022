.ReplyName
	color $blue2
	font-size 13px
	@extend .transition
	
	@media $xs
		font-size 12px
	
	& > .SvgIco
		margin-right 4px
		margin-bottom 2px
	
	&:hover
		color $blue
	
	&-companyReviews
		font-weight 500
		font-size 16px
		color $black
		
		& > .SvgIco
			margin-right 5px
			color $blue2
		
		&:hover
			color $blue2