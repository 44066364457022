.QuestionStatistics
	&_list, &_item
		display flex
	
	&_item
		align-items center
		font-size 13px
		margin-right 31px
		
		@media $xxs
			margin-right 23px
		
		& > .SvgIco
			margin-right 4px
			color $blue3
		
		&-likes
			& > .SvgIco
				width 27px
				margin-top 3px
		
		&:last-child
			margin-right 0
	
	&_right
		@media $xxs
			display flex
	
	&_views
		@media $xxs
			position relative
			margin-right 11px
			padding-right 13px
			color #9DA4AA
			
			& > .SvgIco
				margin-right 4px
			
			&:after
				content ""
				position absolute
				top 0
				right 0
				width 1px
				height 15px
				background #EAEAEA
				vertical-align middle
	
	&_country
		font-size 13px
		
		@media $xxs
			margin-right 2px
			font-size 12px
			color #9DA4AA
		
		& > img
			margin-right 6px
			margin-bottom 3px
			vertical-align middle
			
			@media $xxs 
				margin-right 4px			
	
	&-advanced
		display flex
		justify-content space-between
		
		@media $xxs
			align-items center