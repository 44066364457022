.CommentForm
	position relative
	
	&_replyName
		display inline-block
		margin-bottom 7px
	
	&_textArea
		min-height 50px
		margin-bottom 30px
		@extend .transition
		
		@media $xs
			// margin-bottom 25px
			margin-bottom 17px
	
	&_actions
		display none
		justify-content flex-end
		align-items center
		
		&-visible
			display flex
	
	// &_resetBtn
	// 	position relative
	// 	margin-right 19px
	// 	padding 11px 24px
	// 	border none
	// 	outline none
	// 	border-radius 20px
	// 	background #EBF0F2
	// 	font-size 14px
	// 	color $blue2
		
	// 	@media $xs
	// 		// margin-right 30px
	// 		margin-right 9px
		
	// 	@media $smPlus
	// 		padding-top 8px
	// 		padding-bottom 8px
	
	&_userPhoto
		margin-right auto
	
	&_dropdownMenu
		@media $smPlus
			margin-right 39px
	
	&_resetBtn
		position relative
		margin-right 19px
		border none
		outline none
		background transparent
		font-size 14px
		color $blue2
		
		@media $xs
			// margin-right 30px
			margin-right 9px
			padding 11px 24px
			border-radius 20px
			background #EBF0F2
	
	.AttachedImage
		position absolute
		bottom 70px
		left 15px
	
	&-expanded
		.CommentForm_actions
			display flex
	
	&-company
		.CommentForm_textArea
			margin-bottom 23px
			
			@media $xs
				margin-bottom 14px