.Soclinks
	&_title
		margin-bottom 7px
		color #404A66
		font-weight 500
		font-size 15px
		line-height 24px
		
	&_list
		display flex
		justify-content center
		align-items center
		padding-left 10px
		
		@media $xs
			flex-direction column
			padding-right 4px
			padding-left 0
	
	&_item
		margin-right 50px
		
		@media $xs
			margin-right 0
			margin-bottom 20px
			
			&:last-child
				margin-bottom 0
		
		&:last-child
			margin-right 0
	
	&-vacancies
		.Soclinks_list
			justify-content flex-start
			padding-left 0
			
			@media $xs
				align-items flex-start
		
		.Soclinks_item
			margin-right 6.6%
			
			@media $mdMinus
				margin-right 4.8%
			
			@media $xs
				margin-bottom 23px
				
				&:last-child
					margin-bottom 0
			
			&:last-child
				margin-right 0
	
	&-pricing
		.Soclinks_list
			padding-left 0
	
	&-contactsBlock
		.Soclinks_list
			// gap 13px 20px
			gap 8px 0px
			padding-left 0
			
			@media $smPlus
				display grid
				grid-template-columns repeat(auto-fit, minmax(160px, 1fr))
				justify-content start
		
		.Soclinks_item
			margin-right 0
	
	&-replenishment
		.Soclinks_list
			padding-left 0
			align-items start
		
		.Soclinks_item:not(:last-child)
			margin-right 43px