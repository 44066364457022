.Cover
	&_imgWrap
		margin-bottom 10px
		
		@media $xs
			margin-bottom 5px
	
	&_title
		font-weight 600
		font-size 15px
		
		@media $xs
			margin-bottom -4px
			font-size 16px
			line-height 1.625