.CompanySettings
	padding-left 4px
	
	&_remark
		margin-bottom 19px
		
		@media $xs
			margin-right 5px
			margin-bottom 32px
	
	&_title
		margin-bottom 36px
		padding-top 6px
		padding-bottom 7px
		font-weight 600
		font-size 16px
		border-bottom 1px solid #ebf0f2
	
	&_fields
		margin-bottom 30px
		padding-right 50px
		padding-bottom 30px
		border-bottom 1px solid #ebf0f2
		
		@media $smMinus
			margin-bottom 40px
			padding-right 0
			padding-bottom 0
			border-bottom none
		
		&-mainInformation
			padding-right 180px
			padding-bottom 17px
			
			@media $mdMinus
				padding-right 140px
			
			@media $xs
				margin-bottom 0
				padding-right 0
		
		&-writeBtn
			margin-bottom 27px
			padding-bottom 0
			border-bottom none
			
			@media $xs
				margin-bottom 30px
				padding-bottom 30px
				border-bottom 1px solid #ebf0f2
	
	&_field
		display flex
		align-items flex-start
		margin-bottom 20px
		
		@media $xs
			flex-direction column
			align-items stretch
			margin-bottom 27px
		
		&:last-child
			margin-bottom 0
		
		&-post
			margin-left -13px
			
			@media $xs
				margin-left 0
		
		&-ownerAddition
			margin-bottom 20px
		
		&-postAddition
			margin-top 30px
			margin-bottom 24px
		
		&-pageAddress
			position relative
			margin-bottom 8px
			
			@media $xs
				margin-bottom 12px
		
		&-uploadArea
			margin-top 29px
			
			@media $xs
				margin-top 41px
		
		&-rightIndent
			@media $smPlus
				padding-right 10px
		
		&-column
			flex-direction column
			align-items stretch
			
			.CompanySettings_label
				flex-basis auto
				margin-top 0
				margin-bottom 3px
				
				@media $xs
					margin-bottom 5px
	
	&_label
		margin-top 13px
		flex 170px 0 0
		font-weight 500
		color $blue2
		
		@media $xs
			margin-top 0
			margin-bottom 5px
			flex auto 0 0
		
		&-enterpriseAddition
			@media $smPlus
				font-size 12px
	
	&_fieldContent
		flex auto 1 1
		
		&-postAddition
			padding-left 171px

			@media $xs
				padding-left 0
	
	&_fieldItems
		margin-bottom 30px
		
		@media $xs
			margin-bottom 31px
		
		&-empty
			margin-bottom 13px
		
		&:last-child
			margin-bottom 0
	
	&_fieldItem
		display flex
		position relative
		margin-top 20px
		
		@media $xs
			flex-direction column
		
		&:first-child
			margin-top 0
		
		&-additional
			@media $xs
				padding-right 23px
		
		&-verticalCenter
			align-items center
			
			@media $xs
				align-items stretch
	
	&_fieldGroup
		display flex
		
		@media $xs
			flex-direction column
		
		.CompanySettings_field
			flex calc(33.33% - 32px) 1 1
			margin-right 16px
			
			@media $xs
				flex-basis 100%
				margin-right 0
			
			&:last-child
				margin-right 0
		
		.CompanySettings_dropdownSelect
			max-width 100%
			flex-basis 100%
	
	&_remark
		&-explanation
			margin-top -15px
			margin-bottom 42px
			margin-right -52px
			
			&:last-child
				margin-bottom 0
	
	&_fieldError
		margin-top 2px
		margin-bottom 10px
		
		&:last-child
			margin-bottom 0
	
	&_dropdownSelect,
	&_timeRangeDropdown
		flex 166px 0 1
		max-width 166px
		margin-right 15px
		
		@media $xs
			flex auto 0 0
			max-width none
			margin-right 0
			margin-bottom 25px
		
		&:last-child,
		&-noMargin
			margin-right 0

			@media $xs
				margin-bottom 0
	
	&_formText,
	&_textarea,
	&_dropdownSelect
		&-fullWidth
			width 100%
	
	&_dropdownSelect
		&-fullWidth
			max-width none
		
		&-short
			width 33.33%
			max-width calc(33.33% - 23px)
			flex-shrink 0
			
			@media $xs
				width auto
				max-width 100%
	
	&_formText
		flex 166px 1 1
		height 44px
		padding-right 12px
		padding-left 12px
		border 1px solid #EBF0F2
		border-radius 3px
		outline none
		font-size 15px
		color $black
		
		+placeholder()
			color $blue3
		
		@media $xs
			flex auto 1 1
			font-size 16px
		
		&:last-child
			margin-right 0
		
		&-gutter
			margin-right 16px
			
			@media $xs
				margin-right 0
				margin-bottom 25px
		
		&-col3
			width 33.33%
			max-width calc(33.33% - 11px)
		
		&-col4
			width 25%
			max-width calc(25% - 12px)
		
		&-col3, &-col4
			@media $xs
				width auto
				max-width none
		
		&-rightIndent
			margin-right 5px
			
			@media $xs
				width calc(100% - 22px)
	
	&_formText,
	&_dropdownSelect
		&-contactBtn
			flex 200px 0 1
			max-width 200px
			
			@media $xs
				flex-basis auto
				max-width 100%
	
	&_dropdownSelect
		&-contactBtn
			@media $smPlus
				margin-right 18px
	
	&_error
		position absolute
		top 12px
		right -146px
		width 133px
		
		@media $xs
			top 72px
			right auto
			left 0
	
	&_timeRangeDropdown
		&-workingHours
			margin-right 15px
			
			@media $xs
				margin-right 0
	
	&_pageAddress
		width 100%
	
	&_createAddress
		@media $xs
			margin-right -15px
			margin-bottom 20px
			margin-left -18px
	
	&_checkboxWrapper
		display flex
		align-items center
		margin-right 16px
		
		&:empty
			display none
	
	&_removeFieldItem,
	&_removePost,
	&_removeOwner
		width 30px
		height 30px
		padding 10px
		background url('../img/icon_close.svg') center center / 10px auto no-repeat
	
	&_removeFieldItem
		position absolute
		top 7px
		right -29px
		
		@media $smMinus
			top 17px
			right -16px
		
		@media $xs
			top 7px
			right -10px
		
		&-writeBtn,
		&-callBtn,
		&-emailBtn
			@media $smPlus
				position static
		
		&-writeBtn
			margin-top 3px
	
	&_actions
		display flex
		align-items center
		padding-left 170px
		
		@media $smMinus
			padding-left 0
		
		@media $xs
			justify-content center
	
	&_successfulSave
		margin-left 29px
	
	&_link
		color $blue
		
		&-cancel,
		&-fullNameEdit,
		&-ownerFullNameEdit
			font-weight 500
		
		&-fullNameEdit,
		&-ownerFullNameEdit
			font-size 16px
			line-height 1.5625
	
	&_post
		margin-bottom 24px
	
	&_postError
		margin-top -20px
		margin-bottom 24px
	
	&_ownerError
		margin-top 4px
	
	&_postHeader,
	&_ownerHeader
		display flex
		justify-content space-between
		align-items center
		margin-bottom 4px
	
	&_jobTitle,
	&_ownerFullName
		font-weight 500
		font-size 14px
		color $blue2
		white-space nowrap
		overflow hidden
		text-overflow ellipsis
	
	&_group
		margin-bottom 27px
		
		@media $xs
			margin-bottom 16px
	
	&_groupTitle
		margin-bottom 1px
		font-weight 600
		font-size 18px
	
	&_groupDescription
		margin-bottom 12px
		font-size 15px
		line-height 1.734
		
		@media $xs
			margin-bottom 18px
			line-height 1.6
	
	&_covers
		display flex
		
		@media $xs
			display block
	
	&_cover
		&-desktop
			margin-right 38px
			
			@media $xs
				margin-right 0
				margin-bottom 16px
	
	&_tabs
		&-languages
			margin-top -5px
			
			@media $xs
				margin-top -14px
	
	&_removePost,
	&_removeOwner
		margin-right -10px
	
	&_notice
		margin-top -10px
		margin-bottom 20px
	
	&_submitBtn
		margin-right 30px
		
		@media $xxs
			margin-right 0
			margin-bottom 37px
		
	&_resetBtn
		border none
		background none
		outline none
		color $blue
		font-weight 500
		
		@media $xs
			margin-right 3px
	
	&_checkboxList
		position relative
		max-height 205px
		margin-bottom 30px
	
	&_profileCover
		margin-top 20px
	
	&_uploadArea
		margin-bottom 31px
	
	&_uploadedImages
		@media $xs
			margin-right -20px
			margin-bottom 30px
	
	&-addingCompany
		.CompanySettings_fields
			margin-bottom 40px
			padding-right 0
			padding-bottom 0
			border-bottom none
			
			@media $xs
				margin-bottom 45px
			
		.CompanySettings_field
			position relative
			flex-direction column
			align-items stretch
			margin-bottom 21px
			
			@media $xs
				margin-bottom 27px
			
			&:last-child
				margin-bottom 0
		
		.CompanySettings_label
			flex auto 1 1
			margin-top 0
			margin-bottom 5px
		
		.CompanySettings_formText
			@media $xs
				font-weight 500
				font-size 16px
		
		.CompanySettings_autoComplete
			position absolute
			z-index 10
			top calc(100% + 1px)
			right 0
			left 0
		
		.CompanySettings_actions
			padding-left 0
		
		.CompanySettings_addButton
			margin-right 28px
			
			@media $xs
				margin-right 0
				flex-grow 1
	
	&-addingCompanyDefault
		.CompanySettings_actions
			justify-content center
			padding-top 32px
			padding-right 14px
	
	&-fieldAddition
		padding-left 0
		
		.CompanySettings_fields
			margin-bottom 46px
		
		.CompanySettings_actions
			flex-direction column
		
		.CompanySettings_addButton 
			width 100%
			margin-bottom 36px
	
	&-profileCover
		.CompanySettings_actions
			padding-left 0
			
			@media $xxs
				flex-direction column
	
	&-contactBtns
		padding-right 32px
		padding-left 3px
		
		@media $smMinus
			padding-right 0
		
		.CompanySettings_title
			margin-bottom 30px
			
			@media $xs
				padding 0
				border-bottom none
				font-weight 500
				font-size 18px
		
		.CompanySettings_actions
			padding-left 0
	
	&-addVacancy,
	&-addService
		@media $xs
			padding-top 16px
			
		.CompanySettings_title
			margin-bottom 25px
		
		.CompanySettings_fields
			margin-bottom 40px
			padding-right 30px
			padding-bottom 0
			border-bottom none
			
			@media $xs
				padding-right 4px
			
			&-allLanguages
				margin-top 22px
				margin-bottom 32px
				
				@media $xs
					margin-top -3px
					margin-bottom 40px
		
		.CompanySettings_label
			@media $smPlus
				flex-basis 174px
		
		.CompanySettings_dropdownSelect
			@media $xs
				margin-bottom 20px
				
				&:last-child
					margin-bottom 0
		
		.CompanySettings_actions
			padding-left 0
	
	&-enterpriseAddition
		@media $xs
			padding-top 20px
			padding-bottom 24px
			
		.CompanySettings_title
			margin-bottom 38px
		
		.CompanySettings_fields
			margin-bottom 72px
			padding-bottom 0
			padding-right 29px
			border-bottom none
			
			@media $smMinus
				margin-bottom 30px
				padding-right 5px
		
		.CompanySettings_field
			margin-bottom 36px
			
			@media $xs
				margin-bottom 26px
		
		.CompanySettings_actions
			padding-right 6px
			padding-left 0
	
	&-findJobMobilePopup
		.CompanySettings_fields
			@media $smMinus
				margin-bottom 20px