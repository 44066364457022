.UploadLink
	display inline-flex
	align-items center
	color $blue
	font-weight 500
	font-size 15px
	
	&::before
		content ""
		flex-shrink 0
		width 20px
		height 10px
		margin-right 12px
		background url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10'%3E%3Cstyle%3E%3C/style%3E%3Cg id='Link Default'%3E%3Cpath id='Фигура 14' d='M6 5c0 .55.45 1 1 1h6c.55 0 1-.45 1-1s-.45-1-1-1H7c-.55 0-1 .45-1 1zm2 3H5C3.34 8 2 6.66 2 5s1.34-3 3-3h3c.55 0 1-.45 1-1s-.45-1-1-1H5C2.24 0 0 2.24 0 5s2.24 5 5 5h3c.55 0 1-.45 1-1s-.45-1-1-1zm7-8h-3c-.55 0-1 .45-1 1s.45 1 1 1h3c1.66 0 3 1.34 3 3s-1.34 3-3 3h-3c-.55 0-1 .45-1 1s.45 1 1 1h3c2.76 0 5-2.24 5-5s-2.24-5-5-5z' fill='%234b97f9'/%3E%3C/g%3E%3C/svg%3E") 0 0 / cover no-repeat