.CompanyBrief
	display flex
	align-items flex-start
	
	&_logo
		flex 40px 0 0
		margin-right 14px
	
	&_logoImg
		border-radius 2px
	
	&_textWrapper
		line-height 20px
	
	&_name
		font-weight 500
		font-size 14px
	
	&_url
		font-size 13px
		color $blue2