.CategoryMenu
	&_menu
		display flex
		margin-left 2px
	
	&_item
		flex auto 0 0
		margin-right 46px
		font-weight 500
		font-size 13px
		
		&:last-child
			margin-right 0
	
	&_link
		display block
		position relative
		padding-top 7px
		padding-bottom 10px
		color $blue3
		@extend .transition
		
		&:after
			content ""
			position absolute
			z-index 1
			right 0
			bottom 0
			width 100%
			height 2px
			background transparent
			@extend .transition
		
		&.active, &:hover
			color $blue
			
			&:after
				background $blue
		
		&.active
			font-weight 600
	
	&_count
		@media $xs
			position relative
			top -5px
			color $blue
			font-size 11px
	
	&-company,
	&-vacancyFull
		.CategoryMenu_menu
			margin-left 0
		
		.CategoryMenu_item
			margin-right 45px
			
			&:last-child
				margin-right 0
	
	&-company,
	&-companySettings,
	&-companiesDossiers,
	&-vacancyFull
		overflow auto
		
		@media $xs
			&::-webkit-scrollbar
				width 0
				height 0
				background transparent
				
			.CategoryMenu_item
				margin-right 0
				font-size 14px
				text-transform uppercase
			
			.CategoryMenu_link
				padding 16px 17px
				
				&:after
					background $grey
				
				&:hover, &.active
					&:after
						background $blue
	
	&-company
		.CategoryMenu_item
			@media $xs
				font-size 13px
	
	&-save
		.CategoryMenu_menu
			@media $xs
				margin-left 0
				
		.CategoryMenu_item
			@media $xs
				margin-right 0
				font-size 14px
				line-height 1.7857
				text-transform uppercase
		
		.CategoryMenu_link
			@media $xs
				padding-top 14px
				padding-right 14px
				padding-bottom 14px
				padding-left 20px
	
	&-vacancyFull
		@media $xs
			.CategoryMenu_item
				font-weight 600
				// font-size 14px
				font-size 12px
			
			.CategoryMenu_link
				padding 12px 16px
	
	&-small
		@media $xs
			.CategoryMenu_item
				font-size 10px
			
			.CategoryMenu_link
				padding 10px 15px