.SelectWrapper
	&_label
		display block
		margin-bottom 4px
		margin-left 26px
		font-size 11px
		
		@media $xs
			margin-bottom 6px
			margin-left 0
			font-weight 500
			font-size 14px
			color $blue2
	
	&_select
		width 100%
	
	&-hidden
		display none