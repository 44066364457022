.InfoBlock
	font-family "Decima Nova Pro", sans-serif
	
	&_title
		margin-bottom 42px
		text-align center
		
		@media $xs
			margin-bottom 25px
		
		&-left
			text-align left
	
	&_content
		display flex
		align-items center
		
		a
			color #3A7CD4
			text-decoration underline
			
			&:hover
				text-decoration none
		
		@media $xs
			flex-direction column
		
	&_left
		flex-basis 535px
		padding-right 15px
		padding-left 15px
		text-align center
		
		@media $xs
			flex-basis auto
			margin-bottom 20px
	
	&_right
		flex-basis 602px
		
		@media $xs
			flex-basis auto
	
	&_text
		font-size 18px
		line-height 1.23
		
		@media $xs
			font-size 16px
		
		& > p
			margin-bottom 20px
			
			&:last-child
				margin-bottom 0
	
	&-inverse
		.InfoBlock_content
			align-items flex-start
			
			@media $xs
				flex-direction column-reverse
		
		.InfoBlock_left
			flex 0 1 auto
			padding-left 0
			text-align left
			
			@media $xs
				margin-bottom 0
				padding-right 0
		
		.InfoBlock_text
			font-size 19px
			line-height normal
			
			@media $xs
				font-size 16px
				line-height 1.23
		
		.InfoBlock_title
			margin-bottom 44px
			
			@media $xs
				margin-bottom 24px
				text-align center
		
		.InfoBlock_right
			padding-top 35px
			flex 0 0 auto
			
			@media $xs
				padding-top 0
		
		.InfoBlock_img
			@media $xs
				display block
				margin 0 auto 20px