.ReviewNote
	border-radius 4px
	
	&_header
		display flex
		justify-content space-between
		align-items center
		padding 13px 31px 14px 29px
		background #EBF0F2
		
		@media $xs
			padding-right 20px
			padding-left 20px
	
	&_title
		font-weight 600
		font-size 15px
		line-height 1.534
		color $blue2
		
		@media $xs
			font-size 16px
			line-height 1.4375
			letter-spacing 0.03em
	
	&_body
		padding 18px 28px 25px
		background $lightGrey
		
		@media $xs
			padding-right 20px
			padding-left 20px
	
	&_text
		margin-bottom 13px
		line-height 1.643
		
		@media $xs
			margin-bottom 19px
			font-size 14px
		
		&:last-child
			margin-bottom 0
	
	&_links
		display flex
		align-items center
		
		@media $xs
			flex-direction column
			align-items stretch
			padding-right 4px
	
	&_link
		font-weight 500
		color $blue
		text-decoration underline
		
		&:hover
			text-decoration none
		
		&-provideEvidence,
		&-verification
			position relative
			
			@media $xs
				padding 17px 13px 15px 47px
				border 1px solid #dfe4e5
				font-size 16px
				text-decoration none
				background-position 16px 17px
				background-repeat no-repeat
		
		&-provideEvidence
			margin-right 30px
			
			@media $xs
				margin-right 0
				margin-bottom 16px
				border-radius 4px
				background-image url('../img/review_note/doc.svg')
		
		&-verification
			@media $xs
				background-image url('../img/review_note/ok.svg')
		
		&-more
			@media $xs
				margin-left 5px
	
	&-pink
		.ReviewNote_header
			background #FFE9DE
		
		.ReviewNote_body
			background #FEF5ED
		
		.ReviewNote_title,
		.ReviewNote_link
			color $orange