.BtnLightBlue
	display inline-grid
	justify-content center
	padding 10px 20px
	border-radius 20px
	background #F5F7FA
	color $blue
	font-weight 500
	font-size 14px
	white-space nowrap
	
	&-fullWidth
		width 100%
	
	&-contactLinksPopup
		font-size 15px