.CompanyPhoto
	width 290px
	padding 16px 16px 30px
	border-radius 3px
	box-shadow 0 0 7px 0 rgba(0, 0, 0, 0.1)
	background $white
	text-align center
	
	&_img
		margin-bottom 19px
	
	&_rating
		margin-bottom 22px