.BenefitsList
	&_item
		margin-bottom 13px
		padding-left 26px
		background url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15.736' height='11.244' viewBox='0 0 15.736 11.244'%3E%3Cpath data-name='Фигура 2 копия 25' d='M15.375.326A1.134 1.134 0 0013.792.32l-.006.006-8.342 8.206-3.5-3.454a1.159 1.159 0 00-1.618.027 1.11 1.11 0 00.028 1.589l4.3 4.224a1.134 1.134 0 001.583.006l.007-.006 9.137-8.976a1.086 1.086 0 00.064-1.554c-.026-.021-.048-.043-.07-.062z' fill='%234b97f9'/%3E%3C/svg%3E") 0 4px no-repeat
		font-size 15px
		line-height 1.4
	
	&_hint
		top 3px
		margin-right 18px
		
		&:last-child
			margin-right 0
	
	&_note
		margin-top 3px
		color $blue2
		font-size 13px
		line-height 1.384615