.CircleIcon
	display flex
	justify-content center
	align-items center
	width 34px
	height 34px
	border-radius 50%
	background $blue
	color $white
	font-weight bold
	font-style italic
	font-size 20px
	font-family serif
	
	&-orange
		background $orange
	
	&-profileSettings
		width 28px
		height 28px
		
		@media $xs
			width 32px
			height 32px