.JobCharacteristic
	display flex
	align-items center
	color #404040
	font-size 14px
	line-height 20px
	
	&::before
		content ""
		margin-right 6px
		width 14px
	
	&-sex
		&::before
			height 13px
			background url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='12' height='13'%3E%3Cimage data-name='Фигура 21' width='12' height='13' opacity='.702' xlink:href='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAANCAYAAACdKY9CAAAABHNCSVQICAgIfAhkiAAAAO9JREFUKFOF0jEoBkAYxvHfVxRJFoNkMIkyKBOjySIpi2JkNLAYjRYGu0HIogw2ZWVRFkU2JRkskhCDXt3VdX1xdcO9Pf973veea0wvrStWB7Ywn2r7WMFb1jQq4BBfWE6CbbRirhnQg2v04SMJ2vCAYTxFrXQYxQ5Gyh5xhUVc1kAX7jGYb0O43qAfLzUQ53iBGawllw0cp/pvqR66F0cYS8AFZvHYbOgFbOIEd0kwgCmsYq90GE83T+C2GnoIZ8npPLd0il0cVOJ8jCBjT2bgHd1lohUYP+AZ7QFEkvGcEdJf6zNCzQ4t6PwHeMX3D/OpOMKSk1gCAAAAAElFTkSuQmCC'/%3E%3C/svg%3E") 0 0 no-repeat
			
	&-employment
		&::before
			height 11px
			background url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11.995' height='11.023'%3E%3Cpath data-name='Фигура 23' d='M11.148 1.972H9.812V.628a.618.618 0 0 0-.605-.629H2.789a.618.618 0 0 0-.605.629v1.344H.847a.865.865 0 0 0-.847.88v7.291a.865.865 0 0 0 .847.88h10.3a.865.865 0 0 0 .847-.88V2.852a.865.865 0 0 0-.846-.88ZM2.91.754h6.176v1.218H2.91ZM.848 2.726h10.3a.125.125 0 0 1 .121.126v2.262H7.6v-.338a.618.618 0 0 0-.605-.629H5a.618.618 0 0 0-.605.629v.338H.726V2.851a.125.125 0 0 1 .121-.124Zm6.025 2.176V6.12h-1.75V4.902Zm4.276 5.366H.849a.125.125 0 0 1-.121-.126V5.869H4.4v.377a.618.618 0 0 0 .605.629h1.991a.618.618 0 0 0 .605-.629v-.377h3.671v4.273a.125.125 0 0 1-.124.127Z' fill='%23647c97'/%3E%3C/svg%3E") 0 0 no-repeat
			
	&-housing
		&::before
			height 13px
			background url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='14' height='13'%3E%3Cimage data-name='Фигура 22' width='14' height='13' opacity='.702' xlink:href='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAANCAYAAACZ3F9/AAAABHNCSVQICAgIfAhkiAAAAP9JREFUKFN90jsrR2EcB/DPHyUKu+HvDZBNuY6MyiWTy8LAbDHZTFaDwXVRLi/ApEQGi+QFEG9AIhnoV8+pp9P5O8u59Hx+3+d8z6lNrmyqOAZxil/M4q68plYBh3CBJdSwjync5rgMh3GOBVymhRM4wHSOcxjoDIsZKkICH+bJBaxKKr/WOI5S8k3AJrw1SKrCe6gHbMYrdrGN96qa0Yl1LKO72Go/rhDnF3RhJg2IzxLD6njAGB7zcp4xmuAWRhK8xkaCcd0TzxvBnZiaYB9W/4NPqaB7VMEBRDG95cT5BH7Qhrn055zgCy1Yw3EZxn0r2hH4I221I6FPfBeN/wFO7UbDLE+ScAAAAABJRU5ErkJggg=='/%3E%3C/svg%3E") 0 0 no-repeat
			
	&-passport
		&::before
			height 13px
			background url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12.001' height='10'%3E%3Cpath data-name='Фигура 24' d='M.42 10A.434.434 0 0 1 0 9.554V.447A.434.434 0 0 1 .42.001h11.16a.434.434 0 0 1 .42.446v9.107a.434.434 0 0 1-.42.446Zm.42-.893h10.32V.893H.84Zm1.247-1.31a.434.434 0 0 1-.42-.446v-.417a1.992 1.992 0 1 1 3.976 0v.417a.434.434 0 0 1-.419.446Zm.42-.893h2.3a1.15 1.15 0 1 0-2.3 0Zm4.522-.268a.447.447 0 0 1 0-.893h2.619a.447.447 0 0 1 0 .893Zm-4.593-3.14a1.221 1.221 0 1 1 1.219 1.3A1.26 1.26 0 0 1 2.436 3.5Zm.841 0a.379.379 0 1 0 .378-.4.391.391 0 0 0-.378.404Zm3.752.76a.448.448 0 0 1 0-.894h2.619a.448.448 0 0 1 0 .894Z' fill='%23647c97'/%3E%3C/svg%3E") 0 0 no-repeat