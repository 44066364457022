.Employers
	@media $smMinus
		// padding 5px 12px 25px
		padding 5px 5px 25px
		
	&_wrapper
		margin-bottom 15px
		
		@media $smPlus
			margin-bottom 40px
		
		@media $md
			display grid
			grid-template-columns repeat(2, 1fr)
			// align-items center
			align-items end
			gap 37px
			margin-bottom 64px
	
	&_title
		font-weight bold
		font-size 28px
		line-height 1.214285em
		color #404A66
		text-transform uppercase
		
		@media $xs
			margin-right -12px
			margin-left -12px
		
		@media $smMinus
			margin-bottom 20px
			text-align center
		
		@media $smPlus
			font-size 38px
			line-height normal
		
		@media $md
			align-self end
			margin-top 30px
			font-size 48px
	
	&_imgWrapper
		text-align center
		
		@media $smMinus
			margin-bottom 22px
		
		@media $md
			display grid
			// justify-content end
			justify-content center
			grid-row 1 / 3
			grid-column 2 / 3
		
	&_img
		@media $xs
			width 280px
	
	&_text
		font-size 16px
		line-height 1.5
		color #404A66
		
		@media $smPlus
			padding-left 2px
			line-height 1.625
		
		@media $md
			align-self start
		
		& > p
			margin-bottom 10px
			
			&:last-child
				margin-bottom 0
	
	&_contactsBlock
		@media $xs
			margin-right -20px
			margin-left -20px