.Activities
	font-size 15px
	line-height 1.6
		
	@media $xs
		font-size 16px
		line-height 1.5625
	
	&_name, &_desc
		margin-bottom 12px
		
		@media $xs
			margin-bottom 9px
	
	&_name
		font-weight 500
		
		@media $xs
			color $blue2
	
	&_list, &_listItem
		margin-bottom 9px
		
		&:last-child
			margin-bottom 0