.ClearBtn
	display inline-flex
	align-items center
	padding 2px 14px 5px 14px
	border-radius 4px
	background #F5F7FA
	color $blue2
	
	@media $xs
		background transparent
	
	&_text
		margin-right 4px
		font-size 13px
		
		@media $xs
			font-weight 500
			font-size 15px
	
	& > .SvgIco
		width 7px
		margin-top 2px
		
		@media $xs
			margin-top 3px