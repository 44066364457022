.QuestionsBlock	
	&_categoryMenu
		padding-right 23px
		padding-left 30px
		border-bottom 1px solid $grey
	
	&_item
		border-bottom 1px solid $grey
		
		&:last-child
			border-bottom none
	
	&_bottom
		padding 8px 30px 30px