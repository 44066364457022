.Aside
	.UserMenu
		margin-top 7px
		margin-bottom 30px
	
	.TopicCreationBlock
		margin-bottom 30px
	
	.Application
		margin-bottom 36px
		
	&_block
		margin-bottom 30px
	
	&_companyLink
		margin-bottom 22px
	
	&_ratingSection
		margin-top 42px
		margin-bottom 35px
	
	&_offerBlock
		margin-bottom 24px
	
	&-vacancies
		display flex
		flex-direction column
		align-items flex-end