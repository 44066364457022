.ImgWrapper
	display flex
	align-items center
	
	@media $xs
		flex-direction column
	
	&_img
		margin-right 8px
		
		@media $xs
			margin-right 0
			margin-left -40px
	
	&_text
		font-weight 600
		font-size 14px
		line-height 1.57142