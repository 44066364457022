.ExtendedRatings		
	&_item
		margin-bottom 14px
		
		&:last-child
			margin-bottom 0
	
	&-short
		.ExtendedRatings_item
			margin-bottom 11px
			
			&:last-child
				margin-bottom 0