.UserInfo
	display flex
	align-items center
	
	&_photo
		flex-shrink 0
		margin-right 10px
	
	&_nameWrapper
		display flex
		margin-bottom -3px
	
	&_name
		margin-right 5px
		font-weight 600
		font-size 15px
		white-space nowrap
		
		@media $xs
			font-size 16px
	
	&_status
		font-size 13px
		color $blue3
		
		@media $xs
			margin-left 2px
	
	&_verification
		width 14px
		color $blue
		
		@media $smPlus
			margin-top 2px
			margin-right 9px