.NewsBlock
	width 263px
	max-width 100%
	
	@media $xs
		width 320px
		padding-bottom 25px
		border-bottom 1px solid $grey
	
	&_imgWrap
		margin-bottom 22px
	
	&_img
		width 100%
	
	&_title
		margin-bottom 11px
		font-weight 600
		font-size 18px
		line-height 1.45
		
		@media $xs
			font-size 21px
			line-height 1.238
	
	&_titleLink
		&:hover
			text-decoration underline
	
	&_text
		margin-bottom 13px
		font-size 14px
		line-height 1.5714
		
		@media $xs
			font-size 15px
			line-height 1.6
	
	&_country
		padding-left 24px
		background url('../img/poland_flag.png') 0 center no-repeat
		color #999
		font-size 10px
	
	&-twoCol
		display flex
		width auto
		padding-bottom 13px
		border-bottom 1px solid $grey
		
		@media $xs
			display block
			width 320px
			padding-bottom 25px
		
		.NewsBlock_imgWrap
			flex-shrink 0
			padding-top 5px
		
		.NewsBlock_textContent
			flex-grow 1
			margin-left 30px
			
			@media $xs
				margin-left 0
		
		.NewsBlock_date
			margin-bottom 19px
		
		.NewsBlock_title
			margin-bottom 10px
			font-weight 500
			font-size 24px
			line-height 1.34
			
			@media $xs
				font-weight 600
				font-size 21px
				line-height 1.238
		
		.NewsBlock_text
			margin-bottom 21px
			font-size 16px
			line-height 1.625
			
			@media $xs
				font-size 15px
				line-height 1.6
	
	&-saved
		@media $smPlus
			width 345px
	
	&-sidebar
		display flex
		width auto
		padding-bottom 26px
		border-bottom 1px solid #ebf0f2
		
		@media $xs
			display block
			padding-bottom 0
			border-bottom none
		
		.NewsBlock_imgWrap
			flex-shrink 0
			margin-right 26px
			margin-bottom 0
			
			@media $xs
				margin-right 0
				margin-bottom 19px
		
		.NewsBlock_img
			display block
			
			@media $smPlus
				width 100px
		
		.NewsBlock_textContent
			margin-top -5px
		
		.NewsBlock_title
			margin-bottom 8px
			font-size 15px
			line-height 1.4
			
			@media $xs
				font-size 18px
				line-height 1.45
	
	&-mobilePopup
		display flex
		align-items flex-start
		padding-bottom 10px

		.NewsBlock_imgWrap
			flex 60px 0 0
			margin-right 18px

		.NewsBlock_img
			border-radius 6px
		
		.NewsBlock_title
			margin-top -4px
			margin-bottom 9px
			font-weight 500
			font-size 14px
			line-height 1.5714