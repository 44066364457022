.TopMenu
	&_menu
		display flex
		justify-content center
	
	&_item
		margin-right 25px
		margin-left 25px
		font-weight 600
		font-size 17px
		font-family $decimaNovaPro
	
	&_link
		display block
		padding 5px 14px
		border-bottom 2px solid transparent
		@extend .transition
		
		&:hover
			border-bottom-color #b2a05e