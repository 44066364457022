.Operation
	display flex
	justify-content space-between
	align-items flex-start
	padding 25px 0 25px 2px
	border-bottom 1px solid $grey
	font-weight 500
	font-size 15px
	line-height 19px
	
	&_left, &_right
		display flex
		align-items flex-start
	
	&_icon
		width 30px
		margin-top 3px
		margin-right 13px
	
	&_name
		line-height 20px
	
	&_time, &_balance
		color #999
		font-size 13px
	
	&_statusWrapper
		margin-right 57px
	
	&_status
		color $blue3
	
	&_id
		color #D0D7E0
		font-size 12px
	
	&_balanceWrapper
		flex 115px 0 1
		width 115px
		text-align right