.Footer
	background $lightGrey
	
	&_left
		padding-left 3px
	
	&_right
		display flex
		align-items center
		padding-right 3px
	
	&_container
		display flex
		justify-content space-between
		align-items center
		padding-top 15px
		padding-bottom 16px
	
	&_copyright
		font-size 12px
		line-height 1.83
		color #7D7F80
	
	&_languageSwitch
		margin-right -4px
		margin-left 32px