.Images
	&_header
		display flex
		-webkit-justify-content space-between
		justify-content space-between
		-webkit-align-items center
		align-items center
		margin-bottom 15px
		
	&_title
		font-weight 500
		font-size 14px
		
	&_list
		display flex
		flex-wrap wrap
	
	&_item
		margin-right 16px
		margin-bottom 16px
		
		@media $xs
			margin-right 5px
			margin-bottom 4px
			
			&:last-child
				margin-right 0
		
		&:nth-child(8n)
			margin-right 0
		
		&:nth-child(n+8):not(.Images_item-other)
			display none
		
		&-other
			margin-right 0
	
	&_link
		display block
		
		&-other
			display flex
			justify-content center
			align-items center
			width 75px
			height 75px
			background $lightGrey
	
	&_img
		display block
		width 76px
		height 76px
		object-fit cover
		
		@media $xs
			width 74px
			height 74px
	
	&_number
		color $blue
		font-weight 600
		font-size 16px
	
	&_hideLink
		position relative
		display none
		padding-right 8px
		font-weight 500
		color $blue
		
		&:after
			content ""
			position absolute
			top 6px
			right 0
			border 3px solid transparent
			border-bottom 3px solid $blue
	
	&.opened
		.Images_list
			margin-bottom -4px
			
			@media $xs
				margin-bottom 8px
			
		.Images_item
			margin-bottom 17px
			
			@media $xs
				margin-bottom 4px
			
			&:nth-child(n+8):not(.Images_item-other)
				display block
			
			&-other
				display none
		
		.Images_hideLink
			display inline-block
	
	&-comment
		.Images_item
			margin-bottom 10px
			margin-right 10px
			
			@media $xs
				margin-bottom 8px
				margin-right 8px
				
				&:nth-child(4n)
					margin-right 0
			
		.Images_img
			width 40px
			height 40px
			
			@media $xs
				width 74px
				height 74px
	
	&-job
		.Images_item
			margin-right 15px
			margin-bottom 15px
			
			@media $xs
				// margin-right 6px
				// margin-bottom 6px
				margin-right 5px
				margin-bottom 4px
			
			&:last-child
				margin-right 0
		
		.Images_img
			width 75px
			height 75px