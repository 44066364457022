.BtnBlue
	display inline-block
	max-width 100%
	padding 5px 13px 6px 14px
	border none
	outline none
	border-radius 15px
	background #4B69B4
	color $white
	font-weight 500
	white-space nowrap
	text-align center
	@extend .transition
	
	& > .SvgIco
		margin-top -2px
		margin-right 10px
	
	&-large
		display flex
		justify-content center
		align-items center
		width 100%
		padding-top 13px
		padding-bottom 10px
		border-radius 23px
		font-size 16px
		font-weight 600
	
	&-loginForm
		width 100%
		padding-top 20px
		padding-bottom 20px
		border-radius 0
		background #5181B8
		font-size 20px
	
	&-mobileMenu
		width 100%
		padding-top 11px
		padding-bottom 9px
		border-radius 0
		background #5181B8
		font-size 12px
	
	&-light
		background $blue
	
	&-topicCreation
		padding 8px 38px 8px 43px
	
	&-commentForm
		padding 7px 28px 9px 34px
		
		@media $xs
			// padding 12px 41px 11px 40px
			padding 10px 24px 12px 25px
			border-radius 24px
		
		&:disabled
			background $grey
			color #7D7C97
			pointer-events none
	
	&-noResults
		padding 10px 50px 10px 51px
		border-radius 18px
		
		@media $xs
			width 320px
			border-radius 24px
	
	&-company,
	&-contact,
	&-companyHeader
		padding 8px 25px
		background $blue
		
		@media $xs
			width 320px
			padding-top 11px
			padding-bottom 10px
			border-radius 18px
	
	&-contact
		width 210px
		border-radius 18px
		
		&::after
			content ""
			display inline-block
			width 4px
			height 3px
			margin-right -14px
			margin-left 4px
			background url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4.001' height='3.012' viewBox='0 0 4.001 3.012'%3E%3Cpath data-name='Многоугольник 1 копия 8' d='M2.004 3.011l-2-3.012h4z' fill='%23fff'/%3E%3C/svg%3E")
			vertical-align middle
	
	&-companyHeader
		@media $xs
			padding-top 8px
			padding-bottom 9px
	
	&-frontpage
		padding 10px 33px
		border-radius 26px
		background $blue
		font-weight normal
		font-size 14px
	
	&-companyArrow
		padding 8px 17px 8px 22px
		background $blue
		
		&:after
			content ""
			display inline-block
			width 5px
			height 8px
			margin-left 11px
			background url('../img/arrow_white.png') 0 0 no-repeat
			vertical-align middle
	
	&-createAddress
		padding 11px 23px
		border-radius 18px
		background $blue
		
		@media $xs
			width 320px
	
	&-disabled
		background #D0D7E0
		pointer-events none
		user-select none
	
	&-addCompany
		padding-right 28px
		padding-left 28px
	
	&-addCompanyPopup
		padding 11px 42px 11px 42px
		border-radius 20px
		background $blue
	
	&-companySettings
		width 320px
		padding-top 10px
		padding-bottom 12px
		border-radius 20px
		background $blue
	
	&-enterpriseAddition
		width auto
		padding 13px 32px
		border-radius 22px
		background $blue
		
		@media $xxs
			width 100%
			padding-top 11px
			padding-bottom 11px
	
	&-profileCover,
	&-addService
		padding 11px 28px 11px 26px
		border-radius 22px
		background $blue
		
		@media $xs
			width 320px
			padding-top 11px
			padding-bottom 10px
			border-radius 18px
	
	&-ban
		padding 10px 37px
		border-radius 18px
		background $blue
	
	&-mobilePopup,
	&-mobilePopupLogin,
	&-commentSwitchPopup
		background $blue
		border-radius 20px
	
	&-mobilePopup,
	&-commentSwitchPopup
		width 320px
		padding-top 12px
		padding-bottom 11px
		font-size 15px
	
	&-commentSwitchPopup
		padding-top 9px
		padding-bottom 10px
	
	&-mobilePopupLogin
		min-width 155px
		padding-top 11px
		padding-bottom 10px
		font-size 14px
	
	&-job,
	&-fullJob,
	&-addEntity
		padding 8px 19px 8px 25px
		border-radius 20px
		background $blue
		
		@media $xs
			width 320px
			padding-right 22px
			padding-left 22px
	
	&-fullJob
		padding-right 27px
		padding-left 27px
		
		@media $xs
			padding-right 25px
			padding-left 25px
	
	&-addEntity
		padding-right 31px
		padding-left 31px
	
	&-service
		padding 7px 42px
		background $blue
		
		@media $xs
			width 320px
			max-width 100%
	
	&-addTestimonial
		padding 11px 24px
		border-radius 18px
		background $blue
		
		@media $xs
			width 320px
			max-width 100%
	
	&-testimonial
		padding 10px 30px
		border-radius 18px
		background $blue
	
	&-ratingSection
		width 100%
	
	&-jobFilter
		// display block
		width 320px
		padding 11px 19px
		border-radius 20px
		// width 100%
		// padding-top 19px
		// padding-bottom 19px
		background $blue
		// border-radius 0
	
	&-banner
		width 152px
		padding-top 11px
		padding-bottom 11px
		border-radius 20px
		background $blue
		
		&:hover
			background darken($blue, 10)
	
	&-orderCallPopup
		min-width 180px
		padding 11px 33px
		background $blue
		border-radius 20px
	
	&-replenishment
		padding 8px 13px 7px 12px
		border-radius 19.5px
		font-size 16px
		line-height 1.5
		background $blue
		
		&:hover
			background darken($blue, 10)
	
	&-write
		display inline-grid
		grid-template-columns 14px auto
		justify-content center
		align-items center
		gap 6px
		padding-top 9px
		padding-bottom 8px
		background $blue
		border-radius 18px
		
		&::before
			content ""
			width 14px
			height 14px
			background url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14.002' height='14'%3E%3Cpath data-name='Фигура 897' d='M7.002 0a6.832 6.832 0 0 0-7 6.631 6.349 6.349 0 0 0 1.113 3.587A5.921 5.921 0 0 1 .071 13.58a.255.255 0 0 0-.044.283.232.232 0 0 0 .209.136h.033a12.016 12.016 0 0 0 3.878-1.311 7.267 7.267 0 0 0 2.857.577A6.641 6.641 0 1 0 7.002 0ZM3.735 7.614a.984.984 0 1 1 .934-.983.96.96 0 0 1-.935.983Zm3.267 0a.984.984 0 1 1 .933-.983.96.96 0 0 1-.933.983Zm3.267 0a.984.984 0 1 1 .933-.983.96.96 0 0 1-.934.983Z' fill='%23fff'/%3E%3C/svg%3E") 0 0 no-repeat
	
	&-findJob
		padding 15px 16px
		background $blue
		border-radius 16px
		box-shadow 0 8px 16px rgba(100, 124, 151, 0.12)
	
	&-offerBlock
		padding 8px 32px
		border-radius 17.5px
		background $blue
		
		&:hover
			background darken($blue, 10)
	
	&-invisible
		opacity 0
		visibility hidden