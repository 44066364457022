.RangeSlider
  position relative
  height 3px
  border-radius 1px
  background #ebf0f2

  &_range
    position absolute
    height 100%
    background-color $blue

  &_handle
    position absolute
    top -6px
    width 15px
    height 15px
    margin-left -7px
    border-radius 50%
    background-color $blue
    outline none
    cursor pointer

    @media $xs
      top -10px
      width 24px
      height 24px