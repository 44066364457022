.Messages	
	&_title
		margin-bottom 6px
		font-weight 600
		text-indent 3px
		
		@media $xs
			margin-right 20px
			margin-bottom 11px
			margin-left 20px
			padding-bottom 8px
			font-size 15px
			text-indent 0
			border-bottom 1px solid $grey
	
	&_items
		border 1px solid #ebf0f2
		border-radius 3px
		
		@media $xs
			border none