.ContextMenu
	position relative
	z-index 10
	
	&_menu
		display none
		position absolute
		top calc(100% + 11px)
		right 0
		left 50%
		transform translateX(-50%)
		width 184px
		padding 13px 0 15px
		border-radius 2px
		box-shadow 0 1px 5px 0 rgba(0, 0, 0, 0.12)
		background $white
		text-align left
		
		&:before
			content ""
			position absolute
			top -10px
			right 0
			left 0
			width 16px
			height 10px
			margin auto
			background url('../img/corner_shadow.jpg') 0 0 no-repeat
		
		&-opened
			display block
	
	&_link
		display flex
		align-items center
		padding 5px 27px
		color #404040
		font-weight 500
		font-size 13px
		line-height 1.8461
		@extend .transition
		
		& > svg
			margin-right 10px
			color $blue3
		
		&:hover
			background #f5f5f5
		
		&-phone
			color $blue
	
	&-large
		.ContextMenu_menu
			width 216px
	
	&-contacts
		.ContextMenu_menu
			padding-top 10px
			padding-bottom 10px
			
		.ContextMenu_link
			// padding 3px 14px 3px 22px
			// padding 3px 4px 3px 23px
			padding 3px 4px 3px 17px
			// font-size 15px
			font-size 14px
			
			& > svg
				width 16px
				margin-right 12px