.EmployerBlocks
	&_label
		margin-bottom 7px
		color $blue2
		font-size 13px
		line-height 1.84615
	
	&_slide
		width auto
	
	&_items
		@media $smPlus
			display grid
			grid-template-columns repeat(auto-fit, 280px)
			gap 6px