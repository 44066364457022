.DotsMenu
	position relative
	
	&_wrapper
		display none
		z-index 10
		position absolute
		top calc(100% + 3px)
		left -9px
		width 176px
		padding 13px 0
		border-radius 4px
		box-shadow 0 0 5px 0 rgba(0, 0, 0, .1)
		background $white
		@extend .transition
		
		&:before
			content ""
			position absolute
			top -10px
			left 8px
			width 16px
			height 10px
			background url('../img/corner_shadow.jpg') 0 0 no-repeat
			
			@media $smMinus
				left auto
				right 8px
		
		&-complaint
			width 162px
		
		@media $smMinus
			width 189px
			top calc(100% + 6px)
			right -11px
			left auto
	
	&_link
		display flex
		align-items flex-start
		padding 5px 36px 5px 19px
		font-weight 500
		line-height normal
		color #0D0D0D
		@extend .transition
		
		@media $smMinus
			padding-top 9px
			padding-bottom 8px
			font-size 16px
		
		& > .SvgIco
			flex-shrink 0
			width 13px
			margin-top 3px
			margin-right 10px
			color $blue2
		
		&:hover
			background #f5f5f5
		
		&-orange
			color $orange
	
	&-commentForm
		position absolute
	
	&-comment
		.DotsMenu_wrapper
			width 200px
			right 0
			left auto
			
			&:before
				right 0
				left auto
			
			@media $smMinus
				width 230px
		
		.DotsMenu_link
			padding-right 20px
	
	&-commentRejected
		.DotsMenu_wrapper
			&:before
				background none
	
	&-dialogue
		.DotsMenu_icon
			width 16px
			opacity 1
		
		.DotsMenu_wrapper
			left auto
			right 0
			width 225px
			
			&:before
				right 0
				left auto
		
		.DotsMenu_link
			padding-right 12px
			padding-left 15px
	
	&-share
		.DotsMenu_wrapper
			@media $smMinus
				width auto
				padding-top 5px
				padding-bottom 5px
				
		.DotsMenu_link
			@media $smMinus
				padding-right 25px
				font-size 15px
			
			& > .SvgIco
				color #0D0D0D
	
	&-job
		.DotsMenu_wrapper
			right 0
			left auto
			width 200px
			
			&:before
				right 0
				left auto
		
		.DotsMenu_link
			padding-right 20px
	
	&-entityStatus
		.DotsMenu_wrapper
			left 50%
			width 170px
			margin auto
			transform translateX(-50%)
			border-radius 3px
			
			@media $xs
				top calc(100% + 19px)
				right 0
				left auto
				width 190px
				transform none
			
			&:before
				right 0
				left 0
				margin auto
				
				@media $xs
					right 0
					left auto
					margin 0
		
		.DotsMenu_link
			padding 7px 19px 6px
	
	&-entityStatusHidden
		.DotsMenu_wrapper
			top calc(100% + 19px)