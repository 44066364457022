.CompanyLink
	position relative
	display inline-flex
	align-items flex-start
	width 260px
	font-weight 500
	
	&:before
		content ""
		position absolute
		top -10px
		right -10px
		bottom -10px
		left -10px
		border-radius 2px
		background transparent
		@extend .transition
	
	&:hover
		&:before
			background #FAFAFA
	
	&_img, &_textWrap
		position relative
	
	&_img
		width 40px
		margin-right 15px
		border-radius 3px
	
	&_text
		color $blue2
		font-size 13px
	
	&-vacancies
		display flex
		width 320px
		max-width 100%
		 
		.CompanyLink_img
			width 60px
			margin-right 17px
		
		.CompanyLink_textWrap
			margin-top 4px
		
		.CompanyLink_title
			font-weight 600
			font-size 18px
			line-height 1.45
		
		.CompanyLink_text
			font-weight 500
			font-size 15px
			color $blue
		
		&:before
			display none