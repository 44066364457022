.ToggleLink
	position relative
	display inline-block
	padding-right 9px
	font-weight 500
	color $blue
	
	&:after
		content ""
		position absolute
		top 10px
		right 0
		border 3px solid transparent
		border-top 3px solid $blue
	
	&-company
		font-size 14px
		text-transform lowercase
		
		@media $xs
			font-size 15px