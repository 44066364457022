.DropdownSelect
	.fs-wrap
		width 102px
		line-height normal
		
	.fs-label-wrap
		padding-right 4px
		
		&:after
			content ""
			position absolute
			top 14px
			right 0
			border 3px solid transparent
			border-top 3px solid $blue
		
		.fs-label
			padding 5px 5px 5px 10px
			color $blue
			text-align right
		
		.fs-arrow
			display none
	
	.fs-dropdown
		width 100%
		margin-top 6px
		
		&:before
			content ""
			position absolute
			top -10px
			right 10px
			left auto
			width 16px
			height 10px
			background url('../img/corner_shadow.jpg') 0 0 no-repeat
		
		.fs-options
			padding-top 12px
			padding-bottom 11px
		
	.fs-option
		padding 6px 13px
		font-weight 500
		color $black
	
	&-commentsSorting
		.fs-wrap
			width 140px
			text-align right
		
		.fs-dropdown
			text-align left
			
			@media $xs
				right 0
				width 170px
			
			.fs-option
				@media $xs
					font-size 15px
	
	&-autoWidth
		.fs-wrap
			width auto
	
	&-notificationSettings				
		.fs-dropdown
			right 0
			min-width 115px
			
			@media $xs
				min-width 250px
	
	&-notifications
		.fs-wrap
			text-align right
		
		.fs-dropdown
			right 0
			min-width 120px
			text-align left
	
	&-mobile
		@media $xs				
			.fs-dropdown
				z-index 1001
				right auto
				left 0
				width 100%
				margin-top 0
				border none
				border-radius 4px
				
				.fs-options
					padding 16px 10px 13px
				
				&:before
					display none
			
			.fs-option
				padding 11px 10px
				font-size 16px
				color $blue3
	
	&-companyProfile
		max-height 18px
		
		.fs-wrap
			width auto
			
		.fs-label-wrap
			&:after
				top 8px
				right -5px
				
				@media $xs
					top 11px
				
			.fs-label
				padding 0
				font-weight 600
				font-size 14px
				
				@media $xs
					font-size 15px
		
		.fs-dropdown
			right 0
			width 214px
			
			@media $xs
				width 240px
			
			&:before
				right 0
				left 0
				margin auto
		
		.fs-option
			@media $xs
				font-size 14px
	
	&-companySettings,
	&-profileSettings,
	&-vacanciesFilter
		.fs-wrap
			display block
			width auto
			
			&.fs-open .fs-label:after
				transform rotate(180deg)
			
		.fs-label-wrap
			display block
			padding-right 0
			border 1px solid $grey
			border-radius 3px
			
			.fs-label
				display flex
				justify-content space-between
				align-items center
				padding 11px 12px 12px
				font-size 15px
				text-align left
				
				@media $xs
					font-size 16px
				
				&:after
					content ""
					width 9px
					height 6px
					margin-top 3px
					margin-left 3px
					background url('../img/arrow_down.svg') 0 0 / cover no-repeat
					@extend .transition
			
			&:after
				display none
		
		.fs-dropdown
			&:before
				right 0
				left 0
				margin auto
			
			.fs-options
				@media $xs
					max-height 255px
					padding-top 5px
					padding-bottom 5px
			
			.fs-option
				font-size 14px

				@media $xs
					padding 8px 12px
					font-size 16px
	
	&-vacanciesFilter
		.fs-search
			margin-right 20px
			margin-left 20px
			padding 0
			border-bottom-color #EBF0F2

			input
				padding-top 14px
				padding-bottom 12px
				padding-left 30px
				background url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15.999' height='16'%3E%3Cpath data-name='Фигура 12' d='M15.861 15.184l-3.889-3.888a6.832 6.832 0 10-.674.674l3.889 3.888a.481.481 0 00.337.142.467.467 0 00.337-.142.48.48 0 000-.674zM.955 6.822A5.865 5.865 0 116.82 12.69 5.871 5.871 0 01.954 6.823z' fill='%238396ac'/%3E%3C/svg%3E") 0 58% no-repeat

		.fs-label-wrap
			.fs-label
				padding-top 10px
				padding-bottom 9px
				
				@media $smPlus
					padding-top 8px
					padding-bottom 7px
	
	&-enterpriseAddition
		.fs-label-wrap .fs-label
			font-size 14px
	
	&-companyAdding
		.fs-dropdown .fs-options
			max-height 170px
			
			@media $xs
				max-height 255px
	
	&-profileSettings
		width 280px
		
		@media $xs
			width 100%
		
		.fs-wrap
			&.fs-open
				.fs-label:after
					margin-top -1px
				
		.fs-label-wrap
			.fs-label
				padding-top 8px
				padding-bottom 7px
				font-size 14px
				
				@media $xs
					padding 13px 13px 12px 10px
				
				&:after
					margin-top 1px
					
					@media $xs
						width 11px
						height 8px
						margin-top 0
		
		.fs-dropdown
			text-align left
	
	&-messenger
		display inline-block
		width 220px
		
		@media $xs
			width 155px
	
	&-operations
		.fs-label-wrap .fs-label
			padding 5px 5px 5px 0
			font-size 14px
		
		.fs-dropdown
			width 200px
			
			&:before
				right 0
				left 0
				margin auto
	
	&-vacancy
		.fs-label-wrap
			.fs-label
				padding 0 5px 0 0
			
			&:after
				top 8px
		
		.fs-dropdown
			width 155px
			
			&:before
				right auto
				left 8px
				margin auto
	
	&-plansTable
		display inline-block
		
		.fs-wrap
			width auto
		
		.fs-label-wrap .fs-label
			padding-left 5px
			font-size 16px
			
			@media $smPlus
				color #36404D
			
			@media $xs
				font-size 15px
			
			&::after
				content ""
				position absolute
				right 10px
				bottom 4px
				left 5px
				border-bottom 1px dashed $blue
		
		.fs-dropdown
			width 60px
			margin-top 4px
			
			&::before
				right 0
				left 0
				margin auto

.fs-options	
	.ps__rail-y
		margin-top 10px
		margin-bottom 10px

.DropdownSelect
	.fs-wrap.multiple
		.fs-dropdown
			margin-top 12px
			
			@media $xs
				width 100%
		
		.fs-options
			padding-top 8px
			padding-bottom 8px

			@media $xs
				padding-right 0
				padding-left 0
		
		.fs-checkbox i
			height 12px
			border none
			border-radius 0
			
		.fs-option
			padding 7px 13px 6px 34px
			color #545556
			font-size 14px

			@media $xs
				color $blue3
				font-size 16px
			
			&:hover
				background #F7F7F7
			
			.fs-checkbox i
				background transparent
			
			&.selected
				color $black

				@media $xs
					color $blue
				
				.fs-checkbox i
					left 8px
					background url('../img/dropdown_checkbox.svg') 0 0 no-repeat	
	
	&-vacanciesFilter
		.fs-label-wrap
			.fs-label
				align-items flex-start
				white-space normal
				font-size 14px
				line-height 1.71428em
				color $black

				&::after
					flex-shrink 0
					width 12px
					height 8px
					margin-top 8px
					background url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12.45' height='7.861'%3E%3Cpath data-name='Прямоугольник 5 копия 4' d='M11.729.693l-5.5 5.725L.729.693' fill='none' stroke='%23d9d9d9' stroke-width='2'/%3E%3C/svg%3E") 0 0 no-repeat

		.fs-wrap.multiple
			.fs-dropdown
				margin-top 6px
			
			.fs-option
				padding-bottom 7px
				padding-left 48px

				.fs-checkbox i
					left 20px
					width 16px
					height 16px
					border 1px solid #DFE2E5
					border-radius 2px

				&.selected
					.fs-checkbox i
						left 20px