.StatIcon
	display inline-flex
	align-items center
	
	& > .SvgIco
		width 19px
		color $blue2
		
		@media $xs
			width 22px
	
	&_link
		display inline-flex
		align-items center
		
		& > .SvgIco
			width 19px
			color $blue2
			
			@media $xs
				width 22px
	
	&_count
		margin-left 7px
		font-size 13px
		
		&-news
			@media $xs
				font-size 12px
	
	&-bordered
		padding-right 10px
		border-right 1px solid $grey
		
		@media $xs
			padding-right 11px
	
	&-answer
		.StatIcon_link > .SvgIco
			width 16px
			
			@media $xs
				width 18px
		
		.StatIcon_count
			margin-left 9px
	
	&-topicAnswer
		.StatIcon_link > .SvgIco
			width 11px
		
		.StatIcon_count
			margin-left 5px
	
	&-views
		& > .SvgIco
			width 16px
			margin-left 4px
	
	&-companyViews
		color #BCC3CC
		
		& > .SvgIco
			width 14px
			margin-left 4px
			color #BCC3CC
	
	&-articleViews
		& > .SvgIco
			width 16px
	
	&-fullArticleViews
		& > .SvgIco
			width 12px
			color #999