.AuthorizationBlock
	display flex
	justify-content flex-end
	width 250px
	max-width 100%
	
	&_list
		display flex
		align-items center
	
	&_item
		&:first-child
			flex-shrink 0
			margin-right 24px