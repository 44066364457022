.DossierSection
	background url('../img/bg_dossier_section2.jpg') center 0 / cover no-repeat
	color $white
	
	&_container
		display flex
		padding-top 90px
		padding-bottom 0
		
		@media $mdMinus
			padding-top 65px
		
		@media $smMinus
			padding-top 45px
			padding-bottom 45px
		
		@media $xs
			flex-direction column
			padding-top 34px
			padding-bottom 33px
	
	&_left
		width 48%
		padding 7px 30px 0 24px
		
		@media $mdMinus
			width 55%
		
		@media $smMinus
			width 65%
		
		@media $xs
			width 500px
			max-width 100%
			margin-right auto
			margin-left auto
			padding 0
	
	&_title
		margin-bottom 0.48275em
		font-weight 600
		font-size 58px
		line-height 1.1379
		
		@media $mdMinus
			font-size 48px
		
		@media $smMinus
			font-size 42px
		
		@media $xs
			margin-bottom 28px
			font-size 36px
	
	&_subTitle, &_text
		margin-left 5px
	
	&_subTitle
		max-width 485px
		margin-bottom 0.5833em
		font-weight 600
		font-size 24px
		line-height 1.34
		
		@media $mdMinus
			font-size 22px
		
		@media $xs
			font-size 21px
			line-height 1.34
	
	&_text
		max-width 500px
		font-weight 500
		font-size 16px
		line-height 1.5
		
		@media $mdMinus
			font-size 15px
		
		@media $xs
			line-height 1.6
	
	&_right
		flex 1 1 auto
		text-align right
	
	&_img
		@media $smMinus
			width 85%
		
		@media $xs
			display block
			width 320px
			margin 0 auto -20px