.Quantity
	display flex
	justify-content center
	align-items center
	width 20px
	height 20px
	border-radius 50%
	background $blue
	color $white
	font-weight 600
	font-size 11px
	
	&-message
		width 18px
		height 18px
		font-size 10px
		
		@media $xs
			width 20px
			height 20px
			font-size 12px