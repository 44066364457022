.Remark
	position relative
	padding 26px 20px 24px 43px
	border-radius 3px
	box-shadow 0 0 7px 0 rgba(0, 0, 0, 0.1)
	background url('../img/blue_checkmark.svg') 21px 29px no-repeat
	
	@media $xs
		padding 25px 17px 27px 38px
		background-position 16px 30px
	
	&_close
		position absolute
		top 8px
		right 8px
		
		@media $xs
			right 18px
	
	&_title
		margin-bottom 4px
		font-weight 600
		font-size 16px
		line-height 1.5
		
		@media $xs
			margin-bottom 0
			font-size 15px
			line-height 1.54
	
	&_text
		font-size 15px
		line-height 1.6
	
	&-contactBtns,
	&-addTestimonial
		padding 21px 38px 18px 49px
		background-position 22px 26px
		
		@media $xs
			padding 16px 16px 13px 38px
			background-position 15px 21px
		
		.Remark_close
			right 18px
			
			@media $xs
				top 9px
				right 10px
	
	&-addTestimonial
		@media $xs
			padding 15px 40px 16px 20px
			background none
			
			.Remark_close
				top 14px
				right 14px
			
			.Remark_text
				font-size 14px
				line-height 1.5714
	
	&-explanation
		padding 21px 45px 18px 21px
		background none
		
		.Remark_close
			top 13px
			right 19px