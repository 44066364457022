.Dossier
	display flex
	align-items flex-start
	
	&_imgWrap
		flex-shrink 0
		margin-top 6px
	
	&_content
		flex-grow 1
		margin-left 41px
	
	&_header
		display flex
		justify-content space-between
		align-items center
		margin-bottom 8px
	
	&_headerLeft
		display flex
		align-items center
	
	&_title
		margin-right 20px
		font-weight 500
		font-size 24px
		letter-spacing 0.01em
	
	&_entity
		margin-top 8px
	
	&_labels
		margin-bottom 9px
	
	&_text
		margin-bottom 26px
		font-size 14px
		line-height 1.7142