.MaskedInfo
	position relative
	
	&:after
		content ''
		position absolute
		top 0
		right 0
		bottom 0
		left 0
		background url('../img/pattern.png') 0 0 repeat