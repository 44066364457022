.Select
	min-width 155px
	height 54px
	padding-right 36px
	padding-left 30px
	appearance none
	border none
	background $white url('../img/arrow_down.svg') calc(100% - 26px) center no-repeat
	outline none
	cursor pointer
	
	@media $xs
		color $blue
		font-weight 500
	
	&-small
		height 43px
		padding-left 26px
		background-position calc(100% - 16px) center
		
		@media $xs
			height 45px
			padding-right 10px
			padding-left 10px
			background-position calc(100% - 8px) center
	
	&-bordered
		border 1px solid $grey
	
	&-profileSettings
		width 280px
		max-width 100%
		height 35px
		padding-left 10px
		background-position calc(100% - 12px) center
		
		@media $xs
			width 100%
			height 45px
	
	&-messenger
		width 220px
		
		@media $xs
			width 106px
	
	&-filterBlock
		width 100%
		padding-left 24px
		background-position calc(100% - 13px) center

// FSelect
.fs-wrap
	position relative
	display inline-block
	width auto
	outline none

.fs-dropdown
	margin-top 10px
	border-color $grey
	box-shadow 0px 4px 10px rgba(100, 124, 151, 0.1)
	
	&:before
		content ""
		position absolute
		top -10px
		left 14px
		width 16px
		height 10px
		background url('../img/corner_shadow.jpg') 0 0 no-repeat
	
	.fs-options
		// max-height none
		position relative
		max-height 260px
		padding-top 5px
		padding-bottom 5px
		
		@media $xs
			max-height none

.fs-option
	padding 9px 17px
	border-bottom none
	font-size 13px
	color $blue2
	@extend .transition
	
	&:hover, &.selected
		color $blue

.fs-label-wrap
	display inline-block
	padding-right 8px
	border none
	cursor pointer
	
	.fs-label
		padding 0
		font-weight 500
		font-size 13px
		color $blue2
	
		&.fs-label-selected
			color $blue

.fs-arrow
	position absolute
	top 4px
	right 0
	border 3px solid transparent
	border-top 3px solid #4b97f9

.fs-open .fs-arrow
	top -2px

.Filter_item
	@media $xs
		.fs-label-wrap
			padding-right 13px
			
			.fs-label
				font-weight 600
				font-size 16px
				line-height 1.625
				color $black
	
				&.fs-label-selected
					color $blue
		
		.fs-arrow
			top -2px
			width 9px
			height 6px
			border none
			background url('../img/arrow_bottom_blue.svg') right center / cover no-repeat
		
		&-mobile
			.fs-label-wrap .fs-label
				color $blue
				
			.fs-dropdown
				z-index 1001
				right auto
				left 0
				margin-top 0
				border none
				border-radius 4px
				
				.fs-options
					padding 16px 10px 13px
				
				&:before
					display none
			
			.fs-option
				padding 11px 10px
				font-weight 500
				font-size 16px
				color $blue3
				
				&:hover, &.selected
					color $blue
				
				&.selected
					font-weight 600
		
		&-staticDropdownxs
			.fs-wrap
				display block
			
			.fs-label-wrap
				display block
				
			.fs-dropdown
				position static
				width 100%
				border none
				box-shadow none
				background transparent
			
			.fs-option
				padding 11px 0px
				font-weight 500
				font-size 15px
				color $blue3
				
				&:hover, &.selected
					color $blue