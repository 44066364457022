.Overlay
	content ""
	position fixed
	z-index 1000
	top 0
	left 0
	width 100%
	height 100%
	background rgba($black, 50%)
	visibility hidden
	opacity 0
	@extend .transition

	&-visible
		visibility visible
		opacity 1