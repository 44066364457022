.PostQuestion
	@media $xs
		padding-right 5px
		padding-top 12px
		padding-left 5px
	
	.TopicCreationForm
		@media $xs
			margin-bottom 25px
		
	&_dropdowns
		display flex
		margin-bottom 40px
		
		@media $xs
			display block
			margin-bottom 21px
	
	&_selectWrapper
		flex-basis 33.33%
		padding-right 18px
		
		@media $xs
			margin-bottom 23px
			padding-right 0
			
			&:last-child
				margin-bottom 0
		
		&:last-child
			padding-right 0