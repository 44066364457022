.PopularTopics
	&_header
		display flex
		justify-content space-between
		align-items center
		margin-bottom 27px
		padding-bottom 6px
		border-bottom 1px solid #ebf0f2
	
	&_title
		font-weight 600
		font-size 16px
	
	&_dropdownSelect
		margin-top 3px
	
	&_item
		margin-bottom 26px
		padding-bottom 18px
		border-bottom 1px solid #ebf0f2
		
		&:last-child
			margin-bottom 0
			padding-bottom 0
			border-bottom none