.UploadedImages
	position relative
	
	&_wrapper
		display flex
		
		@media $xs
			overflow-x scroll
	
	&::after
		@media $xs
			content ""
			position absolute
			top 0
			right 0
			width 40px
			height 70px
			background-image: linear-gradient(-90deg, rgba(249, 249, 249, 0.9490196078431373) 0%, rgba(249, 249, 249, 0) 100%)
			pointer-events none
	
	&_item
		position relative
		flex 90px 0 0
		margin-right 8px
		
		&:last-child
			margin-right 0
	
	&_img
		display block
		border-radius 2px
	
	&_removeItem
		position absolute
		top 0
		right 0
		width 20px
		height 20px
		border-radius 50%
		background rgba(0, 0, 0, .4) url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10'%3E%3Cstyle%3E%3C/style%3E%3Cg id='Files'%3E%3Cpath id='Фигура 45 копия 2' d='M5 4.01L8.45.55l.99.99L5.98 5l3.46 3.46-.99.98L5 5.99 1.54 9.44l-.99-.98L4.01 5 .55 1.54l.99-.99L5 4.01z' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E") center center / 10px auto no-repeat