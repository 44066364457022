.Swiper
	border-radius 8px
	
	&_wrapper
		align-items flex-end
	
	&_slide
		// width auto
		width auto
		border-radius 8px
		
		& > img
			display block
			max-height 200px
			border-radius 8px
		
		&-video
			&::after
				content ""
				position absolute
				top 0
				right 0
				bottom 0
				left 0
				width 50px
				height 50px
				margin auto
				border-radius 50%
				background $white url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 477.867 477.867'%3E%3Cpath d='M238.933 0C106.974 0 0 106.974 0 238.933s106.974 238.933 238.933 238.933 238.933-106.974 238.933-238.933C477.726 107.033 370.834.141 238.933 0zm100.624 246.546a17.068 17.068 0 01-7.662 7.662v.085L195.362 322.56c-8.432 4.213-18.682.794-22.896-7.638a17.061 17.061 0 01-1.8-7.722V170.667c-.004-9.426 7.633-17.07 17.059-17.075a17.068 17.068 0 017.637 1.8l136.533 68.267c8.436 4.204 11.867 14.451 7.662 22.887z'/%3E%3C/svg%3E") 0 0 / cover no-repeat
				// background url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30.051 30.051'%3E%3Cpath d='M19.982 14.438l-6.24-4.536a.752.752 0 00-1.195.607v9.069a.75.75 0 001.195.606l6.24-4.532a.747.747 0 000-1.214z' fill='%23fff'/%3E%3Cpath d='M15.026.002C6.726.002 0 6.728 0 15.028c0 8.297 6.726 15.021 15.026 15.021 8.298 0 15.025-6.725 15.025-15.021.001-8.3-6.727-15.026-15.025-15.026zm0 27.54c-6.912 0-12.516-5.601-12.516-12.514 0-6.91 5.604-12.518 12.516-12.518 6.911 0 12.514 5.607 12.514 12.518.001 6.913-5.603 12.514-12.514 12.514z' fill='%23fff'/%3E%3C/svg%3E") 0 0 / cover no-repeat
				// opacity 0.7
			
			// &::before
			// 	background $white