.Bookmark
	display flex
	-webkit-align-items center
	align-items center
	
	&_notificationMenu
		margin-right 15px
		
	&_icon
		display inline-block
		width 16px
		color $blue3
		
		@media $xs
			width 17px
		
		&-small
			width 14px
	
	&-active
		.Bookmark_icon
			color $orange
			
			path
				fill $orange
	
	&-article,
	&-news
		.Bookmark_icon
			width 18px
	
	&-company
		.Bookmark_icon
			width 16px
	
	&-companyBellCheck
		.Bookmark_icon
			width auto
			color $blue3
			
			.SvgIco-bellCheck
				width 20px
				
				.SvgIco_path
					fill $blue3
					
					&-check
						fill $orange
	
	&-animated
		.Bookmark_icon
			animation pulsating-animation .45s ease-in-out

@keyframes pulsating-animation
	from,
	to
		transform scale(1)
	25%
		transform scale(1.2)
	50%
		transform scale(0.95)