.ProfileContent
	padding-right 30px
	
	@media $xs
		padding-top 17px
		padding-right 0
	
	&_title,
	&_text
		padding-left 5px
	
	&_title
		margin-bottom 0.8214em
		font-weight 500
		font-size 28px
		line-height 1.42857
		
		@media $xs
			font-size 21px
	
	&_description
		padding-left 5px
		font-size 13px
		
		@media $smPlus
			margin-bottom 30px
	
	&_text
		padding-right 5px
		margin-bottom 0.875em
		font-size 16px
		line-height 1.625
		
		h2, h3
			margin 1em 0 .5em
			font-weight 600
			font-size 18px
			line-height 1.45
		
		p
			margin-bottom 11px
			
			@media $xs
				margin-bottom 10px
			
			&:last-child
				margin-bottom 0
		
		a
			color $blue
			text-decoration underline
			
			&:hover
				text-decoration none
		
		@media $xs
			margin-bottom 1.067em
			font-size 15px
			line-height 1.6
	
	&_moreText
		display none
		
		&-visible
			display block
	
	&_moreLinkWrapper
		margin-bottom 14px
		text-align right
		
		@media $xs
			padding-right 5px
	
	&_bonusForm
		margin-bottom 23px
		
		@media $xs
			margin-bottom 3px
	
	&_teamTable
		margin-left 3px
	
	&_myRating
		margin-bottom 33px
		
		@media $xs
			margin-bottom 20px
			margin-right -15px
			margin-left -15px
	
	&_ratingIncrease
		margin-top 24px
		margin-bottom 17px
		
		@media $xs
			margin-top 13px
			margin-bottom 9px
	
	&_imgWrapper
		margin-bottom 9px
		margin-left -25px
		
		@media $xs
			margin-top -19px
			margin-left 0
	
	&_note
		margin-top 39px
		margin-bottom 37px
		
		@media $xs
			margin-top 29px
			margin-bottom 28px
	
	&-rating
		padding-top 11px
		
		@media $xs
			padding-top 0
		
		.ProfileContent_text
			padding-right 0
			
			@media $xs
				padding-right 7px
			
			h3
				margin-top 1.6em
				
				@media $xs
					margin-top 1em
					margin-bottom .6em
					font-weight 500
					font-size 21px
					line-height 1.238
		
		.ProfileContent_bonusForm
			margin-top 27px
			
			@media $xs
				margin-top 12px
				margin-bottom 11px
	
	&-applications
		.ProfileContent_title
			margin-bottom 0
			
			@media $xs
				margin-bottom 10px