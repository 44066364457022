.RecentSearch
	background #F8F9FA
	font-weight 500
	
	&_body
		padding 20px
	
	&_title
		margin-bottom 1em
		font-size 15px
	
	&_item
		margin-bottom 1em
		font-size 14px
		line-height 1.5
		
		&:last-child
			margin-bottom 0
	
	&_link
		color $blue
	
	&_footer
		padding 13px 25px
		border-top 1px solid #DFE4E5
	
	&_moreLink
		color $blue2