.Question
	padding 27px 30px
	
	&_text
		margin-bottom 15px
	
	&_responses
		display flex
		align-items center
		font-size 11px
		
		& > .SvgIco
			margin-right 4px
			color $blue3