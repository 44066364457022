.AddTestimonial
	padding-right 30px
	
	@media $xs
		padding-right 5px
		padding-left 5px
	
	&_remark
		margin-bottom 32px
		
		@media $xs
			margin 0 -20px 25px
	
	&_title
		margin-bottom 21px
		font-weight 600
		font-size 30px
		line-height 1.34
	
	&_subTitle
		margin-bottom 26px
		font-weight 600
		font-size 18px
		
		@media $xs
			margin-bottom 22px
		
		&-reviewText
			margin-bottom 13px
		
		&-documentaryEvidance
			margin-bottom 16px
			
			@media $xs
				margin-bottom 7px
	
	&_field
		margin-bottom 33px
		
		@media $xs
			margin-bottom 38px
		
		&:last-child
			margin-bottom 0
		
		&-documentsPreparation
			margin-bottom 52px
			
			@media $xs
				margin-bottom 36px
		
		&-reviewText
			margin-bottom 55px
			
			@media $xs
				margin-bottom 35px
		
		&-anonymousReview
			@media $xs
				margin-bottom 23px
	
	&_fieldRow
		display flex
		align-items center
		margin-bottom 12px
		
		@media $xs
			margin-bottom 18px
		
		&:last-child
			margin-bottom 0
		
		&-gutterLeft
			padding-left 28px
			
			@media $xs
				padding-left 0
	
	&_removeFieldRow
		width 30px
		height 30px
		padding 10px
		background url('../img/icon_close.svg') center center / 10px auto no-repeat
	
	&_dropdownSelect
		width 260px
		
		@media $xs
			width 100%
	
	&_addFieldItem
		margin-top 19px
		margin-left 30px
		
		@media $xs
			margin-top 25px
			margin-left 0
	
	&_ratings
		margin-bottom 50px
		
		@media $xs
			margin-bottom 36px
	
	&_link
		color $blue
		font-weight 500
		
		&-cancel
			margin-left 30px
			
			@media $xs
				margin-top 26px
				margin-left 0
	
	&_text
		margin-bottom 7px
		font-size 15px
		line-height 1.67
		
		@media $xs
			margin-bottom 17px
			line-height 1.6
	
	&_note
		color $blue3
		font-size 13px
		line-height 1.616
		
		@media $xs
			margin-bottom 18px
			font-size 15px
			line-height 1.6
			color $black
		
		@media $smPlus
			padding 30px 30px
	
	&_actions
		@media $xs
			display flex
			flex-direction column
			justify-content center
			align-items center
	
	&_tabs
		@media $xs
			margin-right -20px
			margin-left -20px
	
	&-evaluation
		@media $xs
			padding-top 15px