.QuestionsSection		
	&_item
		margin-bottom 25px
		padding-bottom 20px
		border-bottom 1px solid #ebf0f2
		
		@media $xxs
			margin-bottom 10px
			
			&:last-child
				margin-bottom 0
		
		&:last-child
			margin-bottom 0
	
	&-saved
		.QuestionsSection_item
			margin-bottom 27px
			padding-bottom 23px
			
			&:last-child
				margin-bottom 0