.FilterTag
	display inline-flex
	align-items center
	padding 3px 13px
	border-radius 17px
	background $grey
	
	&_close
		width 7px
		margin-bottom 2px
		margin-left 8px
	
	&-white
		border 1px solid #e4e4e4
		background $white
		
		@media $xxs
			padding 3px 17px
			font-size 14px