.AttachedImage
	position relative
	width 80px
	height 80px
	border 1px solid $grey3
	text-align center
	
	&_img
		max-height 100%
	
	&_remove
		position absolute
		top -10px
		right -16px
		width 30px
		height 30px
		border 1px solid $grey3
		border-radius 50%
		background $white url('../img/icon_remove_attach.svg') center center no-repeat