.FormTextarea
	display block
	width 100%
	max-width 100%
	padding 17px 20px
	border 1px solid $grey
	color $black
	font-size 15px
	@extend .transition
	outline none
	resize none
	overflow hidden
	
	&:focus
		border-color $blue3
	
	&-commentForm
		min-height 72px
	
	&-commentFormCompany
		height 55px
		
		@media $xs
			height 60px
	
	&-topicCreationForm,
	&-vacancyCreationForm
		height 171px
		padding 20px 25px
		color $black
		
		@media $xs
			height 160px
			padding 12px 10px
	
	&-submitComplaint,
	&-workPlace
		height 100px
		padding 7px 9px
		font-size 16px
		resize both
		
		+placeholder()
			color rgba($blue2, 30%)
	
	&-sendMessage
		position relative
		min-height 50px
		max-height 200px
		padding 15px 50px 15px 20px
		border-color #DFE2E6
		border-radius 9px
		font-size 14px
		color $blue2
		overflow-x hidden
		overflow-y auto
		
		@media $xs
			max-height 145px
		
		&::-webkit-scrollbar
			width 0
		
		@media $xs
			height 40px
			padding 10px 9px
	
	&-companySettings,
	&-enterpriseAddition
		min-height 90px
		padding 10px 12px
		border-radius 3px
		
		@media $xs
			min-height 160px
	
	&-enterpriseAddition
		min-height 180px
		
		@media $xs
			min-height 159px
	
	&-addTestimonial
		min-height 150px
		padding 15px 16px
		border-color #EDF1F4
		border-radius 3px
		font-size 14px
		color $blue2
		
		@media $xs
			min-height 0
			max-height 184px
			overflow scroll
	
	&-jobDescription,
	&-serviceDescription
		min-height 111px
		padding 10px 12px
		border-radius 2px
		
		@media $xs
			min-height 160px