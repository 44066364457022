.Earnings
	display inline-flex
	align-items center
	
	& > .SvgIco
		height 14px
		margin-right 5px
		color #EAEAEA
	
	&_number
		font-size 16px
		
		@media $xs
			font-size 15px