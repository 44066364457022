.Plans
	padding 101px 0
	
	@media $xs
		padding 28px 0 20px
	
	&_header
		margin-bottom 28px
		
		@media $xs
			margin-bottom 18px
	
	&_title
		margin-bottom 4px
		font-weight 600
		font-size 37px
		
		@media $xs
			margin-bottom 5px
			font-size 23px
			line-height 1.2
	
	&_subTitle
		margin-bottom 66px
		font-weight 600
		font-size 18px
		line-height 1.56
		
		@media $xs
			margin-bottom 0
			font-size 16px
			line-height 1.5
	
	&_currency
		margin-left 8px
		
		@media $xs
			margin-left 0
	
	&_currencyLabel
		font-size 15px
		
		@media $xs
			color #36404D
	
	&_slider
		@media $xs
			margin-right -20px
			margin-left -5px