.Main
	flex auto 1 1
	background $white
	
	@media $xs
		margin-top 77px
	
	&_container
		padding-top 40px
		padding-bottom 32px
		
		@media $xs
			padding-top 0
			padding-bottom 50px
	
	&_columns
		display flex
		margin-right -15px
		margin-left -15px
		
		@media $smMinus
			flex-direction column
	
	&_left,
	&_aside
		padding-right 15px
		padding-left 15px
	
	&_left
		width 67%
		
		@media $smMinus
			width auto
		
	&_menuSection
		margin-right 30px
		margin-bottom 27px
		
		@media $xs
			margin-right -15px
			margin-left -15px
			margin-bottom 12px
	
	&_topicsHeader
		margin-bottom 33px
	
	&_sectionDescription
		margin-top 25px
		margin-bottom 15px
		
		@media $xs
			margin-top 17px
	
	&_banner
		// margin-bottom 12px
		margin-bottom 20px
	
	&_pageTitle
		margin-top -6px
		margin-bottom 20px
		
		@media $xs
			margin-top -10px
			margin-bottom 17px
	
	&_topicsFilter
		margin-bottom 19px
		padding-right 30px
		
		@media $smMinus
			padding-right 0
		
		@media $xs
			position relative
			margin-bottom 0
		
		&-opened
			z-index 100
	
	&_aside
		width 33%
		
		@media $smMinus
			width auto
	
	&_newsSidebar
		margin-top 43px
		
		@media $xs
			margin-top 22px
	
	&_categories,
	&_dossierList
		flex-grow 1
		padding-left 18px
	
	&_dateSortTags
		margin-top 13px
		margin-bottom 5px
	
	&_breadcrumbs
		@media $xs
			margin-left 4px
	
	&-infoPages
		background $white
		
		.TopMenu
			margin-bottom 40px
		
		.Main_container
			padding-top 43px
		
		.Main_columns
			margin-right 0
			margin-left 0
		
		.Breadcrumbs
			@media $xs
				margin-top 4px
				margin-left 4px
	
	&-article
		padding-bottom 80px
		
		@media $xs
			margin-top 45px
		
		.Main_container
			padding-top 31px
			
			@media $xs
				padding-top 24px
				padding-bottom 10px
		
		.Main_content
			flex-grow 1
		
		.Breadcrumbs
			margin-bottom 20px
	
	&-loginPage
		background #F4F9FC
	
	&-newsFeed
		.SidebarMenu
			margin-top -7px
		
		.Main_container
			@media $smMinus
				padding-top 30px
			
			@media $xs
				// padding-top 5px
				// padding-top 20px
				padding-top 0
		
		.Main_sidebar
			@media $xs
				padding-left 4px
			
		.Main_content
			margin-left 24px
			
			@media $smMinus
				margin-left 0
		
		.Breadcrumbs
			@media $md
				// margin-bottom 10px
				// margin-top -15px
				margin-top -21px
				margin-bottom 12px
		
		.Main_banner
			@media $xs
				margin 20px 5px 25px
			
			@media $md
				margin-bottom 35px
		
		.Main_pageTitle
			@media $xs
				margin 4px 0 11px 5px
	
	&-dossier
		.Main_container
			padding-top 0
			
			@media $smPlus
				padding-bottom 0
			
		@media $xs
			margin-top 67px
	
	&-profileInvite
		.Main_container
			padding-bottom 64px
	
	&-pageAbout,
	&-contactsPage
		.Main_container
			@media $smPlus
				padding-top 0
				padding-bottom 0
	
	&-contactsPage
		@media $xs
			margin-top 0
			// margin-top 67px
			// margin-top 128px
			
		&, .Main_container
			@media $smPlus
				display flex
				flex-direction column
				flex-grow 1
		
		.WriteUs
			@media $smPlus
				flex-grow 1
	
	&-profileSettings
		@media $xs
			padding-top 18px
	
	&-notifications
		@media $xs
			margin-top 0
			// padding-top 13px
			
		.Main_menuSection
			margin-right 0
			margin-bottom 0
			
			@media $xs
				// margin-top 6px
				margin-top 14px
				margin-bottom 6px
	
	&-profileMessages		
		.Main_container
			padding-top 30px
			
			@media $smMinus
				padding-top 15px
			
			@media $xs
				padding-top 11px
		
		.Main_left
			width 80%
			
			@media $smMinus
				width auto
			
			@media $xs
				padding-right 0
				padding-left 0
		
		.Main_aside
			position relative
			width 20%
			height calc(100vh - 145px)
			padding-top 3px
			padding-left 21px
	
	&-companyPage
		@media $xs
			margin-top 0
			
		.Main_container
			padding-top 44px
			
			@media $smMinus
				padding-top 30px
				padding-bottom 80px
			
			@media $xs
				padding-top 24px
				overflow hidden
		
		.Breadcrumbs
			margin-bottom 20px
	
	&-companies
		.Main_container
			padding-bottom 50px
			
		.Main_columns
			margin-right 0
			margin-left 0
			
		.Main_sidebar
			flex 260px 0 0
			margin-right 42px
			padding-left 3px
		
		.Main_content
			flex auto 1 1
	
	&-companiesDossiers
		.Main_left
			@media $xs
				padding-right 20px
				padding-left 20px
				
		.Main_aside
			padding-right 11px
		
		.Breadcrumbs
			margin-top -15px
			margin-bottom 12px
			
			@media $xs
				margin-top 4px
				margin-bottom 13px
		
		.Main_pageTitle
			@media $md
				margin-bottom 0
		
		.Main_banner
			@media $md
				margin-top 20px
	
	&-profileBalance
		.Main_container
			padding-bottom 43px
		
		.Main_aside
			margin-top -3px
			padding-left 13px
	
	&-companySettings
		.Main_container
			@media $smMinus
				padding-top 20px
				padding-bottom 20px
			
			@media $xs
				padding-top 5px
				padding-bottom 90px
		
		.Main_menuSection
			margin-right -15px
			margin-bottom 0
			margin-left -15px
			
			@media $xs
				margin-bottom 32px
	
	&-save
		@media $xs
			padding-right 0
			padding-left 0
			
		.Main_container
			@media $xs
				padding-top 4px
				
		.Main_menuSection
			margin-right 26px
			
			@media $xs
				margin-bottom 24px
				margin-right -15px
		
		.Main_popupMenuLinks
			margin-bottom 6px
			margin-left 3px
	
	&-vacancyDummy
		@media $xs
			margin-top 0
	
	&-successfulAuth
		@media $xs
			margin-top 50px
	
	&-realReviews
		@media $xs
			padding-bottom 50px
	
	&-addTestimonial
		.Main_container
			padding-top 32px
			
			@media $xs
				padding-top 0
				padding-bottom 80px
		
	&-vacancyFull
		@media $xs
			margin-top 0
			
		.Main_container
			padding-top 28px
			
			@media $xs
				padding-top 0
				padding-right 20px
				padding-left 20px
		
		.Breadcrumbs
			margin-bottom 18px
			
			@media $xs
				margin-top 20px
				margin-bottom 0
	
	&-vacancies,
	&-vacanciesFilter
		.Breadcrumbs
			margin-bottom 3px
			
			@media $xs
				margin-top 9px
				margin-bottom 0
				margin-right 5px
				margin-left 5px
		
		.Main_banner
			margin-bottom 4px
			
		@media $md
			.Main_container
				// padding-top 30px
				padding-top 25px
			
			.Main_left
				padding-right 46px
			
			.Main_aside
				padding-top 20px
	
	&-vacanciesFilter
		@media $xs
			overflow-x hidden
			
			.Main_banner
				margin-top 15px
				margin-bottom 10px
			
		@media $md
			.Main_columns
				justify-content space-between
				
			.Main_left
				width 68%
				// width 100%
				padding-right 0
			
			// .Main_aside
			// 	margin-top 60px
		
		@media $xs
			margin-top 70px
	
	&-post
		@media $xs
			margin-top 0
			
		.Breadcrumbs
			margin-top -10px
			margin-bottom 20px
			
			@media $xs
				margin-top 12px
				margin-bottom 0
	
	&-employers
		.Main_container
			@media $md
				padding-top 24px
				padding-bottom 70px
				
			@media $xlPlus
				padding-bottom 166px
		
		.Breadcrumbs
			margin-bottom 14px
		
			@media $smPlus
				margin-bottom 55px
			
			@media $md
				margin-bottom 40px
			
			@media $xlPlus
				margin-bottom 83px
				
			&_item
				font-size 12px
	
	&-replenishment
		.Main_container
			padding-top 3px
			
			@media $smPlus
				padding-top 24px
		
		.Breadcrumbs
			margin-bottom 11px
			
			@media $smPlus
				margin-bottom 50px
			
			@media $xlPlus
				margin-bottom 147px
	
	&-homePage
		.Main_pageTitle
			@media $xs
				margin-left 5px