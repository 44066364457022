// PopupBlock
.PopupBlock
	position absolute
	z-index 100
	display block
	width 378px
	max-width 100%
	background #FFF
	box-shadow 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.4)
	font-family 'Roboto', Arial, sans-serif
	
	&_body
		padding 24px 24px 0
	
	&_title
		margin-bottom 16px
		font-size 16px
		line-height 1.25
		color #0D0D0D
	
	&_text
		margin-bottom 32px
		line-height 21px
		color #606060
	
	&_bottom
		padding 18px 8px 19px 24px
		border-top 1px solid #0000001a
	
	&_link
		color #065FD4
		font-weight 500
		text-transform uppercase