.PageHeader
	background url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgMTAyNCA1NjAiPjxkZWZzPjxyYWRpYWxHcmFkaWVudCBpZD0iYyIgY3g9IjUyLjcxJSIgY3k9Ijc2LjYwNyUiIHI9IjExNi4yMiUiIGZ4PSI1Mi43MSUiIGZ5PSI3Ni42MDclIiBncmFkaWVudFRyYW5zZm9ybT0ibWF0cml4KDAgLTEgLjM1ODI1IDAgLjI1MyAxLjI5MykiPjxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiMzNTgzRUUiLz48c3RvcCBvZmZzZXQ9IjY2Ljc2MSUiIHN0b3AtY29sb3I9IiMyRTUzQUYiLz48c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMyQjNDOTIiLz48L3JhZGlhbEdyYWRpZW50PjxwYXRoIGlkPSJiIiBkPSJNNTA1IDBoMTI3OXY0NzEuNzFDMTYwNy43NDUgNTMxLjIzOCAxNDExLjE0OSA1NjEgMTE5NC4yMTMgNTYxIDk3Ny4yNzcgNTYxIDc0Ny41MzkgNTMxLjIzNyA1MDUgNDcxLjcxVjB6Ii8+PGZpbHRlciBpZD0iYSIgd2lkdGg9IjEwMy45JSIgaGVpZ2h0PSIxMDguOSUiIHg9Ii0yJSIgeT0iLTMuNiUiIGZpbHRlclVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCI+PGZlT2Zmc2V0IGR5PSI1IiBpbj0iU291cmNlQWxwaGEiIHJlc3VsdD0ic2hhZG93T2Zmc2V0T3V0ZXIxIi8+PGZlR2F1c3NpYW5CbHVyIGluPSJzaGFkb3dPZmZzZXRPdXRlcjEiIHJlc3VsdD0ic2hhZG93Qmx1ck91dGVyMSIgc3RkRGV2aWF0aW9uPSI3LjUiLz48ZmVDb2xvck1hdHJpeCBpbj0ic2hhZG93Qmx1ck91dGVyMSIgdmFsdWVzPSIwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwLjIgMCIvPjwvZmlsdGVyPjwvZGVmcz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC01MjQgLTIxKSI+PHVzZSBmaWxsPSIjMDAwIiBmaWx0ZXI9InVybCgjYSkiIHhsaW5rOmhyZWY9IiNiIi8+PHVzZSBmaWxsPSJ1cmwoI2MpIiB4bGluazpocmVmPSIjYiIvPjwvZz48L3N2Zz4=') center bottom / cover no-repeat
	color $white
	text-align center
	
	&_container
		padding-top 56px
		padding-bottom 140px
		
		@media $xs
			padding-top 30px
			padding-bottom 80px
	
	&_title
		max-width 974px
		margin 0 auto 0.18em
		font-size 55px
		font-family 'Welcome', sans-serif
		text-transform uppercase
		
		@media $xs
			font-size 40px
	
	&_subTitle
		font-size 25px
		font-family 'Decima Nova Pro', sans-serif
		font-weight 700
		
		@media $xs
			font-size 24px