.LoginMenu
	&_menu
		text-align center
	
	&_link
		display block
		padding 7px 0
		font-weight 500
		font-size 15px
		color $blue2
		@extend .transition
		
		&:hover
			color $blue