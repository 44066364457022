.CategoryBlock
	width 280px
	font-family $decimaNovaPro 
	
	&_imgWrap
		margin-bottom 11px
		border 1px solid $grey
		
		& > img
			display block
	
	&_title
		margin-bottom 4px
		font-weight 600
		font-size 16px
	
	&-column
		display flex
		width auto
		
		.CategoryBlock_imgWrap
			flex-shrink 0
			margin-right 16px