.DossierList
	&_filter
		margin-bottom 58px
	
	&_item
		margin-bottom 34px
		padding-bottom 38px
		border-bottom 1px solid $grey
		
		&:last-child
			border-bottom none