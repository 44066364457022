.Statistics
	display flex
	align-items center
	color #AFB0B4

	&_item
		display flex
		align-items center
		margin-right 20px
		
		.SvgIco
			margin-right 5px
		
		&:last-child
			margin-right 0
	
	&_link
		&:hover, &-likeActive
			color $orange
	
	&-news
		.Statistics_item
			margin-right 10px
	
	&-article
		.Statistics_item
			@media $smPlus
				margin-right 25px