.PopupMenu
	// z-index 101
	z-index 103
	visibility hidden
	opacity 0
	
	&, &_overlay
		position fixed
		top 0
		right 0
		bottom 0
		left 0
		@extend .transition
	
	&_overlay
		background rgba($black, 50%)
	
	&_title
		margin 0 0 10px
		padding 0 10px 10px
		border-bottom 1px solid $grey
		font-weight 600
		font-size 16px
	
	&_wrapper
		position absolute
		min-width 179px
		max-width 100%
		padding 16px 10px 13px
		border-radius 4px
		background $white
	
	&_link, &_label
		display block
		padding 11px 10px
		font-weight 500
		font-size 16px
		color $blue3
		@extend .transition
		
		&:hover, &.active
			color $blue
		
		&.active
			font-weight 600
	
	&_link
		&-iconWrapper
			display flex
			justify-content space-between
			align-items center
	
	&_icon
		margin-bottom 2px
		margin-left 15px
		color $blue
	
	&-opened
		visibility visible
		opacity 1
	
	&-profileMessages
		.PopupMenu_wrapper
			width 240px
	
	&-topRight
		.PopupMenu_wrapper
			top 10px
			right 34px
	
	&-paddingLeft
		.PopupMenu_wrapper
			padding-left 4px
	
	&-paddingTop
		.PopupMenu_wrapper
			padding-top 4px
			padding-bottom 5px
		
		.PopupMenu_link:first-child
			padding-top 7px
	
	&-login
		.PopupMenu_wrapper
			width 240px
	
	&-contact
		top 44px
		right 5px
		
		.PopupMenu_wrapper
			padding 11px 10px 11px 13px
			
		.PopupMenu_link
			padding 9px 10px
			
			&:not(.active)
				color #404040