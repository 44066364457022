.MobilePopupLinks
	&_item
		margin-bottom 34px
		
		&:last-child
			margin-bottom 0
			
	&_link
		color $blue
		font-weight 500
		font-size 16px