.BtnViolet
	display inline-grid
	align-items center
	grid-template-columns 21px auto
	gap 8px
	justify-content center
	max-width 100%
	padding 8px 16px 7px 12px
	border none
	outline none
	border-radius 19.5px
	background $violet
	color $white
	font-weight 500
	font-size 16px
	white-space nowrap
	text-align center
	@extend .transition
	
	& > svg
		max-width 100
		
	&:hover
		background darken($violet, 10)