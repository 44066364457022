.TimeRangeDropdown
	position relative

	// @media $xs
	// 	&:after
	// 		content ""
	// 		position fixed
	// 		z-index 100
	// 		top 0
	// 		left 0
	// 		width 100%
	// 		height 100%
	// 		background transparent
	// 		pointer-events none
	// 		@extend .transition
	
	&_label
		display flex
		justify-content space-between
		align-items center
		height 44px
		padding-right 12px
		padding-left 12px
		border 1px solid $grey
		border-radius 3px
		color $blue
		background $white
		font-weight 500
		font-size 15px
		cursor pointer

		@media $xs
			font-size 16px
		
		&:after
			content ""
			margin-top 5px
			margin-left 3px
			border 3px solid transparent
			border-top 3px solid $blue
			
			@media $xs
				border none
				width 9px
				height 6px
				margin-top 3px
				background url('../img/arrow_down.svg') 0 0 / cover no-repeat
				@extend .transition
	
	&_dropdown
		display none
		position absolute
		z-index 10
		top calc(100% + 12px)
		left -28px
		width 223px
		padding 20px
		box-shadow 0 0 5px 0 rgba($black, 0.1)
		background $white
		
		@media $xs
			z-index 1001
			left 0
			border-radius 4px
			box-shadow 0 4px 10px rgba(100, 124, 151, .1)
		
		&:before
			content ""
			position absolute
			top -10px
			right 0
			left 0
			width 16px
			height 10px
			margin auto
			background url('../img/corner_shadow.jpg') 0 0 no-repeat

			@media $xs
				display none
	
	&_wrapper
		display flex
		align-items flex-start
		margin-bottom 15px
	
	&_time
		width 84px
		height 44px
		margin-right 15px
		padding-right 8px
		padding-left 4px
		border 1px solid $grey
		border-radius 3px
		outline none
		box-shadow none
		font-size 15px
		color $blue3
		
		&::-webkit-inner-spin-button,
		&::-webkit-clear-button
			-webkit-appearance none
		
		&::-moz-clear
			display none
		
		&:before
			content attr(data-placeholder)
			width 100%
			margin-top 5px
			margin-left 4px
			color $blue3
		
		&:focus, &-notEmpty
			&:before
				content "" !important
				width 0
		
		&:last-child
			margin-right 0
	
	&-opened
		@media $xs
			&:after
				background rgba(0, 0, 0, .2)

		.TimeRangeDropdown_label
			&:after
				@media $xs
					transform rotate(180deg)
			
		.TimeRangeDropdown_dropdown
			display block