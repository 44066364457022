.SocIcons
	&_list
		display grid
		grid-template-columns repeat(3, 40px)
		gap 16px
		justify-content center
		align-items center
	
	&-contactsBlock
		.SocIcons_list
			display flex
			gap 25px