.ProfileMessages
	display flex
	
	&_left
		width 320px
		flex 320px 0 0
		
		@media $xs
			width 100%
			flex auto 1 1
			height auto
	
	&_dialogueList
		position relative
		max-height calc(100vh - 193px)
		margin-bottom 15px
		
		@media $xs
			height auto
			margin-bottom 0
	
	&_link
		color $blue
		font-weight 500
		font-size 13px
		
		&-showUnread
			margin-left 3px
	
	&_messages
		@media $xs
			margin-top 6px
	
	&_right
		flex-grow 1
		padding-right 4px
		padding-left 43px
		
		@media $smMinus
			padding-right 0
			padding-left 20px
		
		@media $xs
			padding-left 0