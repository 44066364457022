.Languages
	&_list
		display flex
		justify-content center
		align-items center
	
	&_item
		&:first-child
			margin-right 13px
			
			@media $smPlus
				margin-right 10px
	
	&_link
		display block
		padding 3px
		border 2px solid transparent
		border-radius 2px
	
		&-active
			border-color rgba(129, 192, 35, .5)
	
	&_icon
		display block
		
		@media $smPlus
			width 36px
	
	&_arrowBtn
		position absolute
		top -5px
		right 0
		display inline-flex
		justify-content center
		align-items center
		width 53px
		height 55px
		padding-top 6px
		transform rotate(-90deg)
		transition transform
		
		& > svg
			width 9px
		
		&-turned
			top 3px
			right 3px
			width 65px
			height 73px
			align-items flex-start
			padding-top 16px
			padding-left 26px
			transform rotate(0)
	
	&-mobileNavigation
		position relative
		max-height 44px
		overflow hidden
		@extend .transition
		
		.Languages_list
			flex-direction column
			align-items stretch
		
		.Languages_item			
			&:first-child
				margin-right 0
		
			&-current
				order -1
				
				.Languages_link
					padding-top 10px
			
			&:not(.Languages_item-current)
				.Languages_link
					padding-bottom 10px
			
		.Languages_link
			display flex
			align-items center
			padding 5px 18px
			@extend .transition
			
			// &-arrow
			// 	position relative
				
			// 	&:after
			// 		content ""
			// 		position absolute
			// 		top 17px
			// 		right 19px
			// 		width 9px
			// 		height 6px
			// 		background url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMSA4LjEyNSI+PHBhdGggZGF0YS1uYW1lPSLQn9GA0Y/QvNC+0YPQs9C+0LvRjNC90LjQuiA1INC60L7Qv9C40Y8iIGQ9Ik0xMCAyLjM4OEw1LjUgNy4xMzMgMSAyLjM4OCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjODM5NmFjIiBzdHJva2Utd2lkdGg9IjIiLz48L3N2Zz4=') 0 0 no-repeat
			// 		transform rotate(-90deg)
			// 		@extend .transition
			
		.Languages_icon
			width 14px
			margin-right 7px
		
		.Languages_text
			font-weight 500
	
	&-expanded
		max-height 200px
		
		.Languages_link-arrow:after
			background-image url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMSA4LjEyNSI+PHBhdGggZGF0YS1uYW1lPSLQn9GA0Y/QvNC+0YPQs9C+0LvRjNC90LjQuiA1INC60L7Qv9C40Y8iIGQ9Ik0xMCAyLjM4OEw1LjUgNy4xMzMgMSAyLjM4OCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjNGI5N2Y5IiBzdHJva2Utd2lkdGg9IjIiLz48L3N2Zz4=')
			transform rotate(0)