.Link
	position relative
	font-weight 500
	color $orange
	
	&:after
		content ""
		position absolute
		left 0
		bottom 1px
		width 100%
		height 1px
		background $orange
		@extend .transition
		
	&:hover:after
		background transparent