.Label
	display inline-flex
	align-items center
	padding 5px 16px
	border-radius 4px
	background #F7FAFC
	color $blue2
	font-size 12px
	
	& > .SvgIco
		width 12px
		margin-right 5px
	
	&-green
		background #F2FAEE
		color $green2