.ExtendedRating
	display flex
	align-items flex-start
	
	@media $xs
		display block
	
	&_markAndNumber
		@media $xs
			display flex
			align-items center
			margin-bottom 1px
	
	&_mark
		font-weight 500
		font-size 16px
		
		@media $xs
			margin-right 7px
	
	&_wrapper
		flex-basis 497px
		margin-left 12px
		
		@media $xs
			margin-left 0
	
	&_label, &_ratingsNumber
		color $blue3
		font-weight 500
		font-size 13px
		
		@media $xs
			font-weight normal
	
	&_label
		margin-bottom 8px
		
		@media $xs
			margin-bottom 10px
			color $black
			line-height 1.6153
	
	&_line
		height 5px
		border-radius 2px
		background #EBF0F2
	
	&_lineFill
		width 0
		height inherit
		background-image -webkit-linear-gradient(to right, #f29624, #ffc20d)
		background-image -o-linear-gradient(to right, #f29624, #ffc20d)
		background-image linear-gradient(to right, #f29624, #ffc20d)
	
	&_ratingsNumber
		flex-shrink 0
		align-self flex-end
		margin-bottom -4px
		margin-left 21px
		
		@media $xs
			margin-bottom 0
			margin-left 0
			align-self initial
	
	&-short
		display block
		
		.ExtendedRating_markAndNumber
			display flex
			align-items center
		
		.ExtendedRating_mark
			margin-right 7px
		
		.ExtendedRating_label,
		.ExtendedRating_ratingsNumber
			font-weight normal
		
		.ExtendedRating_label
			color #000
			
			@media $xs
				margin-bottom 6px
		
		.ExtendedRating_ratingsNumber
			margin-bottom 0
			margin-left 0
			align-self initial
		
		.ExtendedRating_wrapper
			margin-left 0