.NotificationSettings
	padding 36px 28px 0 2px
	
	@media $xs
		padding-top 17px
		padding-right 5px
		padding-left 4px
	
	&_group
		margin-bottom 36px
		border-bottom 1px solid #ebf0f2
		
		@media $xs
			margin-bottom 26px
		
		&:last-child
			margin-bottom 0
			border-bottom none
	
	&_groupTitle
		margin-bottom 30px
		font-weight 600
		font-size 21px
		line-height 1.23809
		
		@media $xs
			margin-bottom 21px
			font-size 18px
			line-height 26px
	
	&_item
		margin-bottom 36px
		
		@media $xs
			margin-bottom 26px