.Thesis
	position relative
	display flex
	align-items flex-start
	
	&_number
		flex 0 0 25%
		font-weight 600
		font-size 120px
		line-height 0.833
		color #EDF1F2
		
		@media $xs
			position absolute
			top 0
			right 0
	
	&_textWrapper
		position relative
		flex 1 1 auto
		padding-left 10px
		
		@media $xs
			padding-left 7px
	
	&_title
		margin-bottom 0.472em
		font-weight 600
		font-size 36px
		line-height 1.278
		
		@media $mdMinus
			font-size 32px
		
		@media $smMinus
			font-size 28px
		
		@media $xs
			margin-bottom 0.67em
			font-size 24px
	
	&_text
		font-size 16px
		line-height 1.5
		letter-spacing -0.005em
		
		@media $xs
			font-size 15px
			line-height 1.6
		
		p
			margin-bottom 0.625em
			
			&:last-child
				margin-bottom 0
		
		a
			color $blue
			text-decoration underline
			
			&:hover
				text-decoration none
	
	&_imgContainer
		display flex
		align-items flex-start
		margin-top 35px
		
		@media $smMinus
			flex-direction column
			align-items center
		
		@media $xs
			margin-top 21px
			margin-bottom 6px
	
	&_imgBlock
		&-arrow
			position relative
			
			@media $xs
				margin-bottom 5px
			
			&:after
				content ""
				position absolute
				right -24px
				bottom 71px
				width 24px
				height 20px
				background url('../img/arrow_dossier.svg') 0 0 no-repeat
				
				@media $smMinus
					display none
	
	&_imgWrap
		margin-top 24px
		margin-bottom 45px
	
	&-pricing
		display grid
		grid-template-columns 415px 1fr
		
		@media $xs
			grid-template-columns auto
		
		.Thesis_left
			position relative
			
		.Thesis_title
			position relative
			margin-left 37px
			font-size 22px
			line-height 1.36
			
			@media $xs
				margin-right 25px
				margin-bottom 7px
				margin-left 0
				font-size 18px
				line-height 1.45
		
		.Thesis_number
			position absolute
			top -23px
			left -16px
			font-weight 900
			font-family Arial
			font-size 180px
			
			@media $xs
				top -12px
				right -20px
				left auto
				font-size 120px
		
		.Thesis_text
			position relative
			line-height 1.625
			
			@media $xs
				font-size 16px