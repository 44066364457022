.FormSuccess
	width 570px
	max-width 100%
	text-align center
	font-family "Decima Nova Pro", sans-serif
	color rgba($black, 77%)
	
	&_icon
		margin-bottom 21px
		
		@media $xs
			width 55px
			margin-right auto
			margin-left auto
	
	&_title
		margin-bottom 0.37em
		font-size 35px
		font-weight bold
		
		@media $xs
			font-size 28px
	
	&_text
		font-size 19px
		
		@media $xs
			font-size 16px
		
	&_par
		margin-bottom 1em
		
		&-small
			font-size 18px
			
			@media $xs
				font-size 15px
	
	&_link
		color rgba(#235ea1, 77%)
		text-decoration underline
		
		&:hover
			text-decoration none