.UserIcons
	&_list
		display flex
		align-items center
		
		&:empty
			margin-left 30px
	
	&_item
		margin-right 7px
		
		&:last-child
			margin-right 0
		
		&-lightningFill
			width 7px
			color $blue3
		
		&-check, &-cup
			color $blue2
		
		&-shield
			color #ED0000
	
	&-companyComment
		.UserIcons_item
			margin-right 9px
			
			&:last-child
				margin-right 0