.JobCharacteristicsGroup
	padding-bottom 13px
	border-bottom 1px solid #EBF0F2
	
	&_header
		display flex
		justify-content space-between
		align-items center
		margin-bottom 9px
		font-size 16px
		line-height 22px
	
	&_title
		font-weight 500
	
	&_salary
		font-weight 600
	
	&_item:not(:last-child)
		margin-bottom 5px