.NotificationMenu
	position relative
	
	&_icon
		display block
		width 20px
		color $blue3
		
		@media $xs
			width 21px
			
		& > .SvgIco
			width 16.76px
			
			@media $xs
				width 17.8px
			
			&-bellCheck
				width 20.37px
				
				@media $xs
					width 20.65px
		
		.SvgIco_path-check
			fill $orange
		
		&-small
			width 18px
			
			& > .SvgIco
				width 14.76px
				
				&-bellCheck
					width 17.94px
	
	&_wrapper
		display none
		position absolute
		z-index 10
		top calc(100% + 12px)
		left -20px
		width 189px
		border-radius 2px
		border 1px solid $grey
		box-shadow 0px 4px 10px rgba(100, 124, 151, 0.1)
		background $white
		text-align left
		
		@media $smMinus
			right -78px
			left auto
		
		@media $xs
			width 230px
		
		&:before
			content ""
			position absolute
			top -12px
			left 20px
			width 16px
			height 10px
			background url('../img/corner_shadow.jpg') 0 0 no-repeat
			
			@media $smMinus
				right 92px
				left auto
			
			@media $xs
				display none
		
		&-opened
			display block
	
	&_link
		display flex
		align-items center
		padding 7px 10px 7px 19px
		color $blue3
		font-weight 500
		font-size 13px
		line-height 1.8461
		@extend .transition
		
		@media $xs
			font-size 16px
			color $black
		
		& > .SvgIco
			margin-right 10px
			// color $blue3
		
			.SvgIco_path-check
				fill $orange
		
		&-active
			background #f5f5f5
			color $blue