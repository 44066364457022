.VacancyImages
	width 720px
	max-width 100%
	
	@media $xs
		width auto
		max-width none
	
	&_gallery
		margin-bottom 12px
	
	&_gallerySlide
		@media $xs
			width 300px
		
		&-video
			&::after
				content ""
				position absolute
				top 0
				right 0
				bottom 0
				left 0
				width 50px
				height 50px
				margin auto
				border-radius 50%
				background $white url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 477.867 477.867'%3E%3Cpath d='M238.933 0C106.974 0 0 106.974 0 238.933s106.974 238.933 238.933 238.933 238.933-106.974 238.933-238.933C477.726 107.033 370.834.141 238.933 0zm100.624 246.546a17.068 17.068 0 01-7.662 7.662v.085L195.362 322.56c-8.432 4.213-18.682.794-22.896-7.638a17.061 17.061 0 01-1.8-7.722V170.667c-.004-9.426 7.633-17.07 17.059-17.075a17.068 17.068 0 017.637 1.8l136.533 68.267c8.436 4.204 11.867 14.451 7.662 22.887z'/%3E%3C/svg%3E") 0 0 / cover no-repeat
				
				@media $smPlus
					width 70px
					height 70px
		
	&_galleryImg
		border-radius 8px
		background-size cover
		
		&::after
			content ""
			display block
			padding-top 66.4%
			
			@media $xs
				padding-top 66.5%
	
	&_thumbSlide
		width 120px
		
		&-video
			&::after
				content ""
				position absolute
				top 0
				right 0
				bottom 0
				left 0
				width 34px
				height 34px
				margin auto
				border-radius 50%
				background $white url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 477.867 477.867'%3E%3Cpath d='M238.933 0C106.974 0 0 106.974 0 238.933s106.974 238.933 238.933 238.933 238.933-106.974 238.933-238.933C477.726 107.033 370.834.141 238.933 0zm100.624 246.546a17.068 17.068 0 01-7.662 7.662v.085L195.362 322.56c-8.432 4.213-18.682.794-22.896-7.638a17.061 17.061 0 01-1.8-7.722V170.667c-.004-9.426 7.633-17.07 17.059-17.075a17.068 17.068 0 017.637 1.8l136.533 68.267c8.436 4.204 11.867 14.451 7.662 22.887z'/%3E%3C/svg%3E") 0 0 / cover no-repeat
	
	&_thumbImg
		border-radius 8px
		background-size cover
		cursor pointer
		
		&::after
			content ""
			display block
			padding-top 65.2%
	
	&_prev,
	&_next
		top 0
		bottom 0
		width 50px
		height auto
		margin-top 0
		border none
		outline none
		background-color transparent
		background-position center center
		background-repeat no-repeat
		@extend .transition
		
		&::after
			display none
		
		&:hover
			background-color rgba(0, 0, 0, .3)
	
	&_prev
		left 0
		border-top-left-radius 8px
		border-bottom-left-radius 8px
		background-image url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14'%3E%3Cstyle%3E%3C/style%3E%3Cg id='Фото'%3E%3Cg id='Scroll icon копия'%3E%3Cpath id='Фигура 42 копия' d='M3.38 8.01l4.66 4.72L6.81 14 0 6.99l6.81-7 1.23 1.27L3.35 6H14v2l-10.62.01z' fill='%23fff'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")
	
	&_next
		right 0
		border-top-right-radius 8px
		border-bottom-right-radius 8px
		background-image url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14'%3E%3Cstyle%3E%3C/style%3E%3Cg id='Фото'%3E%3Cg id='Scroll icon копия'%3E%3Cpath id='Фигура 42' d='M10.62 5.99L5.96 1.27 7.19 0 14 7.01l-6.81 7-1.23-1.27L10.65 8H0V6l10.62-.01z' fill='%23fff'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")