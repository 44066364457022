.MoreLink
	position relative
	padding-right 19px
	padding-bottom 4px
	color $blue
	
	&:before
		content ""
		position absolute
		right 19px
		bottom 0
		left 0
		height 1px
		background $blue
		opacity 0.2
		@extend .transition
	
	&:after
		content ""
		position absolute
		top 5px
		right 0
		width 12px
		height 7px
		background url('../img/arrow_down.svg') 0 0 no-repeat
		transform rotate(-90deg)
	
	&:hover:before
		background transparent