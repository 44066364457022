.hidden-xs
	@media $xs
		display none!important
.hidden-xxs
	@media $xxs
		display none!important
.hidden-xxsPlus
	@media $xxsPlus
		display none!important
.hidden-smPlus
	@media $smPlus
		display none!important
.hidden-sm
	@media $sm
		display none!important
.hidden-smMinus
	@media $smMinus
		display none!important
.hidden-md
	@media $md
		display none!important
.hidden-mdMinus
	@media $mdMinus
		display none!important
		
.hidden
	display none!important

.transition
	transition all .2s ease-in-out

.Medium
	font-weight 500

.Semibold
	font-weight 600

.Bold
	font-weight 700

.Red
	color $red

.Burgundy
	color $burgundy

.Black
	color $black

.Green
	color $green

.Green2
	color $green2

.Blue
	color $blue

.Orange
	color $orange

.yellowBg
	background yellow

.Underline
	text-decoration underline
	
	&:hover
		text-decoration none