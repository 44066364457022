.Icons	
	&_list
		display flex
		align-items center
	
	&_item
		margin-right 25px
		
		&:last-child
			margin-right 0
	
	&_link
		display block
		color $black
		
		&-search
			width 16px
			
			@media $xs
				width 17px
	
	&_img
		display block
		width 33px
		border-radius 50%
	
	&-vacanciesHeader
		.Icons_item
			// margin-right 15px
			margin-right 19px
			
			&:last-child
				margin-right 0