.ActionMenu
	&_link
		display flex
		align-items center
		padding 16px 0
		font-weight 500
		font-size 16px
		
		& > .SvgIco
			width 20px
			margin-right 20px
			color $blue
			
		&-copied
			color $green
			
			& > .SvgIco
				color $green