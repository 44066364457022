.Tabs
	&_list
		display flex
		margin-bottom 59px
		
		@media $xs
			margin-bottom 35px
	
	&_item
		display flex
		justify-content center
		align-items center
		flex auto 1 1
		padding-top 10px
		padding-bottom 10px
		border-bottom 1px solid rgba($black, 22%)
		font-size 25px
		font-family 'Decima Nova Pro', sans-serif
		@extend .transition
		color rgba($black, 33%)
		text-align center
		cursor pointer
		
		@media $xs
			padding-top 7px
			padding-bottom 7px
			font-size 18px
		
		&:hover, &-active
			border-bottom 2px solid #81BE1F
			color #81BE1F
		
		&-active
			font-weight 700
			
		&-gutterRight
			padding-right 14px
		
		&-gutterLeft
			padding-left 14px
	
	&_content
		display none
		
		&-publicFiles,
		&-privateFiles
			border-radius 16px
		
		&-publicFiles
			background $lightGreen
			
		&-privateFiles
			background $pink
	
	&_infoBlocks
		&-launch
			padding-top 45px
			
			@media $xs
				padding-top 0
	
	&_infoBlock
		margin-bottom 70px
		
		@media $xs
			margin-bottom 45px
		
		&:last-child
			margin-bottom 0
		
		&-inverse
			@media $xs
				margin-bottom 30px
	
	&_count
		position relative
		bottom 4px
		left 4px
		font-size 12px
	
	&_subTitle
		margin-bottom 10px
		font-weight 600
		font-size 15px
		line-height 1.67
	
	&-profileMenu
		.Tabs_list
			margin-bottom 10px
			
		.Tabs_item
			flex-grow 0
			border-bottom-color $grey
			font-weight 500
			font-size 13px
			font-family $baseFont
			color $blue3
			
			&:hover, &-active
				border-bottom 1px solid $blue
				color $blue
			
			&-active
				font-weight 500
	
	&-profileDropdownMenu
		.Tabs_list
			margin-bottom 24px
			
		.Tabs_companyBrief
			margin-bottom 12px
	
	&-companySettings
		.Tabs_list
			position relative
			margin-bottom 30px
			
			@media $xs
				margin-bottom 26px
			
			&:after
				content ""
				position absolute
				right 0
				bottom 0
				left 0
				height 1px
				background #EBF0F2
			
		.Tabs_item
			position relative
			margin-right 40px
			border-bottom none
			font-size 15px
			
			@media $xs
				margin-right 0
				flex-basis 0
				flex-grow 1
				padding-top 10px
				padding-bottom 10px
			
			img
				margin-right 8px
			
			&:after
				content ""
				position absolute
				z-index 1
				right 0
				bottom 0
				left 0
				height 1px
				background #EBF0F2
				@extend .transition
			
			&:last-child
				margin-right 0
			
			&:hover, &-active
				border-bottom none
			
			&-active
				&:after
					background $blue
	
	&-addTestimonial
		.Tabs_list
			margin-bottom 17px
			
			@media $xs
				display none
		
		.Tabs_content
			@media $xs
				display block
				margin-bottom 20px
				padding 10px 20px 20px
				
				&:last-child
					margin-bottom 0