.Dialogue
	display flex
	flex-direction column
	justify-content space-between
	
	&_headerWrapper, &_headerLeft, &_headerRight
		display flex
		align-items center
	
	&_header
		border-bottom 1px solid #ebf0f2
	
	&_headerWrapper
		justify-content space-between
		padding-top 2px
		padding-bottom 9px
		
		@media $xs
			padding 4px 20px 8px
	
	&_headerRight			
		@media $xs
			align-self flex-start
	
	&_btnBack
		margin-top -2px
		margin-right 25px
	
	&_actionIcons
		display flex
	
	&_actionIcon
		&-search
			margin-top 3px
			
		&-dots
			width 16px
			margin-left 13px
			color $blue2
	
	&_dotsMenu
		margin-left 13px
	
	&_footer
		display flex
		flex-direction column
		height 71vh
		
		@media $xs
			height 78vh
	
	&_conversationChoice
		margin-top 160px
	
	&_messages
		position relative
		margin-bottom 20px
		padding-top 17px
		overflow-y auto
		
		&::-webkit-scrollbar
			width 3px
		
		&::-webkit-scrollbar-track
			border-radius 3px
		
		&::-webkit-scrollbar-thumb
			border-radius 3px
			background #DFE4E6
		
		@media $xs
			padding-top 40px
			padding-right 17px
			padding-left 20px
		
		&:before
			content ""
			flex auto 1 1
	
	&_message
		margin-bottom 19px
		
		@media $xs
			margin-bottom 14px
		
		&:last-child
			margin-bottom 0
	
	&_date
		margin-top 20px
		margin-bottom 11px
		font-weight 500
		font-size 13px
		color $blue2
		text-align center