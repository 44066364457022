.Rating
	display flex
	align-items center
	
	&_wrapperLink,
	&_wrapper
		display flex
		align-items baseline
	
	&_icon
		width 7px
		margin-top -2px
		margin-right 5px
		color $grey3
	
	&_number
		font-size 13px
		color $blue2
		
		&-profileSettings
			@media $xs
				font-size 14px
	
	&_max,
	&_label
		flex-shrink 0
		
	&_max
		margin-right 7px
	
	&_label
		color $blue3
	
	&_dropdown
		position absolute
		z-index 10
		top calc(100% + 9px)
		left -21px
		width 440px
		padding 14px 22px
		border-radius 3px
		background $white
		box-shadow 0 0 5px 0 rgba($black, 0.12)
		cursor text
		visibility hidden
		opacity 0
		@extend .transition
		
		@media $xs
			position fixed
			z-index 1001
			top 50%
			left 50%
			max-width calc(100% - 40px)
			padding 22px 27px 19px 25px
			transform translate(-50%, -50%)
			box-shadow none
			border-radius 5px
		
		&:hover
			@media $smPlus
				visibility visible
				opacity 1
		
		&-visible
			visibility visible
			opacity 1
	
	&_closeDropdown
		position absolute
		top 16px
		right 16px
		width 14px
		height 14px
	
	&-bordered
		padding 9px 13px
		border 1px solid #f0f3f6
		border-radius 18px
	
	&-blue
		.Rating_icon
			color $blue2
			
	&-green
		.Rating_icon
			color $green
			
	&-userInfo
		.Rating_icon
			width 11px
			margin-right 5px
			color $orange
	
	&-mobNav
		.Rating_icon
			width 8px
			margin-right 5px
		
		.Rating_number
			font-size 12px
	
	&-center
		justify-content center
	
	&-company,
	&-companyComment,
	&-companyBlock,
	&-vacancyFull
		.Rating_icon
			align-self center
			margin-right 5px
			color $brown
		
		.Rating_number
			margin-right 5px
			color $black
			line-height 1
	
	&-company,
	&-vacancyFull
		align-items flex-end
		font-weight 500
		
		.Rating_icon
			width 18px
			
			@media $xs
				margin-top 0
		
		.Rating_number
			font-size 26px
			
			@media $xs
				font-size 36px
		
		.Rating_max
			@media $xs
				margin-right 10px
		
		.Rating_label
			@media $xs
				font-size 15px
	
	&-companyComment,
	&-companyBlock
		position relative
		display inline-flex
		
		&:after
			content ""
			position absolute
			right 0
			bottom 5px
			width 4px
			height 3px
			background url('../img/arrow_black_bottom.svg') 0 0 no-repeat
		
		.Rating_number
			font-size 18px
		
		.Rating_max
			font-size 13px
	
	&-companyBlock
		&:after
			bottom 7px
			right -7px
			
			@media $xs
				display none
			
		.Rating_icon
			width 9px
			margin-right 7px
			
			@media $xs
				margin-right 5px
		
		.Rating_number
			@media $xs
				font-size 16px
		
		.Rating_label
			margin-top 2px
			font-size 13px
			
			@media $xs
				margin-top 0
				font-size 13px
		
		.Rating_max
			@media $xs
				margin-right 5px
	
	&-dropdown
		position relative
		cursor pointer
			
		&:hover
			@media $smPlus
				.Rating_dropdown
					visibility visible
					opacity 1
	
	&-companyInfo
		font-weight 500
		
		.Rating_icon
			color $brown
		
		.Rating_number
			margin-right 3px
			color $black
			font-size 15px
		
		.Rating_max
			margin-top 2px
			margin-right 0
			font-size 12px
	
	&-similarTopics
		align-items baseline
		font-size 13px
		
		.Rating_icon
			align-self center
			width 12px
			margin-right 4px
			color $brown
		
		.Rating_number
			margin-right 3px
			color $black
			font-size 15px
		
		.Rating_max
			margin-right 4px
	
	&-balance
		font-weight 500
		
		.Rating_icon
			color $brown
		
		.Rating_number
			margin-right 3px
			font-size 16px
			line-height 1.375
			color $black
		
		.Rating_max
			margin-right 3px
		
		.Rating_label
			margin-bottom 2px
			font-size 13px
	
	&-companyReviews
		font-weight 500
		
		.Rating_icon
			width 9px
			color $brown
		
		.Rating_number
			font-size 16px
			color $black
		
		.Rating_max
			margin-right 4px
			margin-left 3px
			font-size 13px
	
	&-companyHeader
		align-items baseline
		
		.Rating_icon
			align-self center
			width 9px
			margin-top 0
			margin-right 5px
			color $brown
			
			@media $xs
				width 11px
				margin-right 4px
		
		.Rating_number
			margin-right 4px
			font-size 19px
			color $black
			
			@media $xs
				font-size 15px
		
		.Rating_max
			margin-right 7px
			font-size 13px
			
			@media $xs
				margin-right 4px
		
		.Rating_label
			font-size 13px
		
		.Rating_dropdown
			@media $smPlus
				top calc(100% + 5px)
				width 440px
				padding 14px 20px
			
			@media $xs
				padding 22px 22px 19px
	
	&-vacancy
		align-items baseline
		font-weight 600
		
		.Rating_icon
			width 10px
			margin-right 5px
			color $brown
			
			@media $xs
				align-self center
				flex-shrink 0
				margin-top 0
		
		.Rating_number
			margin-right 3px
			color $black
			font-size 20px
		
		.Rating_max
			margin-right 0
			font-size 13px
	
	&-companyBrief
		align-items baseline
		font-weight 600
		
		.Rating_icon
			color $brown
		
		.Rating_number
			margin-right 4px
			font-size 15px
			color $black
		
		.Rating_max
			margin-right 5px
			font-weight 600
			font-size 8px
		
		.Rating_label
			font-size 8px
	
	&-orange
		.Rating_icon
			color $orange
	
	&-companyComment
		.Rating_dropdown
			@media $xs
				padding 22px 22px 19px
	
	&-vacancyFull
		width 100%
		
		.Rating_icon
			transform scale(0.556)
			margin-top 2px
			margin-right 0
			
			@media $xs
				margin-right 4px
				transform scale(0.6665)
		
		.Rating_number
			font-size 28px
			
			@media $xs
				font-size 32px
		
		.Rating_label
			position relative
			right -4px
			align-self flex-start
			margin-left auto
			color #999
			font-weight normal
			font-size 13px
			
			@media $xs
				align-self flex-end
				right 0
				margin-left 0
				color $blue3
				font-weight 500
				font-size 15px
	
	&-vacancyBlock
		flex-direction column
		
		.Rating_wrapper
			margin-bottom 6px
		
		.Rating_icon
			margin-top -2px
		
		.Rating_max
			margin-right 0
			font-size 13px
		
		.Rating_label
			align-self initial
		
		.Rating_number
			margin-right 2px
			font-size 16px
	
	&-employerBlock
		align-items baseline
		font-weight 500
		
		.Rating_icon
			flex-shrink 0
			width 8px
			margin-right 2px
			
			@media $smPlus
				margin-right 4px
		
		.Rating_number
			margin-right 3px
			font-size 14px
			color $black
			
			@media $smPlus
				margin-right 5px
		
		.Rating_max,
		.Rating_label
			font-size 12px
		
		.Rating_max
			margin-right 3px
	
	&-parentComment
		align-items baseline
		
		.Rating_icon
			color $blue2
	
	&-companyBehalf
		align-items baseline
		
		.Rating_number,
		.Rating_max
			font-weight 600
			
		.Rating_number
			margin-right 3px
			font-size 14px
			color $black
		
		.Rating_max,
		.Rating_label
			font-size 13px
		
		.Rating_max
			margin-right 3px