.ProfileBlock
	display flex
	align-items center
	
	&_notifications
		margin-top 6px
		margin-right 37px
		
		@media $xs
			margin-right 0
	
	&_rating
		margin-right 17px