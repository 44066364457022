.Filter
	&_search
		margin-bottom 22px
		
	&_resultsText
		margin-bottom 12px
		padding-left 4px
		color $blue2
		font-weight 600
		font-size 13px
		
	&_main
		display flex
		flex-wrap wrap
		justify-content space-between
		align-items center
		min-height 67px
		padding-top 18px
		padding-bottom 22px
		border-top 1px solid #ebf0f2
		border-bottom 1px solid #ebf0f2
		
		@media $xs
			flex-direction row-reverse
			padding-top 0
			padding-bottom 7px
			border-top none
	
	&_left
		@media $xs
			padding-right 4px
			padding-bottom 5px
	
	&_right
		@media $xs
			padding-left 5px
	
	&_wrapper
		@media $xs
			display none
			position fixed
			z-index 10
			top 0
			left 0
			width 100%
			height 100%
			padding-bottom 23px
			background $white
			overflow scroll
			
			&-opened
				display block
	
	&_mobileHeader
		@media $xs
			margin-bottom 10px
	
	&_items
		display flex
		align-items center
		padding-left 3px
		
		@media $xs
			flex-direction column
			align-items stretch
	
	&_item
		margin-right 26px
		
		@media $mdMinus
			margin-right 12px
		
		@media $smMinus
			margin-right 22px
		
		@media $smPlus
			&-apply
				margin-right 0
		
		@media $xs
			margin-right 0
			
			&-staticDropdownxs
				padding 12px 17px
				border-bottom 1px solid $grey
				
				.fs-dropdown:before
					display none
			
			&-apply
				margin-top 39px
				margin-bottom 23px
				padding-right 20px
				padding-left 17px
			
			&-clearBtn
				text-align center
		
		&:last-child
			margin-right 0
		
		&-clearBtn
			@media $smPlus
				margin-left 4px
	
	&_titleWrapper
		display flex
		align-items baseline
		
		@media $md
			align-items center
	
	&_resultsTitle
		margin-right 7px
		font-weight 600
		font-size 24px
		
		@media $xs
			margin-right 0
			font-size 19.36px
			line-height 24.2px
	
	&_results
		font-size 16px
		line-height 24.2px
		color $blue2
		
		@media $xs
			font-size 14px
			line-height 24px
		
		@media $md
			padding 2px 8px
			color #808080
			background #F2F6FA
			border-radius 4px
	
	&_title
		font-weight 500
	
	&_titleText
		margin-left 10px
	
	&_tags
		display flex
		align-items center
		margin-top 17px
	
	&_tag
		margin-right 10px
		
		&:last-child
			margin-right 0
	
	&_clearLink
		margin-left 5px
		color $blue3
	
	&_applyBtn
		@media $smPlus
			margin-left 15px
	
	&_linkWrapper
		display flex
		align-items baseline
	
	&_findJobBtn
		position fixed
		z-index 1000
		right 20px
		bottom 64px
	
	&_link
		margin-right 7px
		font-weight 500
		font-size 15px
		color $blue
	
	&_circle
		display inline-flex
		align-items center
		justify-content center
		width 16px
		height 16px
		border-radius 50%
		background $blue
		color $white
		font-weight 500
		font-size 11px
	
	&-results
		padding-top 5px
		
		@media $xs
			display flex
			flex-direction column
			padding-top 25px
		
		.Filter_search
			margin-bottom 25px
			
			@media $xs
				margin 0 5px 12px
		
		.Filter_resultsText
			@media $xs
				order -1
				margin-bottom 19px
				padding-left 5px
				font-size 14px
				line-height 1.4286
	
	&-vacancies,
	&-vacanciesResults
		@media $smMinus
			padding-right 15px
			padding-left 15px
			
		@media $smPlus
			.Filter_main
				min-height 45px
				border-top none
				padding-top 0
				padding-bottom 0
			
			.Filter_item
				margin-right 42px
				
				&:last-child
					margin-right 0
			
			.Filter_search
				margin-bottom 32px
		
			.fs-label-wrap .fs-label
				font-size 14px
	
	&-vacanciesResults
		@media $smPlus
			.Filter_main
				align-items baseline
				min-height 0
				padding-top 3px
				// padding-bottom 10px
				padding-bottom 20px
			
			.fs-label-wrap .fs-label
				font-size 13px

			.Filter_item:nth-last-child(2)
				margin-right 0
		
		@media $xs
			display flex
			flex-direction column

			.Filter_main
				flex-direction row
				align-items center
				min-height 0
				margin 0 -20px 0
				padding-right 24px
				padding-left 23px
				border-bottom none
			
			.Filter_left
				padding-right 0
				padding-bottom 12px
			
			.Filter_resultsTitle
				margin-left 2px
			
			.Filter_right
				display flex
				justify-content space-between
				align-items end
				width 100%
				padding-left 3px
			
			.Filter_linkWrapper
				align-items center
			
			.Filter_link
				margin-right 4px
				font-size 14px
			
			.Filter_toggleLink
				margin-top 5px
				margin-right 5px
			
			.Filter_search
				margin-right 5px
				margin-left 5px