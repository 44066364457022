.UserPhoto
	display inline-block
	
	&_link
		display flex
		align-items center
	
	&_img
		display block
		width 50px
		border-radius 50%
	
	&-online
		position relative
		
		&:after
			content ""
			position absolute
			right 0
			bottom 0
			width 13px
			height 13px
			background $green
			border 2px solid $white
			border-radius 50%
			
			@media $xs
				width 11px
				height 11px
		
		&.UserPhoto-fullMessage:after
			width 8px
			height 8px
			border-width 1px
	
	&-glow
		.UserPhoto_img
			box-shadow 0 0 16px $orange
			
			@media $xs
				box-shadow 0 0 8px $orange
	
	&-commentCompany
		.UserPhoto_img
			width 40px
	
	&-small
		.UserPhoto_img
			width 34px
	
	&-roundedSquare
		.UserPhoto_img
			border-radius 2px
	
	&-profileBlock
		position relative
		
		.UserPhoto_link
			column-gap 5px
			
			&::after
				content ""
				width 7px
				height 6px
				margin-top 3px
				background url("data:image/svg+xml,%3Csvg viewBox='0 0 7 6' width='7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.633 5.099 6.965.267a.179.179 0 0 0 .017-.184.175.175 0 0 0-.15-.083H.167a.175.175 0 0 0-.15.083C0 .117 0 .133 0 .167c0 .033.017.066.033.1l3.333 4.832c.033.05.083.067.133.067s.1-.034.134-.067z' fill='%23ebeff3'/%3E%3C/svg%3E") 0 0 no-repeat
				
				@media $xs
					width 6px
					height 4px
					background-image url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6.004' height='3.986' viewBox='0 0 6.004 3.986'%3E%3Cpath data-name='Многоугольник 1' d='M3.005 3.986.005 0h6Z' fill='%23647c97'/%3E%3C/svg%3E")
		
		@media $xs
			// width 32px
			width 43px
		
		.UserPhoto_img
			width 40px
			
			@media $xs
				width 32px
		
		.Dropdown
			@media $xs
				top -18px
				right -18px
				width 240px
	
	&-sidebarBlock,
	&-fullMessage
		.UserPhoto_img
			width 30px
	
	&-fullMessage
		.UserPhoto_img
			@media $xs
				width 36px
	
	&-frontPage,
	&-search,
	&-ratingDropdown
		@media $xs
			.UserPhoto_img
				width 40px
	
	&-shortMessage,
	&-commentSwitch
		.UserPhoto_img
			width 40px
	
	&-commentSwitch
		.UserPhoto_link
			gap 6px
			
			&::after
				content ""
				width 6px
				height 4px
				background url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6.004' height='3.986' viewBox='0 0 6.004 3.986'%3E%3Cpath data-name='Многоугольник 1' d='M3.005 3.986.005 0h6Z' fill='%23647c97'/%3E%3C/svg%3E") 0 0 no-repeat
		
		.UserPhoto_img
			@media $smPlus
				width 34px
	
	// &-parentComment
	// 	.UserPhoto_img
	// 		width 40px
	
	&-companyBehalf
		.UserPhoto_img
			width 56px
			// width auto
			border-radius 4px
		
		&.UserPhoto-online::after
			right -5px
			bottom -5px