.FormText
	width 100%
	height 36px
	border none
	border-bottom 1px solid $grey
	background $white
	outline none
	font-size 16px
	line-height 1
	@extend .transition
	autofill($white)
	
	&:focus
		border-bottom-color $blue
	
	&-topicCreationForm,
	&-vacancyCreationForm
		height 54px
		padding-right 25px
		padding-left 25px
		border 1px solid $grey
		font-size 15px
		color $black
		
		@media $xs
			height 45px
			padding-right 10px
			padding-left 10px
			font-size 14px
		
		&:focus
			border-color $blue2
	
	&-profileSettings,
	&-jobFilter
		width 280px
		max-width 100%
		height 35px
		padding-right 10px
		padding-left 10px
		border 1px solid $grey
		font-size 14px
		+placeholder()
			color $blue2
		
		@media $xs
			width 100%
			height 45px
			border-radius 3px
			font-weight 500
			font-size 16px
		
		&:focus
			border-bottom-color $grey
	
	&-jobFilter
		width 100%
		height 40px
		border-radius 4px

		@media $xs
			font-size 14px
	
	&-bordered
		border 1px solid $grey
	
	&-login
		height 68px
		padding-right 24px
		padding-left 23px
		border 2px solid $grey
		border-radius 6px
		font-size 23px
		
		&:focus
			border-bottom-color $grey
	
	&-loginPopup
		height 34px
		padding-right 4px
		padding-left 4px
	
	&-authPage
		padding-right 4px
		padding-left 4px
		
		@media $xs
			height 45px
			padding-right 10px
			padding-left 10px
			border 1px solid $grey
			font-size 14px
			
			&:focus
				border-bottom-color $grey
	
	&-phone
		&:focus
			padding-left 17px
			
			@media $xs
				padding-left 23px
	
	&-phoneNotEmpty
		padding-left 17px
		
		@media $xs
			padding-left 23px
	
	&-passwordRecovery
		@media $xs
			height 45px
			padding-right 15px
			padding-left 15px
			border 1px solid $grey
			border-radius 3px
			
			&:focus
				border-bottom-color $grey
	
	&-uploadFiles
		&:focus
			border-bottom-color #ebf0f2