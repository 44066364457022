.CompanyBlock
	display flex
	align-items flex-start
	padding-bottom 25px
	border-bottom 1px solid #ebf0f2
			
	&_header
		display flex
		align-items center
		
		@media $xs
			margin-bottom 4px
	
	&_headerRight
		@media $xs
			margin-top -5px
	
	&_rating
		margin-left 6px
	
	&_logo
		flex auto 0 0
		width 100px
		margin-right 32px
		
		&-small
			width 50px
			margin-right 20px
	
	&_logoImg
		border-radius 2px
	
	&_photoWrapper
		margin-right 26px
	
	&_photo
		border-radius 8px
	
	&_mainWrapper
		flex auto 1 1
	
	&_name
		display block
		margin-top -9px
		margin-bottom 4px
		font-weight 500
		font-size 24px
		
		a
			&:hover
				text-decoration underline
	
	&_subtitle,
	&_mark
		font-size 13px
	
	&_activity,
	&_description
		font-size 14px
		line-height 1.6
	
	&_activity
		margin-bottom 6px
		margin-left 2px
		font-weight 500
	
	&_description
		margin-bottom 16px
		color #404040
	
	&_footer
		display flex
		justify-content space-between
		align-items center
	
	&_swiper
		margin 0 -20px 16px 0
		
		@media $xs
			margin-bottom 18px
	
	&_jobCharacteristics
		@media $xs
			margin-right 2px
	
	&_demands
		margin-bottom 11px
		font-size 13px
		line-height 1.8461
	
	&_demandsList
		display flex
		flex-wrap wrap
		
	&_demand
		&:nth-child(even)
			flex 30% 1 1
			
		&:nth-child(odd)
			flex 70% 1 1
	
	&_country
		margin-top 2px
		margin-right -3px
		color #666
		font-size 13px
	
	&_countryIcon
		margin-right 8px
	
	&_statIcons
		margin-right -4px
		
		@media $xs
			width 100%
	
	&_activities
		margin-left -2px
		
		@media $smPlus
			display flex
	
	&-dossiers
		@media $xs
			padding-bottom 18px
			
		.CompanyBlock_logo
			width 90px
			margin-right 28px
			
			@media $xs
				width 70px
				margin-right 15px
		
		.CompanyBlock_name
			margin-top -6px
			
			@media $xs
				margin-top 0
			
			@media $xs
				font-size 18px
				line-height 1.44
		
		.CompanyBlock_activity
			@media $xs
				width 320px
				margin-bottom 5px
				margin-left 0
				font-size 15px
				line-height 1.5
				white-space nowrap
				overflow hidden
				text-overflow ellipsis
		
		.CompanyBlock_description
			margin-bottom 18px
			
			@media $xs
				margin-bottom 8px
				font-size 15px
				line-height 1.5
		
		.CompanyBlock_country
			@media $xs
				margin-right 0
				font-size 14px
		
		.CompanyBlock_countryIcon
			@media $xs
				margin-right 5px

	&-vacancy
		border-bottom 1px solid #ebf0f2
		
		@media $smMinus
			padding-right 15px
			padding-left 15px
		
		@media $xs
			padding-left 20px
			border-bottom 8px solid $lightGrey
		
		.CompanyBlock_header
			justify-content space-between
			
			@media $xs
				max-width calc(100vw - 30px)
				margin-bottom 16px
			
			@media $smPlus
				margin-top -6px
				margin-bottom 14px
		
		.CompanyBlock_headerLeft
			@media $xs
				overflow hidden
		
		.CompanyBlock_headerRight
			@media $xs
				flex-shrink 0
				margin-top -4px
				// margin-right -2px
				margin-right 6px
		
		.CompanyBlock_name
			margin-top 0
			margin-bottom 1px
			font-weight 600
			font-size 20px
			line-height 1.2
			
			@media $xs
				overflow hidden
				text-overflow ellipsis
				white-space nowrap
				margin-right 15px
			
			a:hover
				text-decoration none
		
		.CompanyBlock_subtitle,
		.CompanyBlock_mark
			color #999
		
		.CompanyBlock_mark
			margin-right 2px
			text-align right
			
			@media $xs
				margin-right 0
		
		.CompanyBlock_activity
			@media $smPlus
				flex-shrink 1
				flex-grow 1
				
				&:first-child
					flex-basis 34%
		
		.CompanyBlock_statIcons
			margin-right 0
			
			@media $xs
				// margin-top 25px
				margin-top 20px
		
		.CompanyBlock_country
			margin-right 0
			line-height 20px
			
			@media $xs
				display flex
				align-items baseline
				margin-top 10px
				margin-right 9px
				// margin-bottom 20px
			
			&:last-child
				margin-bottom 0
		
		.CompanyBlock_countryIcon
			margin-right 5px
			
			@media $smPlus
				margin-right 10px
		
		.CompanyBlock_footer
			@media $smPlus
				margin-top 30px