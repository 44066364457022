.OfferBlock
	width 300px
	padding 23px 26px
	border-radius 4px
	background-color $lightGrey
	
	&_title
		margin-bottom 4px
		color $blue2
		font-weight 500
		font-size 24px
	
	&_text
		margin-bottom 14px
		font-size 14px
		line-height 22px
	
	&-findJob
		background-image url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='57.97' height='50.724'%3E%3Cpath data-name='Фигура 13' d='m54.941 25.762-20.685 4.672v4.272a1.253 1.253 0 0 1-1.318 1.335h-7.9a1.253 1.253 0 0 1-1.318-1.335v-4.272L3.035 25.762a4 4 0 0 1-3.031-3.871v-8.543a3.908 3.908 0 0 1 3.953-4.005h50.065a3.908 3.908 0 0 1 3.953 4v8.544a4 4 0 0 1-3.034 3.875Zm-28.59 7.609h5.27v-5.34h-5.27Zm28.985-20.023a1.253 1.253 0 0 0-1.318-1.335H3.953a1.252 1.252 0 0 0-1.317 1.335v8.543a1.3 1.3 0 0 0 1.054 1.335l20.026 4.538v-1.068a1.253 1.253 0 0 1 1.318-1.335h7.9a1.253 1.253 0 0 1 1.318 1.335v1.068l20.026-4.538a1.3 1.3 0 0 0 1.054-1.335ZM38.209 6.673a1.253 1.253 0 0 1-1.318-1.335V4.004a1.253 1.253 0 0 0-1.318-1.335H22.398a1.253 1.253 0 0 0-1.318 1.335v1.335a1.318 1.318 0 1 1-2.635 0V4.004a3.907 3.907 0 0 1 3.952-4h13.174a3.907 3.907 0 0 1 3.953 4v1.335a1.253 1.253 0 0 1-1.316 1.335ZM2.637 28.03a1.253 1.253 0 0 1 1.317 1.335v17.353a1.253 1.253 0 0 0 1.318 1.335h47.43a1.253 1.253 0 0 0 1.317-1.335V29.366a1.318 1.318 0 1 1 2.635 0v17.353a3.908 3.908 0 0 1-3.953 4.005H5.271a3.908 3.908 0 0 1-3.952-4.005V29.366a1.253 1.253 0 0 1 1.317-1.335Z' fill='%23edf1f2'/%3E%3C/svg%3E")
		background-position calc(100% - 23px) calc(100% - 19px)
		background-repeat no-repeat