.DateSortTags
	display none
	
	&_list
		display flex
		flex-wrap wrap
	
	&_item
		margin-right 11px
		margin-bottom 6px
		
		&:last-child
			margin-right 0
	
	&-visible
		display block