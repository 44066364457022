.AdvantagesRating
	&_header
		display flex
		align-items center
		margin-bottom 20px
		padding-left 5px
	
	&_photoWrapper
		flex-shrink 0
		flex-basis 46px
		margin-right 18px
		
		@media $xs
			margin-right 15px
	
	&_photo
		display block
		border-radius 2px
	
	&_title
		font-weight 600
		font-size 18px
		line-height 1.45
	
	&_subTitle
		font-weight 500
		font-size 14px
		color $blue3
		
	&_item
		display flex
		align-items baseline
		margin-bottom -1px
		line-height 26px
		
		@media $xs
			align-items flex-start
			margin-bottom 10px
			padding-bottom 10px
			border-bottom 1px solid #ebf0f2
			
			&:last-child
				margin-bottom 0
				padding-bottom 0
				border-bottom none
		
		&:last-child
			margin-bottom 0
		
	&_icon
		width 10px
		margin-right 6px
		color $brown
		
		@media $xs
			flex-shrink 0
			width 13px
			margin-top 0px
			margin-right 4px
	
	&_numberWrapper
		display flex
		align-items baseline
		width 53px
		margin-right 7px
		
		@media $xs
			flex-direction column
			align-items flex-end
			width 40px
			margin-right 15px
	
	&_mark
		width 24px
		margin-right 5px
		font-size 16px
		text-align right
		
		@media $xs
			width auto
			margin-right 0
			margin-bottom 1px
			font-weight 500
			font-size 24px
		
		&-single
			margin-right 9px
			
			@media $xs
				width 40px
				margin-right 15px
	
	&_reviews
		font-size 11px
		color $blue3
		
		@media $xs
			font-size 13px
			line-height normal
	
	&_text
		font-size 13px
		color #404040
		
		@media $xs
			font-size 15px
			line-height 1.467
			color $black