.SocIcon
	display inline-grid
	justify-items center
	
	&_text
		margin-top 6px
		font-weight 500
		font-size 13px
	
	& > svg
		width 40px
	
	&-contactsBlock
		& > svg
			width 47px
	
	&-tg
		color #2AABEE
	
	&-viber
		color #665CAC
	
	&-phone
		color $green2
		
	&-phone
		color $green2
	
	&-email
		color #F89333