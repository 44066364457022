.Application
	position relative
	padding 22px 10px 25px 25px
	border 1px solid #ebf0f2
	border-radius 5px
	background $lightGrey
	
	&_close
		position absolute
		top 6px
		right 7px
		width 18px
		opacity .2
	
	&_title
		margin-bottom 11px
		font-weight 600
		font-size 24px
		color $blue2
	
	&_text
		margin-bottom 19px
		font-size 15px
		line-height 1.53
	
	&_link
		&:first-child
			margin-right 18px