.Ratings
	&_title
		margin-bottom 28px
		font-weight 600
		font-size 18px
		line-height 1.389
		
		@media $xs
			margin-bottom 18px
	
	&_item
		margin-bottom 30px
		
		@media $xs
			margin-bottom 32px
		
		&:last-child
			margin-bottom 0