.SuccessfulAuth
	text-align center
	
	&_imgWrap
		margin-bottom 60px
	
	&_img
		width 90px
	
	&_text
		color $blue2
		font-weight 500
		font-size 18px
		
		p
			margin-bottom 20px
			
			&:last-child
				margin-bottom 0