.FieldAddition
	padding 0 20px
	
	&-popup
		@media $xs
			height 100%
			width 100%
			padding-top 77px
			padding-bottom 37px
	
	&-altAddress
		@media $xs
			padding-top 50px