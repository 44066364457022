.Ban
	padding-top 50px
	font-weight 500
	text-align center
	color $blue2
	
	@media $xs
		padding-top 25px
	
	&_icon
		margin-bottom 36px
		
		@media $xs
			margin-bottom 20px
			
			svg
				width 85px
	
	&_title
		margin-bottom 34px
		font-size 21px
		line-height 1.23809
		
		@media $xs
			margin-bottom 20px
			font-size 18px
	
	&_text
		margin-bottom 54px
		font-size 16px
		line-height 2.25
		
		@media $xs
			margin-bottom 30px
			font-size 14px
			line-height 2