.Note
	padding 1.167em 1.56em
	border-left 2px solid $blue2
	background $lightGrey
	font-size 18px
	line-height 1.56
	
	@media $xs
		padding-top .9375em
		padding-bottom 1.0375em
		font-size 16px
		line-height 1.5625
	
	&-ratingIncrease
		padding 10px 15px
		border-radius 12px
		border-left none
		background #F8F9FA
		font-size 14px