.Company
	display flex
	
	@media $smMinus
		display block
	
	&_info
		margin-bottom 8px
	
	&_btnWrapper
		@media $xs
			display flex
			justify-content center
			align-items center
			margin-top 18px
			margin-bottom 6px
	
	&_btn
		@media $xs
			margin-right 10px
			
			&:last-child
				margin-right 0
	
	&_left
		flex-shrink 0
		padding-top 6px
		
		@media $smMinus
			display flex
			justify-content center
			margin-bottom 20px
			padding-top 0
	
	&_photo
		margin-bottom 20px
		
		&:last-child
			margin-bottom 0
	
	&_right
		flex-grow 1
		padding-left 54px
		
		@media $smMinus
			padding-left 0
	
	&_title
		margin-bottom 22px
		font-weight 600
		font-size 36px
		line-height 1.12
		
		@media $smMinus
			font-size 34px
	
	&_menuSection
		margin-bottom 25px
		
		@media $xs
			margin-right -20px
			margin-left -20px
	
	&_entityList
		margin -10px -6px 18px 0
		
		@media $xs
			margin -2px -3px 18px -1px
	
	&_notice
		&-fraud
			margin-bottom 24px
			
			@media $xs
				margin-bottom 20px
	
	&_contacts
		@media $xs
			margin-top -2px
		
		&-bordered
			margin-bottom 20px
	
	&_characteristics
		margin-bottom 16px
		
		&-largeIndent
			margin-bottom 47px
		
		&-dossier
			margin-bottom 33px
			
			@media $xs
				margin-bottom 20px
		
		&-dossierLock
			margin-bottom 42px
			
			@media $xs
				margin-bottom 23px
	
	&_description
		margin-bottom 15px
		font-size 16px
		line-height 1.625
		
		p
			margin-bottom 10px
			
			&:last-child
				margin-bottom 0
	
	&_infoLink
		margin-bottom 22px
	
	&_ratingSection
		margin-bottom 30px
		
		@media $xs
			// margin-bottom 24px
			margin-bottom 15px
	
	&_remark
		margin-bottom 30px
		
		@media $xs
			margin-bottom 23px
			margin-right -20px
			margin-left -20px
	
	&_commentsBlock
		margin-right -4px
		
		@media $xs
			margin-bottom 25px