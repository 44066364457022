.BtnGrey
	display inline-block
	padding 11px 26px 11px 25px
	border none
	outline none
	border-radius 18px
	background #e4e4e4
	color #727272
	font-weight 500
	white-space nowrap
	
	&-mobilePopup,
	&-mobilePopupWrite,
	&-completeArticle
		background #EBF0F2
		color #4591F2
		text-align center
	
	&-mobilePopup
		min-width 155px
		// padding 11px 29px 10px
		padding 11px 17px 10px
	
	&-mobilePopupWrite,
	&-completeArticle
		width 320px
		padding-top 12px
		padding-bottom 11px
		font-size 15px
	
	&-completeArticle
		padding-top 10px
		padding-bottom 9px
	
	&-dots
		padding 8px 10px 9px
		background #EBF0F2
		border-radius 50%
		
		& > .SvgIco
			width 14px
	
	&-ratingSection
		width 100%
		text-align center
	
	&-fullWidth
		width 100%