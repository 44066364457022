.Comment
	&_wrapper
		// padding 3px 4px 10px
		padding 3px 5px 19px
		border-bottom 1px solid #ebf0f2
		background #FFF
		transition all 3s linear
		
		.CommentForm
			margin-bottom 15px
		
		&:hover
			.Comment_dotsMenu
				display block
		
	&_header,
	&_headerLeft,
	&_infoTop
		display flex
	
	&_header
		justify-content space-between
		align-items flex-start
		// margin-bottom 13px
		margin-bottom 12px
	
	&_headerLeft
		align-items center
	
	&_headerRight
		position relative
		padding-top 14px
		padding-right 7px
		
		@media $xs
			padding-top 4px
			padding-right 3px
	
	&_dotsMenu
		display none
		top 1px
		right -20px
	
	&_userPhoto
		margin-top 3px
		flex-shrink 0
	
	&_info
		margin-top 5px
		margin-left 13px
		
		@media $xs
			// margin-left 11px
			margin-left 10px
	
	&_infoTop
		align-items center
	
	&_userName
		flex-shrink 0
		font-weight 500
		font-size 14px
		
		@media $xs
			max-width 153px
			white-space nowrap
			overflow hidden
			text-overflow ellipsis
		
		&-highlighted
			padding 2px 8px
			border-radius 12px
			background $green2
			color $white
			font-size 14px
	
	&_replyName
		margin-left 10px
		
		@media $xs
			max-width 153px
			margin-left 5px
			white-space nowrap
			overflow hidden
			text-overflow ellipsis
	
	&_userIcons
		margin-left 7px
	
	&_dateWrapper
		display flex
		align-items baseline
	
	&_employerLink
		font-weight 500
		font-size 13px
		color $blue
	
	&_date,
	&_person
		margin-left 1px
		font-size 13px
		color $blue2
		
		// @media $xs
		// 	font-size 12px
	
	&_date
		@media $xs
			flex-shrink 0
			
		&-highlightedName
			margin-left 8px
	
	&_mobile
		margin-top 2px
		margin-left 15px
		color #C4C4C4
	
	&_marks
		display flex
		align-items center
		margin-bottom 10px
	
	&_userRating
		margin-right 6px
	
	&_text,
	&_additionText
		margin-bottom 19px
		font-size 15px
		line-height 1.67
		overflow hidden
		text-overflow ellipsis
		
		a
			display inline-block
			max-width 100%
			color $blue
			text-decoration underline
			overflow hidden
			text-overflow ellipsis
			// vertical-align middle
			vertical-align top
			
			&:hover
				text-decoration none
		
		@media $xs
			// margin-bottom 13px
			margin-bottom 14px
			font-size 14px
			line-height 1.5714
	
	&_nameAndRating
		display flex
		align-items center
		
		@media $xs
			// margin-bottom 3px
			margin-top -3px
	
	&_rating
		// margin-left 6px
		margin-left 8px
	
	&_replyLink
		display inline-block
		margin-top 16px
		margin-bottom 15px
		color $blue2
		@extend .transition
		
		&:hover
			color $blue
	
	&_addition
		margin-top 19px
	
	&_additionTitle
		margin-bottom 14px
		font-weight 700
	
	&_additionText
		margin-bottom 13px
		
		p:last-child
			margin-bottom 0
	
	&_footer
		display flex
		justify-content space-between
		align-items center
		padding-right 7px
		
		@media $xs
			padding-right 0
	
	&_actions
		margin-top 20px
	
	&_actionLinks
		display flex
		align-items center
	
	&_actionLink
		color $blue2
		
		&:first-child
			margin-right 18px
			
			@media $xs
				margin-right 21px
		
		&-toggleAnswers
			position relative
			padding-right 10px
			color $blue
			font-weight 500
			
			&:after
				content ""
				position absolute
				top 8px
				right 0
				border 3px solid transparent
				border-top 3px solid $blue
				@extend .transition
		
		&-answersOpened:after
			top 5px
			border-top 3px solid transparent
			border-bottom 3px solid $blue
	
	&_childComments
		padding 0 3px 0 60px
		
		@media $xs
			padding 0 3px 0 45px
		
		.CommentForm
			margin-left -60px
			
			@media $xs
				margin-left -45px
	
	&_commentForm
		margin-top 18px
		margin-right 6px
	
	&_country
		display flex
		align-items center
		font-size 13px
		color #666
	
	&_countryIcon
		margin-right 10px
	
	&_images
		margin-bottom -10px
	
	&-hasChild
		& > .Comment_wrapper
			padding-bottom 20px
		
		&.Comment-company
			& > .Comment_wrapper
				padding-bottom 25px
			
			&:last-child > .Comment_wrapper
				@media $xs
					padding-bottom 0
	
	&-child
		& > .Comment_wrapper
			padding 18px 0 20px
			
			@media $xs
				padding 12px 0 17px
		
	&-highlighted
		& > .Comment_wrapper
			margin-right -12px
			margin-left -12px
			padding-right 16px
			padding-left 16px
			border-radius 10px
			background rgba($blue, 5%)
			transition none
	
	&-reply
		& > .Comment_wrapper
			padding-bottom 4px
			border-bottom none
	
	&-hideChildComments
		& > .Comment_childComments
			display none
	
	&-company		
		.Comment_header
			margin-bottom 17px
		
		.Comment_info
			margin-left 15px
			
			@media $xs
				margin-left 10px
		
		.Comment_nameAndRating
			margin-bottom 3px
		
		.Comment_date,
		.Comment_person
			@media $xs
				font-size 13px
		
		.Comment_userIcons
			margin-left 6px
	
		.Comment_ratingWrapper
			display flex
			align-items center
			margin-bottom 9px
		
		.Comment_confirmLabel
			margin-top 3px
			margin-left 13px
		
		.Comment_wrapper
			padding-right 0
			padding-left 0
			
			@media $xs
				padding-bottom 6px
		
		.Comment_text
			margin-bottom 13px
		
		.Comment_images
			@media $xs
				margin-bottom -5px
		
		.Comment_footer
			margin-top 18px
			padding-right 0
			
			@media $xs
				padding-right 3px
		
		.Comment_childComments
			padding-top 14px
			padding-left 55px
			
			@media $xs
				padding-top 11px
				padding-left 40px
		
		&:last-child
			.Comment_wrapper
				@media $xs
					border-bottom none
	
	&-companyChild
		.Comment_header
			margin-bottom 9px
			
			@media $xs
				margin-bottom 12px
	
	&-rejected,
	&-onCheck
		.Comment_wrapper
			padding 20px 30px 30px
			border-bottom none
			border-bottom-right-radius 4px
			border-bottom-left-radius 4px
			transition none
			
			@media $xs
				padding-top 17px
				padding-right 33px
		
		.Comment_headerRight
			padding-top 4px
			padding-right 2px
		
		.Comment_ratingWrapper
			margin-left 4px
		
		.Comment_text
			margin-bottom 15px
			
			@media $xs
				margin-bottom 12px
				font-size 15px
		
		.Comment_footer
			-webkit-align-items flex-start
			align-items flex-start
			margin-top 15px
			
			@media $xs
				margin-top 0
		
		.Comment_countryIcon
			margin-right 7px

	&-rejected
		.Comment_wrapper
			background #FEF5ED
	
	&-onCheck
		.Comment_wrapper
			background $lightGrey
	
	&-companyBehalf
		.Comment_header
			@media $xs
				margin-bottom 9px
			
		.Comment_nameAndRating
			margin-bottom 2px
				
		.Comment_userName
			@media $xs
				max-width 230px
		
		.Comment_rating
			margin-left 1px
		
		.Comment_dateWrapper
			@media $xs
				flex-wrap wrap
				gap 4px 6px
				margin-left 2px
		
		.Comment_date
			margin-left 3px
			
			@media $xs
				margin-left -3px
				
			&::before
				content "• "
		
		.Comment_replyName
			
			@media $xs
				margin-left 0
		
		.Comment-child:not(.Comment-companyBehalf)
			.Comment_date::before
				display none
			
			.Comment_dateWrapper
				@media $xs
					flex-wrap nowrap