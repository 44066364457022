.PlansTable
	width 100%
	
	&_th,
	&_data
		border-bottom 1px solid #ebf0f2
	
	&_th
		width 160px
		padding-bottom 19px
		font-weight 600
		font-size 18px
		line-height 1.34
		
		@media $smMinus
			font-size 16px
		
		&-currency
			width 348px
			font-weight normal
			font-size 16px
			text-align left
			vertical-align middle
	
	&_headerPrice
		color $blue
		font-weight 500
		font-size 16px
	
	&_sum
		font-size 22px
	
	&_data
		height 79px
		padding 15px 10px
		vertical-align middle
		font-size 16px
		line-height 1.625
		text-align center
		
		img
			vertical-align middle
		
		&-title
			width 353px
			padding-left 0
			text-align left
	
	&_currencyDropdown
		position relative
		top 9px
	
	&_hint
		position relative
		top 2px
		margin-left 3px
	
	&_price
		margin-top 8px
		font-size 13px
		
		&-smallMargin
			margin-top 3px