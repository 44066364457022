.Share
	position relative
	
	&_icon, &_mobIcon
		display inline-block
		width 22.87px
		color $blue3
		
		@media $xs
			width 24.3px
		
		&-small
			width 18px
	
	&_wrapper
		position absolute
		z-index 10
		top calc(100% + 10px)
		left -54px
		width 370px
		padding 18px 20px 20px
		border-radius 3px
		box-shadow 0 0 5px 0 rgba($black, 0.12)
		background $white
		visibility hidden
		opacity 0
		
		@media $xs
			position fixed
			z-index 1001
			top 50%
			left 50%
			max-width calc(100% - 20px)
			padding 20px
			transform translate(-50%,-50%)
			box-shadow none
		
		&-opened
			visibility visible
			opacity 1
	
	&_title
		margin-bottom 0.875em
		font-weight 600
		font-size 16px
	
	&_socIcons
		display flex
		align-items center
		margin-bottom 15px
	
	&_socItem
		margin-right 10px
		
		@media $xs
			flex auto 1 1
			margin-right 8%
		
		&:last-child
			margin-right 0
	
	&_socLink
		display inline-block
		width 26px
		
		@media $xs
			width 100%
	
	&-job
		.Share_wrapper
			right 0
			left auto