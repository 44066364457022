.Characteristics
	&_title
		margin-bottom 27px
		font-weight 600
		font-size 21px
		
		@media $xs
			margin-bottom 20px
	
	&_subTitle
		margin-top 41px
		margin-bottom 25px
		font-weight 600
		font-size 16px
		line-height 1.5
		
		@media $xs
			margin-top 25px
		
	&_item
		margin-bottom 16px
		
		&:last-child
			margin-bottom 0
	
	&-dossiers
		padding-bottom 37px
		border-bottom 1px solid #ebf0f2
		
		@media $xs
			padding-bottom 20px
		
		.Characteristics_item
			margin-bottom 27px
			
			@media $xs
				margin-bottom 16px
			
			&:last-child
				margin-bottom 0
	
	&-noBorder
		padding-bottom 0
		border-bottom none